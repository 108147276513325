import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';

const Github = ({ classes, strLink = '' }) => {
  return (
    <a href={strLink} target="_blank" rel="noopener noreferrer">
      <svg className={classes.github__svg} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_github)">
          <path
            d="M68.6203 33.9154C68.6203 49.651 57.4218 62.8295 42.5221 66.37L38.4473 53.6243H30.6183L26.6767 66.37C11.7769 62.8295 0.445312 49.651 0.445312 33.9154C0.445312 15.4656 15.7981 0.215088 34.5328 0.215088C53.2675 0.215088 68.6203 15.4656 68.6203 33.9154Z"
            fill="#4250C6"
          />
          <path
            d="M68.6204 33.9154C68.6204 49.651 57.422 62.8295 42.5222 66.37L38.4475 53.6243H34.533V0.215088C53.2677 0.215088 68.6204 15.4656 68.6204 33.9154Z"
            fill="#6070F9"
          />
          <path
            d="M24.583 56.6089C23.7737 56.6089 22.9836 56.4952 22.2091 56.2708C20.1946 55.6848 18.5578 54.3633 17.3427 52.3482C16.7888 51.4282 16.2114 50.7905 15.5311 50.3408C14.8617 49.8972 14.3275 49.7281 13.7637 49.7686L13.4126 45.8501C14.8929 45.7251 16.3092 46.1205 17.7526 47.0717C18.9286 47.8457 19.9174 48.9158 20.7777 50.3387C21.484 51.5086 22.297 52.1945 23.3409 52.4983C24.5133 52.8379 25.7299 52.696 27.2866 52.0429L28.859 55.6577C27.3548 56.2918 25.939 56.6089 24.583 56.6089Z"
            fill="#C9C9F9"
          />
          <path
            d="M53.8273 37.8887C52.4286 41.547 48.9136 46.1893 40.0058 47.4873C41.1641 49.1786 42.5622 50.0837 42.5222 53.6242V66.3699C39.9657 66.9994 37.2891 67.3534 34.5329 67.3534C31.7767 67.3534 29.2333 66.9994 26.6768 66.3699V53.4665C26.6768 49.9654 28.0219 48.9819 29.0601 47.4873C20.1522 46.1888 16.6372 41.547 15.2385 37.8887C13.3614 33.0102 14.36 26.9521 17.6358 23.0971C17.7154 23.0182 17.8356 22.8216 17.7955 22.7037C16.2772 18.2192 18.1149 14.482 18.195 14.0886C19.9125 14.5998 20.1923 13.5769 25.7447 16.9207L26.7033 17.4719C27.1028 17.7075 26.9832 17.5502 27.3826 17.5502C29.6993 16.9207 32.1361 16.5668 34.5329 16.5668C36.9697 16.5668 39.3665 16.9207 41.8028 17.5502L42.0826 17.5897C42.0826 17.5897 42.1622 17.5897 42.3625 17.4719C49.273 13.3408 49.0332 14.6787 50.9104 14.0886C50.9504 14.482 52.788 18.2581 51.2703 22.7037C51.0706 23.2938 57.2622 28.8796 53.8273 37.8887Z"
            fill="#C9C9F9"
          />
          <path
            d="M53.8273 37.8887C52.4287 41.547 48.9136 46.1893 40.0058 47.4872C41.1641 49.1786 42.5623 50.0837 42.5222 53.6242V66.3699C39.9658 66.9994 37.2891 67.3534 34.533 67.3534V16.5668C36.9698 16.5668 39.3666 16.9207 41.8034 17.5502L42.0827 17.5897C42.0827 17.5897 42.1628 17.5897 42.3625 17.4719C49.273 13.3408 49.0332 14.6787 50.9109 14.0886C50.951 14.482 52.7886 18.2581 51.2703 22.7037C51.0706 23.2938 57.2623 28.8796 53.8273 37.8887Z"
            fill="#D5D5FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_github">
            <rect x="0.445312" y="0.215088" width="68.175" height="67.1384" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

export default withTheme(withStyles(styles)(Github));
