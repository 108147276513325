import axios from 'axios';

export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';

const API_URI = '/.netlify/functions/email';

export const sendEmailBegin = () => {
  return {
    type: SEND_EMAIL_START,
  };
};

export const sendEmailError = () => {
  return {
    type: SEND_EMAIL_FAILURE,
  };
};

export const sendEmailSuccess = () => {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
};

export const sendEmail = (objEmailBody) => {
  console.log(objEmailBody);
  return (dispatch) => {
    dispatch(sendEmailBegin());
    return axios
      .post(API_URI, objEmailBody)
      .then((response) => {
        console.log(response);
        if (response.status === 200) return dispatch(sendEmailSuccess());
        return dispatch(sendEmailError());
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendEmailError());
      });
  };
};

export const resetContactForm = () => {
  return {
    type: RESET_CONTACT_FORM,
  };
};
