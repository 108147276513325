export default (theme) => ({
  nav__link: {
    color: theme.palette.white,
    textDecoration: 'none',
    fontSize: theme.mediumFontSize,
    fontWeight: 500,
    fontFamily: theme.fonts.passionOne,
  },
  nav__container: {
    display: 'flex',
    justifyContent: 'space-around',
    top: 40,
    left: 70,
    position: 'absolute',
    width: 300,
  },
  nav__container__footer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'space-between',
  },
  nav__linkActive: {
    color: theme.palette.yellow,
    borderBottom: `2px solid ${theme.palette.yellow}`,
    transition: 'border 0.2s',
  },
  nav__bgShape: {
    height: 135,
    width: 500,
  },

  navigation: {
    position: 'relative',
    width: 600,
  },
  //topRightShape
  nav__topRightShape: {
    height: 190,
    width: 175,
  },
  nav__rightShape: {
    position: 'absolute',
    top: 100,
    right: 0,
  },

  //tablet
  '@media (max-width: 1170px)': {
    nav__linkActive: {
      borderBottom: 'none',
    },
  },
  //mobile
  '@media (max-width: 800px)': {
    nav__link: {
      fontSize: 20,
    },
  },
  nav__containerMobile: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    background: theme.palette.brightPurple,
    position: 'absolute',
    top: 40,
    right: 20,
  },
  nav__mobile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 65,
    right: 100,
  },
  nav__linesContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 50,
    width: 50,
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 40,
    right: 20,
  },
  nav__line: {
    width: '50%',
    height: 3,
    borderRadius: 10,
    background: theme.palette.white,
    margin: '2px 0 2px',
    transformOrigin: '6.5px',
  },
  nav__wrapper: {
    position: 'relative',
  },
});
