import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { withRouter } from 'react-router';
import { PlanetsPageShape } from './components';

const Footer = ({ classes }) => {
  return (
    <div className={classes.footer__container}>
      <PlanetsPageShape />
    </div>
  );
};

export default withRouter(withStyles(styles)(Footer));
