export default (theme) => ({
  skills__mainSvg: {
    height: 470,
    width: 500,
    position: 'relative',
    marginLeft: -100,
    zIndex: 1,
  },
  skills__container: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 50,
  },
  skills__leftShape: {
    position: 'absolute',
    top: '8%',
    left: '10%',
  },
  skills__rightShape: {
    position: 'absolute',
    top: '10%',
    right: '10%',
    zIndex: 0,
  },
  '@media (max-width: 900px)': {
    skills__leftShape: {
      top: '-4%',
      right: '2%',
      left: 'unset',
    },
  },
  '@media (max-width: 540px)': {
    skills__mainSvg: {
      width: '100%',
      marginLeft: 0,
    },
    skills__title: {
      marginTop: -20,
    },
  },
});
