import { FETCH_CONTENT_ERROR, FETCH_CONTENT_START, RECEIVED_CONTENT_DATA } from '../../actions/ActionGetContent';

const objInitialState = {
  bLoading: false,
  nstrError: null,
  objContent: {},
};

function contentReducer(state = objInitialState, action) {
  switch (action.type) {
    case FETCH_CONTENT_START:
      return {
        ...state,
        bLoading: true,
        nstrError: null,
      };
    case RECEIVED_CONTENT_DATA:
      return {
        ...state,
        objContent: action.payload,
        bLoading: false,
        nstrError: null,
      };
    case FETCH_CONTENT_ERROR:
      return {
        ...state,
        bLoading: false,
        nstrError: action.payload,
      };
    default:
      return state;
  }
}

export default contentReducer;
