import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleTheme, toggleLanguage } from '../../redux/actions/ActionChangePreferences';

const withTheme = (WrappedComponent) => {
  class HOC extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      funcToggleTheme: () => dispatch(toggleTheme()),
      funcToggleLanguage: (strLanguage) => dispatch(toggleLanguage(strLanguage)),
    };
  };

  const mapStateToProps = (state, ownProps) => {
    const { bIsDark, strLanguage } = state.PreferencesReducer;
    return {
      bIsDark,
      strLanguage,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withTheme;
