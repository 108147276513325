export default (theme) => ({
  footer__container: {
    width: '100%',
  },
  footer__mainSvg: {
    height: 280,
    width: 390,
    position: 'absolute',
    bottom: 13,
    left: 0,
  },
  shape__containerFooter: {
    position: 'relative',
    width: '100%',
    height: 350,
    clipPath: 'polygon(8% 14%, 100% 32%, 100% 100%, 0 100%, 0 0)',
    background: theme.footerLinearGradient,
  },
  footer__cta: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  hexagon_svg: {
    height: 172,
    width: 150,
  },
  footer__grid: {
    marginTop: 100,
    height: 170,
  },
  footer__links: {
    display: 'flex',
    width: 150,
    justifyContent: 'space-between',
  },
  footer__bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  footer__message: {
    color: theme.palette.white,
    fontFamily: theme.fonts.passionOne,
    marginBottom: 15,
    letterSpacing: 1,
  },
  footer__text: {
    color: theme.palette.white,
    fontFamily: theme.fonts.quickSand,
    fontSize: 12,
  },
  message__img: {
    height: 25,
    margin: '0 5px -8px 5px',
  },

  //social links
  social__container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  social__github: {
    height: 26,
    width: 26,
  },
  social__linkedin: {
    width: 25,
  },
  social__gmail: {
    width: 23,
  },

  //abstract
  abstract__shape: {
    width: 173,
    height: 185,
    position: 'absolute',
    right: 75,
    bottom: 0,
  },

  //tablet
  '@media (max-width: 1170px)': {
    abstract__shape: {
      right: 10,
    },
  },

  //mobile
  '@media (max-width: 800px)': {
    abstract__shape: {
      display: 'none',
    },
    footer__mainSvg: {
      bottom: 40,
    },
    footer__bottom: {
      position: 'relative',
      zIndex: 10,
    },
    footer__links: {
      flexDirection: 'column-reverse',
      position: 'relative',
      zIndex: 10,
    },
    footer__grid: {
      display: 'flex',
      justifyContent: 'center',
    },
    footer__logo: {
      display: 'none',
    },
    social__container: {
      marginTop: 20,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    shape__containerFooter: {
      height: 425,
      clipPath: 'polygon(8% 3%, 100% 32%, 100% 100%, 0 100%, 0 0)',
    },
    footer__redPlanet: {
      display: 'none',
    },
  },

  '@media (max-width: 600px)': {
    footer__mainSvg: {
      opacity: 0.6,
    },
    footer__cta: {
      marginTop: 30,
    },
    shape__containerFooter: {
      clipPath: 'polygon(100% 14%, 100% 32%, 100% 100%, 0 100%, 0 0)',
    },
  },
  '@media (max-width: 400px)': {
    footer__bottom: {
      marginTop: 50,
    },
  },
});
