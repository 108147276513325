import React, { Component } from 'react';
import Placeholder from '../Placeholder';

export default class ErrorBoundary extends Component {
  state = {
    bHasError: false,
  };

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true });
  };
  render() {
    const { bHasError } = this.state;
    const { children } = this.props;
    if (bHasError) {
      return <Placeholder strTitleText="Something went wrong :(" />;
    } else {
      return children;
    }
  }
}
