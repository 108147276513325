import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import withContent from '../../../../hoc/withContent';
import { FloatingAbstractShape, BackgroundAbstractShape } from '../../../../components/common';
import palette from '../../../../theme/Palette';
import posed from 'react-pose';
import SkillsHoneyComb from './components/SkillsHoneyComb';
import createFloatingParallaxOptions from '../../../../util/createFloatingParallaxOptions';
import SectionTitle from '../../../../components/SectionTitle';
import VisibilitySensor from 'react-visibility-sensor';

const BracketLeft = posed.path({
  animated: { x: 0, opacity: 1, delay: 500 },
  default: { x: 50, opacity: 0, delay: 500 },
});

const BracketRight = posed.path({
  animated: { x: 0, opacity: 1, delay: 500 },
  default: { x: -50, opacity: 0, delay: 500 },
});

const CodeLine = posed.path({
  animated: { x: 0, opacity: 1 },
  default: { x: 50, opacity: 0 },
});

const Skills = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const [bIsVisible, setVisibility] = useState(false);
  const onChange = (bIsVisible) => {
    if (bIsVisible) {
      setVisibility(bIsVisible);
    }
  };

  const { subtitles_text_aboutpage: arrSectionTitles = [] } = objContent[intContentIndex].data;

  return (
    <div className={classes.skills__container}>
      <div className={classes.skills__title}>
        <SectionTitle strTitleText={arrSectionTitles[1].text} />
      </div>
      <div className={classes.skills__leftShape}>
        <FloatingAbstractShape
          bLight
          objParallaxOptions={createFloatingParallaxOptions('600', '1200', '-80px')}
          bRoundType
        />
      </div>
      <div className={classes.skills__rightShape}>
        <BackgroundAbstractShape
          intRotation={180}
          objParallaxOptions={createFloatingParallaxOptions('600', '1400', '-120px')}
        />
      </div>
      <VisibilitySensor onChange={onChange}>
        <svg className={classes.skills__mainSvg} viewBox="0 0 1307 1260" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M565.614 971.281C280.045 835.503 41.7903 598.839 111.278 390.833C180.767 182.827 536.43 62.4724 796.226 149.411C1056.02 236.35 1345.25 1002.14 1039.21 879.054C783.915 734.23 825.411 1058.22 565.614 971.281Z"
            fill="url(#paint0_linear_skills_illustration_skills_illustration)"
          />
          <path
            d="M457.532 902C560.551 902 644.065 894.705 644.065 885.707C644.065 876.709 560.551 869.414 457.532 869.414C354.513 869.414 271 876.709 271 885.707C271 894.705 354.513 902 457.532 902Z"
            fill={bIsDark ? palette.darkModePurpleBg : '#3F3D56'}
          />
          <path
            opacity="0.1"
            d="M457.532 896.219C545.282 896.219 616.417 891.395 616.417 885.444C616.417 879.494 545.282 874.67 457.532 874.67C369.783 874.67 298.648 879.494 298.648 885.444C298.648 891.395 369.783 896.219 457.532 896.219Z"
            fill="black"
          />
          <path d="M1104.53 291.674H443.467V811.288H1104.53V291.674Z" fill="url(#paint1_linear_skills_illustration)" />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M766.476 362.684H603.393C603.133 362.685 602.875 362.634 602.635 362.534C602.394 362.435 602.175 362.289 601.991 362.105C601.807 361.92 601.661 361.702 601.561 361.461C601.461 361.22 601.41 360.962 601.41 360.701C601.41 360.44 601.461 360.182 601.561 359.941C601.661 359.701 601.807 359.482 601.991 359.298C602.175 359.113 602.394 358.967 602.635 358.868C602.875 358.769 603.133 358.718 603.393 358.718H766.476C767 358.719 767.502 358.929 767.873 359.301C768.243 359.672 768.451 360.176 768.451 360.701C768.451 361.226 768.243 361.73 767.873 362.102C767.502 362.473 767 362.683 766.476 362.684Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M944.996 384.495H688.497C687.972 384.495 687.469 384.286 687.098 383.914C686.726 383.543 686.518 383.038 686.518 382.512C686.518 381.987 686.726 381.482 687.098 381.11C687.469 380.738 687.972 380.53 688.497 380.53H944.996C945.256 380.53 945.513 380.581 945.753 380.68C945.993 380.78 946.211 380.926 946.395 381.11C946.579 381.294 946.725 381.513 946.824 381.754C946.924 381.994 946.975 382.252 946.975 382.512C946.975 382.773 946.924 383.031 946.824 383.271C946.725 383.512 946.579 383.73 946.395 383.914C946.211 384.099 945.993 384.245 945.753 384.344C945.513 384.444 945.256 384.495 944.996 384.495Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M767.268 406.306H604.185C603.66 406.306 603.157 406.098 602.785 405.726C602.414 405.354 602.206 404.85 602.206 404.324C602.206 403.798 602.414 403.293 602.785 402.922C603.157 402.55 603.66 402.341 604.185 402.341H767.268C767.792 402.341 768.296 402.55 768.667 402.922C769.038 403.293 769.247 403.798 769.247 404.324C769.247 404.85 769.038 405.354 768.667 405.726C768.296 406.098 767.792 406.306 767.268 406.306Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M944.996 406.703H781.913C781.388 406.703 780.885 406.494 780.514 406.122C780.143 405.75 779.934 405.246 779.934 404.72C779.934 404.194 780.143 403.69 780.514 403.318C780.885 402.946 781.388 402.737 781.913 402.737H944.996C945.521 402.737 946.024 402.946 946.395 403.318C946.766 403.69 946.975 404.194 946.975 404.72C946.975 405.246 946.766 405.75 946.395 406.122C946.024 406.494 945.521 406.703 944.996 406.703Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M767.663 428.118H604.581C604.056 428.118 603.553 427.909 603.181 427.537C602.81 427.165 602.602 426.661 602.602 426.135C602.602 425.609 602.81 425.104 603.181 424.733C603.553 424.361 604.056 424.152 604.581 424.152H767.663C768.188 424.152 768.692 424.361 769.063 424.733C769.434 425.104 769.643 425.609 769.643 426.135C769.643 426.661 769.434 427.165 769.063 427.537C768.692 427.909 768.188 428.118 767.663 428.118Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M768.059 449.929H604.976C604.451 449.929 603.948 449.72 603.577 449.348C603.206 448.976 602.997 448.472 602.997 447.946C602.997 447.42 603.206 446.916 603.577 446.544C603.948 446.172 604.451 445.963 604.976 445.963H768.059C768.584 445.963 769.087 446.172 769.459 446.544C769.83 446.916 770.038 447.42 770.038 447.946C770.038 448.472 769.83 448.976 769.459 449.348C769.087 449.72 768.584 449.929 768.059 449.929Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M842.08 363.08H795.371C794.847 363.08 794.343 362.872 793.972 362.5C793.601 362.128 793.392 361.623 793.392 361.098C793.392 360.572 793.601 360.067 793.972 359.696C794.343 359.324 794.847 359.115 795.371 359.115H842.08C842.605 359.115 843.108 359.324 843.479 359.696C843.85 360.067 844.059 360.572 844.059 361.098C844.059 361.623 843.85 362.128 843.479 362.5C843.108 362.872 842.605 363.08 842.08 363.08Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M842.08 428.118H795.371C794.847 428.118 794.343 427.909 793.972 427.537C793.601 427.165 793.392 426.661 793.392 426.135C793.392 425.609 793.601 425.104 793.972 424.733C794.343 424.361 794.847 424.152 795.371 424.152H842.08C842.605 424.152 843.108 424.361 843.479 424.733C843.85 425.104 844.059 425.609 844.059 426.135C844.059 426.661 843.85 427.165 843.479 427.537C843.108 427.909 842.605 428.118 842.08 428.118Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M842.08 449.532H795.372C795.111 449.533 794.853 449.482 794.613 449.382C794.372 449.283 794.154 449.137 793.969 448.953C793.785 448.769 793.639 448.55 793.539 448.309C793.44 448.068 793.388 447.81 793.388 447.549C793.388 447.289 793.44 447.03 793.539 446.789C793.639 446.549 793.785 446.33 793.969 446.146C794.154 445.962 794.372 445.816 794.613 445.716C794.853 445.617 795.111 445.566 795.372 445.566H842.08C842.34 445.566 842.598 445.617 842.838 445.716C843.079 445.816 843.298 445.962 843.482 446.146C843.666 446.33 843.812 446.549 843.912 446.789C844.012 447.03 844.063 447.289 844.063 447.549C844.063 447.81 844.012 448.068 843.912 448.309C843.812 448.55 843.666 448.769 843.482 448.953C843.298 449.137 843.079 449.283 842.838 449.382C842.598 449.482 842.34 449.533 842.08 449.532Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M920.454 449.532H873.746C873.222 449.531 872.72 449.322 872.349 448.95C871.979 448.578 871.771 448.074 871.771 447.549C871.771 447.024 871.979 446.52 872.349 446.149C872.72 445.777 873.222 445.568 873.746 445.566H920.454C920.715 445.566 920.972 445.617 921.213 445.716C921.454 445.816 921.672 445.962 921.856 446.146C922.041 446.33 922.187 446.549 922.287 446.789C922.386 447.03 922.438 447.289 922.438 447.549C922.438 447.81 922.386 448.068 922.287 448.309C922.187 448.55 922.041 448.769 921.856 448.953C921.672 449.137 921.454 449.283 921.213 449.382C920.972 449.482 920.715 449.533 920.454 449.532Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M649.706 384.495H602.997C602.472 384.495 601.969 384.286 601.598 383.914C601.227 383.543 601.018 383.038 601.018 382.512C601.018 381.987 601.227 381.482 601.598 381.11C601.969 380.738 602.472 380.53 602.997 380.53H649.706C650.23 380.53 650.734 380.738 651.105 381.11C651.476 381.482 651.685 381.987 651.685 382.512C651.685 383.038 651.476 383.543 651.105 383.914C650.734 384.286 650.23 384.495 649.706 384.495Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M766.674 683.904H603.591C603.066 683.904 602.563 683.695 602.192 683.323C601.821 682.951 601.612 682.447 601.612 681.921C601.612 681.395 601.821 680.891 602.192 680.519C602.563 680.147 603.066 679.938 603.591 679.938H766.674C767.199 679.938 767.702 680.147 768.073 680.519C768.444 680.891 768.653 681.395 768.653 681.921C768.653 682.447 768.444 682.951 768.073 683.323C767.702 683.695 767.199 683.904 766.674 683.904Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M765.882 727.526H602.8C602.275 727.526 601.771 727.317 601.4 726.945C601.029 726.573 600.82 726.069 600.82 725.543C600.82 725.017 601.029 724.513 601.4 724.141C601.771 723.769 602.275 723.56 602.8 723.56H765.882C766.407 723.56 766.91 723.769 767.281 724.141C767.653 724.513 767.861 725.017 767.861 725.543C767.861 726.069 767.653 726.573 767.281 726.945C766.91 727.317 766.407 727.526 765.882 727.526Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M766.278 749.337H603.195C602.935 749.338 602.677 749.287 602.437 749.188C602.196 749.088 601.978 748.942 601.793 748.758C601.609 748.574 601.463 748.355 601.363 748.114C601.263 747.873 601.212 747.615 601.212 747.354C601.212 747.094 601.263 746.835 601.363 746.595C601.463 746.354 601.609 746.135 601.793 745.951C601.978 745.767 602.196 745.621 602.437 745.521C602.677 745.422 602.935 745.371 603.195 745.372H766.278C766.538 745.371 766.796 745.422 767.037 745.521C767.277 745.621 767.496 745.767 767.68 745.951C767.864 746.135 768.011 746.354 768.11 746.595C768.21 746.835 768.261 747.094 768.261 747.354C768.261 747.615 768.21 747.873 768.11 748.114C768.011 748.355 767.864 748.574 767.68 748.758C767.496 748.942 767.277 749.088 767.037 749.188C766.796 749.287 766.538 749.338 766.278 749.337Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M835.944 727.922H789.236C788.711 727.922 788.208 727.714 787.837 727.342C787.465 726.97 787.257 726.466 787.257 725.94C787.257 725.414 787.465 724.909 787.837 724.538C788.208 724.166 788.711 723.957 789.236 723.957H835.944C836.469 723.957 836.972 724.166 837.344 724.538C837.715 724.909 837.923 725.414 837.923 725.94C837.923 726.466 837.715 726.97 837.344 727.342C836.972 727.714 836.469 727.922 835.944 727.922Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M835.944 749.337H789.236C788.711 749.337 788.208 749.128 787.837 748.757C787.465 748.385 787.257 747.88 787.257 747.354C787.257 746.829 787.465 746.324 787.837 745.952C788.208 745.58 788.711 745.372 789.236 745.372H835.944C836.469 745.372 836.972 745.58 837.344 745.952C837.715 746.324 837.923 746.829 837.923 747.354C837.923 747.88 837.715 748.385 837.344 748.757C836.972 749.128 836.469 749.337 835.944 749.337Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M914.319 727.922H867.611C867.086 727.922 866.582 727.714 866.211 727.342C865.84 726.97 865.632 726.466 865.632 725.94C865.632 725.414 865.84 724.909 866.211 724.538C866.582 724.166 867.086 723.957 867.611 723.957H914.319C914.844 723.957 915.347 724.166 915.718 724.538C916.089 724.909 916.298 725.414 916.298 725.94C916.298 726.466 916.089 726.97 915.718 727.342C915.347 727.714 914.844 727.922 914.319 727.922Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M945.194 705.715H688.695C688.17 705.715 687.667 705.506 687.295 705.134C686.924 704.762 686.716 704.258 686.716 703.732C686.716 703.206 686.924 702.702 687.295 702.33C687.667 701.958 688.17 701.749 688.695 701.749H945.194C945.719 701.749 946.222 701.958 946.593 702.33C946.965 702.702 947.173 703.206 947.173 703.732C947.173 704.258 946.965 704.762 946.593 705.134C946.222 705.506 945.719 705.715 945.194 705.715Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M649.903 705.715H603.195C602.67 705.715 602.167 705.506 601.796 705.134C601.425 704.762 601.216 704.258 601.216 703.732C601.216 703.206 601.425 702.702 601.796 702.33C602.167 701.958 602.67 701.749 603.195 701.749H649.903C650.428 701.749 650.932 701.958 651.303 702.33C651.674 702.702 651.883 703.206 651.883 703.732C651.883 704.258 651.674 704.762 651.303 705.134C650.932 705.506 650.428 705.715 649.903 705.715Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M945.194 771.545H688.695C688.435 771.545 688.177 771.494 687.936 771.395C687.696 771.296 687.477 771.15 687.293 770.965C687.109 770.781 686.962 770.563 686.863 770.322C686.763 770.081 686.712 769.823 686.712 769.562C686.712 769.301 686.763 769.043 686.863 768.802C686.962 768.561 687.109 768.343 687.293 768.158C687.477 767.974 687.696 767.828 687.936 767.729C688.177 767.629 688.435 767.579 688.695 767.579H945.194C945.719 767.579 946.222 767.788 946.593 768.16C946.965 768.532 947.173 769.036 947.173 769.562C947.173 770.088 946.965 770.592 946.593 770.964C946.222 771.336 945.719 771.545 945.194 771.545Z"
            fill="#F2F2F2"
          />
          <CodeLine
            pose={bIsVisible ? 'animated' : 'default'}
            d="M649.903 771.545H603.195C602.935 771.545 602.677 771.494 602.437 771.395C602.196 771.296 601.978 771.15 601.793 770.965C601.609 770.781 601.463 770.563 601.363 770.322C601.263 770.081 601.212 769.823 601.212 769.562C601.212 769.301 601.263 769.043 601.363 768.802C601.463 768.561 601.609 768.343 601.793 768.158C601.978 767.974 602.196 767.828 602.437 767.729C602.677 767.629 602.935 767.579 603.195 767.579H649.903C650.164 767.579 650.422 767.629 650.662 767.729C650.903 767.828 651.121 767.974 651.306 768.158C651.49 768.343 651.636 768.561 651.736 768.802C651.835 769.043 651.887 769.301 651.887 769.562C651.887 769.823 651.835 770.081 651.736 770.322C651.636 770.563 651.49 770.781 651.306 770.965C651.121 771.15 650.903 771.296 650.662 771.395C650.422 771.494 650.164 771.545 649.903 771.545Z"
            fill="#F2F2F2"
          />
          <BracketLeft
            pose={bIsVisible ? 'animated' : 'default'}
            d="M727.289 502.276L663.956 564.933L727.289 627.591L741.539 613.315L693.644 565.33L741.934 516.948L727.289 502.276Z"
            fill="url(#paint2_linear_skills_illustration)"
          />
          <BracketRight
            pose={bIsVisible ? 'animated' : 'default'}
            d="M820.704 502.276L884.038 564.933L820.704 627.591L806.455 613.315L854.351 565.33L806.059 516.948L820.704 502.276Z"
            fill="url(#paint3_linear_skills_illustration)"
          />
          <path
            d="M461.049 312.811C465.418 312.811 468.96 309.262 468.96 304.884C468.96 300.507 465.418 296.958 461.049 296.958C456.679 296.958 453.137 300.507 453.137 304.884C453.137 309.262 456.679 312.811 461.049 312.811Z"
            fill="#E44400"
          />
          <path
            d="M482.146 312.811C486.516 312.811 490.058 309.262 490.058 304.884C490.058 300.507 486.516 296.958 482.146 296.958C477.777 296.958 474.235 300.507 474.235 304.884C474.235 309.262 477.777 312.811 482.146 312.811Z"
            fill="#E4CD00"
          />
          <path
            d="M503.244 312.811C507.613 312.811 511.155 309.262 511.155 304.884C511.155 300.507 507.613 296.958 503.244 296.958C498.874 296.958 495.332 300.507 495.332 304.884C495.332 309.262 498.874 312.811 503.244 312.811Z"
            fill="#279316"
          />
          <path opacity="0.1" d="M1104.53 318.095H443.467V320.737H1104.53V318.095Z" fill="black" />
          <path
            d="M378.793 810.487C376.509 811.761 374.1 812.795 371.604 813.572C367.139 814.794 362.367 814.346 357.857 815.389C356.011 815.816 354.002 816.709 353.414 818.514C352.984 819.838 353.452 821.268 353.901 822.586C358.884 837.223 361.887 852.764 369.834 866.022C373.174 871.591 377.319 876.633 382.135 880.983C383.941 882.615 385.989 884.211 388.407 884.467C389.522 884.585 390.648 884.406 391.671 883.948C392.695 883.491 393.58 882.771 394.237 881.862C394.895 880.952 395.301 879.885 395.415 878.768C395.529 877.651 395.346 876.523 394.886 875.499C394.174 873.913 392.849 872.684 391.995 871.17C391.069 869.241 390.526 867.15 390.396 865.013C389.611 858.753 388.854 852.21 391.019 846.285C392.472 842.311 395.135 838.923 397.354 835.323C399.573 831.722 401.417 827.597 400.923 823.394C400.586 820.526 399.19 817.901 397.715 815.42C396.237 812.934 393.315 806.254 390.346 805.424C387.263 804.563 381.481 809.107 378.793 810.487Z"
            fill="#2F2E41"
          />
          <path
            d="M460.049 861.047C460.096 862.464 459.898 863.877 459.466 865.227C458.537 867.686 456.23 869.371 455.039 871.715C453.382 874.974 454.133 878.913 455.231 882.402C455.919 884.588 456.822 886.864 458.67 888.217C460.251 889.198 462.048 889.775 463.904 889.896C479.759 892.095 495.865 890.833 511.865 891.294C514.781 891.378 517.868 891.48 520.414 890.052C522.959 888.625 524.533 885.035 522.802 882.683C521.247 880.57 518.173 880.59 515.556 880.466C508.742 880.119 502.253 877.441 497.172 872.878C496.17 872.021 495.301 871.019 494.594 869.905C494.05 868.916 493.598 867.879 493.245 866.807C491.002 860.72 488.334 854.799 485.26 849.088C484.4 847.49 483.425 845.825 481.826 844.97C480.656 844.437 479.385 844.159 478.1 844.155C474.112 843.896 463.786 842.536 460.702 845.643C457.853 848.513 460.112 857.402 460.049 861.047Z"
            fill="#2F2E41"
          />
          <path
            d="M462.909 504.822C476.976 504.822 488.38 493.397 488.38 479.304C488.38 465.211 476.976 453.786 462.909 453.786C448.842 453.786 437.438 465.211 437.438 479.304C437.438 493.397 448.842 504.822 462.909 504.822Z"
            fill="#C08B93"
          />
          <path
            d="M444.03 502.76C442.515 506.823 439.945 510.639 436.205 512.826L471.434 520.025C470.156 514.874 469.988 509.51 470.94 504.288C471.29 503.024 471.361 501.7 471.151 500.405C470.488 497.845 467.611 496.612 465.037 496.024C458.607 494.556 452.921 493.768 446.947 490.923C445.12 494.056 445.376 499.148 444.03 502.76Z"
            fill="#C08B93"
          />
          <path
            d="M435.226 514.939C438.771 513.367 442.632 512.603 446.521 512.702C450.41 512.802 454.226 513.763 457.681 515.514C461.905 517.695 465.403 520.986 468.839 524.234C472.006 527.228 475.385 530.656 475.61 534.962C475.719 537.048 479.329 539.999 474.719 541.151C476.372 540.454 477.299 543.003 479.329 546.823C481.359 550.643 482.135 560.509 486.048 562.472C488.665 566.744 489.205 571.919 489.245 576.901C489.397 595.778 483.318 614.888 487.658 633.281C488.677 637.599 490.257 641.782 491.138 646.13C492.278 651.761 492.222 657.551 492.818 663.262C493.465 669.446 490.673 670.979 492.818 676.83C491.384 679.064 492.604 684.472 489.917 684.291C487.229 684.11 484.717 682.968 482.319 681.766C470.707 675.944 460.072 668.268 448.046 663.321C440.09 660.047 431.614 658.009 423.896 654.228C416.177 650.448 408.999 644.448 406.639 636.316C405.618 632.8 405.556 629.095 405.5 625.439C405.265 610.044 405.047 594.48 408.468 579.452C411.484 566.199 417.299 553.52 418.567 539.999C418.98 535.597 418.989 530.886 421.499 527.213C425.046 522.022 432.706 520.678 435.226 514.939Z"
            fill="url(#paint4_linear_skills_illustration)"
          />
          <path
            d="M451.094 478.522C452.595 476.561 455.49 476.293 457.888 476.874C460.285 477.454 462.496 478.692 464.918 479.162C465.468 479.319 466.045 479.358 466.612 479.279C467.179 479.199 467.722 479.002 468.208 478.699C469.055 477.864 469.561 476.743 469.629 475.555C470.477 470.499 470.675 465.354 470.218 460.248C470.092 459.531 470.14 458.795 470.358 458.101C470.957 456.654 472.786 456.309 474.28 455.845C476.745 455.01 478.862 453.377 480.297 451.203C481.731 449.028 482.402 446.437 482.203 443.838C482.166 442.592 481.643 441.409 480.747 440.544C479.714 439.701 478.268 439.649 476.935 439.638C470.088 439.583 463.241 439.585 456.394 439.643C453.86 439.665 451.199 439.727 449.002 440.993C447.378 441.929 446.17 443.435 444.712 444.614C440.909 447.689 435.765 448.314 431.138 449.89C428.879 450.534 426.828 451.76 425.189 453.444C423.65 455.205 422.91 457.824 423.877 459.955C424.373 460.843 424.927 461.697 425.535 462.512C428.264 466.884 426.448 472.555 426.855 477.695C427.364 484.114 436.846 494.527 443.275 496.046C453.655 498.498 447.658 482.736 451.094 478.522Z"
            fill="#2F2E41"
          />
          <path
            d="M455.722 762.569C456.377 767.256 457.217 771.959 456.993 776.686C456.679 783.286 454.303 789.645 453.864 796.237C453.536 801.161 454.295 806.138 453.612 811.026C453.175 814.155 452.157 817.172 451.526 820.268C450.528 825.313 450.556 830.508 451.609 835.542C452.662 840.576 454.719 845.346 457.656 849.565C458.087 850.287 458.701 850.882 459.435 851.292C460.139 851.553 460.892 851.653 461.639 851.584C469.053 851.433 476.446 850.759 483.766 849.569C484.301 849.547 484.809 849.323 485.186 848.941C485.351 848.688 485.457 848.401 485.495 848.101C486.852 841.112 483.757 834.104 482.97 827.027C482.13 819.471 483.94 811.899 485.736 804.512C486.55 800.868 487.568 797.273 488.784 793.743C489.652 791.368 490.701 789.057 491.453 786.642C492.519 782.849 493.168 778.95 493.389 775.015C493.912 768.806 494.188 762.582 494.22 756.345C494.397 750.434 493.906 744.522 492.756 738.722C491.105 731.379 487.724 724.56 484.373 717.823C482.581 718.253 480.899 719.054 479.435 720.174C475.116 722.976 470.607 725.472 465.941 727.643C462.806 729.102 456.691 730.139 454.702 733.179C452.739 736.177 454.005 742.295 454.155 745.602C454.413 751.278 454.935 756.934 455.722 762.569Z"
            fill="#2F2E41"
          />
          <path
            opacity="0.1"
            d="M455.722 762.569C456.377 767.256 457.217 771.959 456.993 776.686C456.679 783.286 454.303 789.645 453.864 796.237C453.536 801.161 454.295 806.138 453.612 811.026C453.175 814.155 452.157 817.172 451.526 820.268C450.528 825.313 450.556 830.508 451.609 835.542C452.662 840.576 454.719 845.346 457.656 849.565C458.087 850.287 458.701 850.882 459.435 851.292C460.139 851.553 460.892 851.653 461.639 851.584C469.053 851.433 476.446 850.759 483.766 849.569C484.301 849.547 484.809 849.323 485.186 848.941C485.351 848.688 485.457 848.401 485.495 848.101C486.852 841.112 483.757 834.104 482.97 827.027C482.13 819.471 483.94 811.899 485.736 804.512C486.55 800.868 487.568 797.273 488.784 793.743C489.652 791.368 490.701 789.057 491.453 786.642C492.519 782.849 493.168 778.95 493.389 775.015C493.912 768.806 494.188 762.582 494.22 756.345C494.397 750.434 493.906 744.522 492.756 738.722C491.105 731.379 487.724 724.56 484.373 717.823C482.581 718.253 480.899 719.054 479.435 720.174C475.116 722.976 470.607 725.472 465.941 727.643C462.806 729.102 456.691 730.139 454.702 733.179C452.739 736.177 454.005 742.295 454.155 745.602C454.413 751.278 454.935 756.934 455.722 762.569Z"
            fill="black"
          />
          <path
            d="M421.286 662.465C417.904 671.588 422.876 681.447 427.195 690.165C437.837 711.642 445.227 734.559 452.578 757.378C453.129 759.087 453.679 760.952 453.065 762.639C452.535 763.838 451.674 764.861 450.584 765.587C445.516 769.444 439.063 770.817 433.025 772.835C427.191 774.786 421.602 777.41 416.373 780.655C411.999 783.371 407.9 786.507 403.81 789.636L390.789 799.599C389.946 800.17 389.21 800.884 388.613 801.71C387.357 803.684 387.871 806.261 388.497 808.516C390.152 814.488 392.284 820.317 394.872 825.947C395.297 827.181 396.108 828.245 397.184 828.983C399.216 830.077 401.702 828.626 403.254 826.916C404.806 825.205 406.148 823.077 408.336 822.342C410.521 821.609 412.926 822.497 415.208 822.176C418.899 821.657 421.349 818.251 424.01 815.636C434.88 804.951 453.028 805.727 464.985 796.28C466.714 794.731 468.544 793.298 470.463 791.993C472.484 790.796 474.777 790.139 476.874 789.081C483.253 785.863 487.263 779.21 489.412 772.386C491.56 765.562 492.173 758.358 493.716 751.372C494.514 747.76 495.565 744.187 495.92 740.505C496.148 737.08 496.074 733.642 495.697 730.231L491.383 676.758C491.032 672.405 490.68 668.046 489.998 663.732C477.184 664.191 464.379 664.708 451.558 664.86C441.356 664.98 430.966 665.695 421.286 662.465Z"
            fill="#2F2E41"
          />
          <path
            d="M417.726 677.217C419.477 681.231 423.738 681.148 426.672 678.947C429.606 676.746 431.886 673.049 434.827 670.867C438.78 667.935 443.456 668.046 447.91 668.225C454.961 668.509 462.099 668.811 468.882 671.566C475.899 674.415 482.73 679.901 489.985 678.806C491.248 678.616 492.628 678.1 493.315 676.578C493.718 675.475 493.907 674.24 493.864 672.998L494.101 663.962C494.246 662.399 494.071 660.81 493.596 659.386C492.987 658.201 492.153 657.29 491.192 656.761C480.865 649.634 469.088 648.307 457.622 647.747C451.793 647.463 445.962 647.346 440.128 647.396C435.178 647.439 429.235 646.418 424.422 648.304C417.963 650.834 414.449 669.704 417.726 677.217Z"
            fill="#2F2E41"
          />
          <path
            d="M473.659 519.387C460.225 519.268 448.051 510.384 434.618 510.168C432.995 510.055 431.369 510.336 429.877 510.987C428.006 511.932 426.775 513.758 425.353 515.301C421.381 519.61 415.63 521.911 411.549 526.117C407.723 530.061 405.595 535.937 407.106 541.224C408.616 546.511 414.317 550.567 419.658 549.296C421.81 542.318 426.251 536.273 432.261 532.14C435.388 530.13 438.684 528.397 442.111 526.96C448.149 524.219 454.9 521.426 461.203 523.481C463.527 524.36 465.719 525.558 467.716 527.039L478.224 534.111C477 529.272 475.899 523.846 473.659 519.387Z"
            fill="#7D4EE1"
          />
          <path
            d="M447.204 634.111C447.973 637.434 449.588 640.497 450.577 643.761C452.233 649.229 452.093 655.118 453.746 660.587C454.44 662.883 455.445 665.087 455.943 667.433C457.105 672.902 455.409 678.561 453.303 683.74C451.197 688.918 448.632 694.024 447.949 699.574C447.467 703.483 448.159 707.897 451.166 710.434C454.288 713.067 458.825 712.889 462.892 712.539C464.352 712.498 465.793 712.197 467.148 711.651C470.257 710.177 471.567 706.49 472.294 703.121C474.73 691.831 474.034 680.129 473.326 668.601L471.475 638.45C471.119 632.646 470.737 626.721 468.951 621.148C468.302 619.12 467.17 615.024 465.322 613.729C463.491 612.447 458.263 612.39 455.996 612.283C445.361 611.783 445.48 626.654 447.204 634.111Z"
            fill="#C08B93"
          />
          <path
            d="M458.987 522.791C456.815 521.651 454.079 522.142 451.979 523.411C449.952 524.802 448.177 526.529 446.729 528.517C442.036 534.257 437.068 540.843 437.764 548.229C438.025 550.999 439.083 553.62 439.801 556.307C442.948 568.091 439.468 580.553 439.867 592.744C440.098 599.789 441.628 606.718 443.152 613.599C443.463 615.003 443.961 616.629 445.317 617.102C446.335 617.457 447.436 617 448.444 616.616C452.2 615.226 456.234 614.762 460.207 615.263C464.179 615.765 467.973 617.218 471.267 619.498C473.786 618.152 472.842 613.895 472.277 611.091C470.6 602.762 472.877 594.169 475.643 586.136C478.408 578.103 481.717 570.081 482.176 561.596C482.695 551.982 479.513 542.599 476.374 533.499C475.689 531.513 474.94 529.43 473.351 528.058C471.991 527.044 470.432 526.331 468.777 525.964C465.443 524.977 462.067 524.407 458.987 522.791Z"
            fill="#7D4EE1"
          />
          <path
            d="M837.019 1063.94C844.965 1054.33 856.891 1048.92 869.353 1049.27L917.434 1050.61C932.961 1051.04 946.872 1060.31 953.243 1074.48L976.48 1126.14C982.772 1140.13 980.62 1156.48 970.921 1168.36L940.142 1206.07C932.112 1215.91 919.933 1221.42 907.244 1220.94L858.034 1219.09C842.655 1218.51 828.932 1209.27 822.619 1195.23L799.781 1144.45C793.449 1130.37 795.671 1113.92 805.511 1102.03L837.019 1063.94Z"
            stroke="url(#paint5_linear_skills_illustration)"
            strokeWidth="5"
          />
          <g filter="url(#filter0_d)">
            <path
              d="M913.725 955.413C925.517 946.258 941.019 943.395 955.304 947.734L995.663 959.994C1012.19 965.015 1024.51 978.874 1027.57 995.877L1034.12 1032.38C1037.02 1048.53 1031.11 1065 1018.6 1075.6L983.057 1105.73C971.674 1115.38 956.317 1118.91 941.864 1115.2L906.37 1106.08C888.94 1101.6 875.722 1087.37 872.541 1069.65L864.495 1024.85C861.47 1008.01 868.044 990.876 881.561 980.383L913.725 955.413Z"
              fill="url(#paint6_linear_skills_illustration)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="849.024"
              y="931.702"
              width="201.363"
              height="210.238"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_skills_illustration_skills_illustration"
              x1="904.19"
              y1="135.857"
              x2="996.344"
              y2="1045.8"
              gradientUnits="userSpaceOnUse">
              <stop stopColor={bIsDark ? palette.darkPurple : '#4C15AA'} />
              <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#A342C0'} />
            </linearGradient>
            <linearGradient
              id="paint1_linear_skills_illustration"
              x1="773.997"
              y1="291.674"
              x2="773.997"
              y2="811.288"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#0F0B39" />
              <stop offset="1" stopColor={bIsDark ? palette.darkModePurpleBg : '#3F3D58'} stopOpacity="0.97" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_skills_illustration"
              x1="702.945"
              y1="502.276"
              x2="702.945"
              y2="627.591"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5597E1" />
              <stop offset="1" stopColor="#AF3EB6" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_skills_illustration"
              x1="845.048"
              y1="502.276"
              x2="845.048"
              y2="627.591"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#5E8FDC" />
              <stop offset="1" stopColor="#B43DB3" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_skills_illustration"
              x1="449.151"
              y1="512.694"
              x2="449.151"
              y2="684.296"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#7D4EE1" />
              <stop offset="1" stopColor="#3A1589" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_skills_illustration"
              x1="849.429"
              y1="1045.77"
              x2="926.885"
              y2="1224.96"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#784BFA" />
              <stop offset="1" stopColor="#4E4BFA" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_skills_illustration"
              x1="951.204"
              y1="978.649"
              x2="951.557"
              y2="1113.71"
              gradientUnits="userSpaceOnUse">
              <stop stopColor={bIsDark ? palette.darkPurple : '#AF3FB7'} />
              <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#9048D0'} />
            </linearGradient>
          </defs>
        </svg>
      </VisibilitySensor>
      <SkillsHoneyComb />
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(Skills)));
