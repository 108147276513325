import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../hoc/withTheme';
import withContent from '../../hoc/withContent';
import palette from '../../theme/Palette';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CtaButton from '../CtaButton';
import SectionTitle from '../SectionTitle';
import withEmail from '../../hoc/withEmail';

const ContactForm = ({
  classes,
  bIsDark,
  objContent,
  intContentIndex,
  bEmailSent,
  bLoading,
  nstrError,
  funcSendEmail,
  funcResetContactForm,
}) => {
  const {
    subtitles_text_contactpage: arrSectionTitles,
    form_fields: arrFormFields,
    form_errors: arrFormErrors,
    form_messages: arrFormMessages,
  } = objContent[intContentIndex].data;

  const validateInputs = ({ email, name }) => {
    const errors = {};
    if (!email || !name) {
      errors.message = arrFormErrors[0].text;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors.message = arrFormErrors[1].text;
    }
    return errors;
  };

  const submitMessage = (values, { setSubmitting }) => {
    setSubmitting(false);
    funcSendEmail(values);
  };

  const renderMessage = () => {
    if (bLoading) return arrFormMessages[0].text;
    if (bEmailSent) return arrFormMessages[1].text;
    if (nstrError) return arrFormMessages[2].text;
    return null;
  };

  return (
    <div className={classes.contactForm__container}>
      <div id="contactForm" className={classes.contactForm__title}>
        <SectionTitle strTitleText={arrSectionTitles[0].text} />
      </div>
      <Formik initialValues={{ name: '', email: '', message: '' }} validate={validateInputs} onSubmit={submitMessage}>
        {({ isSubmitting }) => (
          <Form className={classes.contact__form}>
            <Field className={classes.contact__input} type="text" name="name" placeholder={arrFormFields[0].text} />
            <Field className={classes.contact__input} type="email" name="email" placeholder={arrFormFields[1].text} />
            <Field
              className={classes.contact__message}
              as="textarea"
              type="text"
              name="message"
              placeholder={arrFormFields[2].text}
            />
            <ErrorMessage className={classes.contact__error} name="message" component="div" />
            <div className={classes.contact__error}>{renderMessage()}</div>
            <CtaButton strButtonText={arrFormFields[3].text} type="submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>

      <svg className={classes.contact__svg} viewBox="0 0 2085 1808" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2085 1085.98C2052.4 1412.11 1964.66 1513.87 1574.58 1726.76C998.912 2020.93 831 1413 412.34 1449.34C-519.991 1501.52 288.148 75.7343 1257.93 221.462C1257.93 221.462 2085 283.706 2085 1085.98Z"
          fill="url(#paint0_linear_contactForm)"
        />
        <path
          d="M1505.68 467.131H1155.87C1144.4 467.131 1133.4 462.601 1125.29 454.537C1117.17 446.474 1112.62 435.537 1112.62 424.134V264.815C1112.62 262.502 1113.09 260.212 1114.01 258.087C1114.93 255.962 1116.28 254.045 1117.96 252.453L1303.33 77.7811C1310.74 70.7975 1320.56 66.9048 1330.77 66.9048C1340.98 66.9048 1350.81 70.7975 1358.22 77.7811L1534.95 244.321C1539.37 248.482 1542.88 253.492 1545.29 259.048C1547.69 264.604 1548.93 270.588 1548.93 276.635V424.134C1548.93 429.78 1547.81 435.371 1545.64 440.588C1543.46 445.805 1540.28 450.544 1536.26 454.537C1532.24 458.53 1527.48 461.697 1522.23 463.858C1516.98 466.018 1511.36 467.131 1505.68 467.131Z"
          fill="#D3D1EB"
        />
        <path
          opacity="0.1"
          d="M1112.62 267.431H1545.29V441.168C1545.29 448.053 1542.54 454.657 1537.64 459.526C1532.74 464.395 1526.1 467.131 1519.17 467.131H1138.73C1131.81 467.131 1125.16 464.395 1120.27 459.526C1115.37 454.657 1112.62 448.053 1112.62 441.168V267.431Z"
          fill="black"
        />
        <path
          d="M1467.41 130.533H1193.22C1180.08 130.533 1169.43 141.125 1169.43 154.191V426.756C1169.43 439.822 1180.08 450.414 1193.22 450.414H1467.41C1480.55 450.414 1491.21 439.822 1491.21 426.756V154.191C1491.21 141.125 1480.55 130.533 1467.41 130.533Z"
          fill="url(#paint1_linear_contactForm)"
        />
        <path opacity="0.1" d="M1491.21 274.615V450.414H1169.43V274.615L1330.32 351.92L1491.21 274.615Z" fill="black" />
        <path
          d="M1330.32 361.859L1124.17 262.808C1122.89 262.195 1121.48 261.914 1120.07 261.992C1118.65 262.069 1117.28 262.502 1116.08 263.25C1114.88 263.999 1113.89 265.037 1113.21 266.269C1112.52 267.501 1112.16 268.886 1112.16 270.294V440.716C1112.16 444.126 1112.84 447.502 1114.15 450.652C1115.46 453.802 1117.39 456.664 1119.81 459.075C1122.24 461.485 1125.12 463.398 1128.28 464.703C1131.45 466.007 1134.85 466.679 1138.28 466.679H1522.36C1525.79 466.679 1529.18 466.007 1532.35 464.703C1535.52 463.398 1538.4 461.485 1540.82 459.075C1543.25 456.664 1545.17 453.802 1546.48 450.652C1547.8 447.502 1548.47 444.126 1548.47 440.716V273.213C1548.47 271.495 1548.03 269.806 1547.2 268.302C1546.36 266.799 1545.15 265.531 1543.69 264.618C1542.23 263.705 1540.55 263.177 1538.83 263.082C1537.1 262.988 1535.38 263.33 1533.82 264.078L1330.32 361.859Z"
          fill="#E6E6ED"
        />
        <path d="M1287.14 159.901H1197.15V172.552H1287.14V159.901Z" fill="#F2F2F2" />
        <path d="M1457.12 205.986H1189.88V213.215H1457.12V205.986Z" fill="#F2F2F2" />
        <path d="M1457.12 230.383H1189.88V237.611H1457.12Vconta230.383Z" fill="#F2F2F2" />
        <path d="M1457.12 254.781H1189.88V262.01H1457.12V254.781Z" fill="#F2F2F2" />
        <path
          d="M1547.8 63.9182C1547.25 68.7567 1543.54 72.6543 1542.17 77.3271C1540.09 84.3604 1543.67 91.9078 1548.57 97.3854C1552.52 101.759 1557.31 105.309 1562.65 107.83C1567.99 110.351 1573.78 111.794 1579.69 112.073C1581.42 112.225 1583.17 112.036 1584.83 111.516C1586.32 110.922 1587.65 110.015 1588.75 108.857C1592.44 105.175 1594.42 100.075 1595.21 94.9389C1595.99 89.803 1595.69 84.5707 1595.38 79.3843C1595.38 78.3122 1595.18 77.2489 1594.8 76.2469C1594.3 75.2925 1593.61 74.4483 1592.77 73.7645C1590.11 71.3431 1587.19 69.2311 1584.05 67.4695C1582.63 66.8378 1581.38 65.8865 1580.4 64.691C1579.81 63.6668 1579.42 62.5363 1579.27 61.3644L1578.31 56.4859C1578.14 55.08 1577.65 53.7295 1576.89 52.5313C1575.19 50.2499 1571.94 49.9394 1569.08 49.8281L1558.63 49.4203C1555.37 49.2934 1551.09 48.3661 1547.91 49.0012C1544.95 49.5942 1545.93 52.3108 1546.47 54.7843C1547.25 57.7708 1547.7 60.8341 1547.8 63.9182Z"
          fill="#A0616A"
        />
        <path
          opacity="0.1"
          d="M1547.8 63.9182C1547.25 68.7567 1543.54 72.6543 1542.17 77.3271C1540.09 84.3604 1543.67 91.9078 1548.57 97.3854C1552.52 101.759 1557.31 105.309 1562.65 107.83C1567.99 110.351 1573.78 111.794 1579.69 112.073C1581.42 112.225 1583.17 112.036 1584.83 111.516C1586.32 110.922 1587.65 110.015 1588.75 108.857C1592.44 105.175 1594.42 100.075 1595.21 94.9389C1595.99 89.803 1595.69 84.5707 1595.38 79.3843C1595.38 78.3122 1595.18 77.2489 1594.8 76.2469C1594.3 75.2925 1593.61 74.4483 1592.77 73.7645C1590.11 71.3431 1587.19 69.2311 1584.05 67.4695C1582.63 66.8378 1581.38 65.8865 1580.4 64.691C1579.81 63.6668 1579.42 62.5363 1579.27 61.3644L1578.31 56.4859C1578.14 55.08 1577.65 53.7295 1576.89 52.5313C1575.19 50.2499 1571.94 49.9394 1569.08 49.8281L1558.63 49.4203C1555.37 49.2934 1551.09 48.3661 1547.91 49.0012C1544.95 49.5942 1545.93 52.3108 1546.47 54.7843C1547.25 57.7708 1547.7 60.8341 1547.8 63.9182Z"
          fill="#F1F1F1"
        />
        <path
          d="M1614.33 281.699C1612.84 297.849 1611.36 313.996 1609.88 330.141C1608.72 342.846 1607.54 355.604 1605.26 368.146C1604.48 372.467 1603.56 376.767 1602.47 381.03C1599.43 392.865 1595.02 404.432 1593.6 416.565C1593.55 416.914 1593.52 417.271 1593.48 417.628C1593.32 420.311 1592.81 422.962 1591.96 425.513C1591.25 427.366 1590.16 429.054 1589.39 430.884C1587.85 434.544 1587.65 438.591 1587.46 442.556C1582.07 439.539 1576.16 437.532 1570.03 436.634C1567.08 436.196 1563.79 435.883 1561.77 433.689C1559.54 431.264 1559.79 427.552 1560.16 424.294C1561.34 414.177 1562.51 404.065 1563.68 393.958C1564.06 389.61 1564.81 385.3 1565.9 381.074C1566.31 379.668 1566.79 378.285 1567.29 376.901C1567.97 374.997 1568.66 373.092 1569.17 371.136C1569.92 367.975 1570.44 364.766 1570.73 361.532C1571.93 351.356 1573.39 341.202 1574.66 331.033L1574.73 330.498C1576.63 315.085 1578.09 299.597 1577.67 284.072C1577.61 281.774 1577.51 279.475 1577.36 277.169C1577.15 273.829 1576.84 272.059 1579.63 269.968C1582.7 267.662 1586.75 266.048 1590.27 264.538C1592.16 263.72 1613.01 256.363 1613.5 257.977C1614.47 261.42 1614.99 264.977 1615.02 268.555C1615.04 272.946 1614.8 277.334 1614.33 281.699Z"
          fill="#37326A"
        />
        <path
          opacity="0.1"
          d="M1605.26 368.146C1604.48 372.468 1603.56 376.767 1602.46 381.03C1599.43 392.865 1595.02 404.432 1593.6 416.565C1593.55 416.914 1593.51 417.272 1593.48 417.629C1584.18 404.432 1576.36 390.239 1567.29 376.901C1567.97 374.997 1568.66 373.093 1569.17 371.136C1569.92 367.975 1570.44 364.766 1570.73 361.533C1571.93 351.356 1573.39 341.202 1574.66 331.034L1574.73 330.498C1575.26 332.657 1576.02 334.754 1576.99 336.754C1583.08 349.058 1597.56 355.277 1604.63 367.052C1604.85 367.417 1605.06 367.781 1605.26 368.146Z"
          fill="black"
        />
        <path
          d="M1529.85 259.955C1530.16 269.824 1531.75 279.603 1533.33 289.349L1536.6 309.458C1538.39 320.464 1540.26 331.693 1545.5 341.551C1549.42 348.925 1555.06 355.217 1560.17 361.832C1572.73 378.096 1582.16 396.519 1594.07 413.26C1597.53 418.125 1602.48 423.387 1608.39 422.371C1612.26 421.705 1615.08 418.496 1618.13 416.022C1621.72 413.105 1626.05 410.971 1628.88 407.315C1631.7 403.659 1632.26 397.582 1628.43 394.981C1627.04 394.217 1625.57 393.619 1624.04 393.198C1618.11 390.953 1614.26 385.19 1611.82 379.362C1609.37 373.534 1607.89 367.267 1604.63 361.847C1597.56 350.072 1583.08 343.853 1576.99 331.545C1573.58 324.651 1573.25 316.706 1572.99 309.027L1572.33 289.453C1572.25 287.312 1572.2 285.086 1573.07 283.129C1575.4 277.918 1582.44 277.433 1588.14 276.773C1594.6 276.019 1600.87 274.054 1606.6 270.982C1609.88 269.221 1613.09 266.975 1614.84 263.704C1616.44 260.719 1616.66 257.219 1616.85 253.842L1618.82 218.368C1618.99 215.316 1619.15 212.16 1618.07 209.299C1615.35 202.115 1606.22 199.767 1598.51 200.11C1584.16 200.748 1570.91 206.886 1557.38 211.03C1554.02 212.082 1550.62 212.964 1547.18 213.674C1544.14 214.28 1540.47 214.029 1537.57 215C1532.42 216.726 1532.55 227.925 1531.82 232.571C1530.31 241.619 1529.65 250.786 1529.85 259.955Z"
          fill="#37326A"
        />
        <path
          d="M1606.44 423.86C1607.7 425.129 1608.12 426.987 1608.45 428.744C1610.27 438.488 1611.23 448.374 1611.29 458.285C1611.2 459.21 1611.39 460.14 1611.82 460.962C1612.18 461.452 1612.66 461.838 1613.22 462.079C1616.88 463.812 1621.12 461.397 1624.17 458.742C1629.82 453.822 1634.6 447.217 1634.93 439.756C1634.87 437.835 1634.93 435.911 1635.13 433.998C1635.62 431.19 1637.27 428.742 1638.73 426.284C1641.2 422.099 1643.2 417.652 1644.68 413.026C1645.23 411.288 1645.6 409.1 1644.23 407.887C1643.75 407.509 1643.2 407.223 1642.6 407.045L1626.08 400.946C1624.65 400.418 1621.09 409.516 1620.52 410.511C1619.14 412.944 1617.76 414.326 1614.99 414.887C1612.15 415.46 1608.78 414.638 1606.14 416.043C1601.67 418.42 1603.91 421.321 1606.44 423.86Z"
          fill="#535070"
        />
        <path
          d="M1580.94 429.475C1580.63 428.867 1580.21 428.328 1579.68 427.89C1578.92 427.407 1578.05 427.114 1577.15 427.033C1574 426.502 1570.27 426.152 1568.1 428.477C1566.07 430.656 1566.42 434.206 1564.71 436.652C1564.18 437.426 1563.45 438.064 1562.98 438.883C1562.4 440.135 1562.14 441.509 1562.22 442.885C1562.13 446.676 1562.04 450.473 1562.3 454.256C1562.53 457.426 1563.06 460.748 1565.06 463.231C1568.26 467.217 1574.03 467.79 1579.15 468.049L1583.3 468.258C1584.45 468.396 1585.61 468.291 1586.7 467.948C1587.38 467.636 1587.99 467.191 1588.5 466.64C1589 466.089 1589.39 465.443 1589.64 464.74C1591.8 459.403 1588.38 453.653 1586.27 448.294C1583.85 442.136 1584.28 435.298 1580.94 429.475Z"
          fill="#535070"
        />
        <path
          d="M1546.6 36.6553C1546.55 36.1142 1546.63 35.5689 1546.83 35.0648C1547.44 33.9964 1549.02 34.2872 1550.22 34.5873C1555.04 35.7939 1560.11 34.7017 1565.07 34.9836C1570.02 35.2655 1575.61 37.7291 1576.6 42.5646C1580.2 40.0288 1583.3 36.8607 1585.76 33.2163C1587.13 31.1696 1588.33 28.8253 1588.14 26.3708C1587.87 22.8304 1584.89 20.1827 1582.12 17.9374L1573.87 11.2443C1572.79 10.2554 1571.54 9.46516 1570.18 8.91043C1568.27 8.26344 1566.2 8.5717 1564.19 8.57298C1558.18 8.5768 1551.78 5.86071 1546.44 8.58918C1544.21 9.72892 1542.53 11.682 1540.91 13.5865L1533.55 22.2485C1533 22.8005 1532.59 23.4828 1532.38 24.232C1532.17 25.2893 1532.71 26.3328 1532.9 27.3936C1533.25 29.3287 1532.41 31.3939 1533.06 33.2522C1534.13 36.3373 1537.9 36.774 1540.2 38.5427C1541.06 39.2018 1542.4 41.4358 1543.43 41.4085C1545.27 41.3593 1546.55 38.0808 1546.6 36.6553Z"
          fill="#3F3D56"
        />
        <path
          d="M1559.09 62.1878C1572.1 62.1878 1582.66 51.6968 1582.66 38.7556C1582.66 25.8143 1572.1 15.3234 1559.09 15.3234C1546.07 15.3234 1535.52 25.8143 1535.52 38.7556C1535.52 51.6968 1546.07 62.1878 1559.09 62.1878Z"
          fill="#C08B93"
        />
        <path
          d="M1564.61 90.0376C1563.81 87.7604 1562.3 85.7956 1560.3 84.4282C1558.3 83.0609 1555.92 82.362 1553.49 82.433C1552.8 82.5613 1552.08 82.4712 1551.44 82.1752C1551.08 81.8988 1550.79 81.5395 1550.6 81.1278C1549.16 78.5656 1548.01 75.8578 1547.16 73.0508C1546.79 71.8106 1546.27 70.3484 1545.01 70.0259C1544.49 69.9579 1543.96 70.0324 1543.47 70.2414C1542.99 70.4504 1542.57 70.786 1542.27 71.2124C1541.67 72.0706 1541.01 72.8891 1540.3 73.6625C1537.34 76.2976 1532.08 73.8959 1528.94 76.3134C1528.42 76.7083 1528 77.2141 1527.46 77.5733C1526.92 77.8966 1526.34 78.1448 1525.73 78.311C1519.65 80.2829 1513.7 82.6021 1507.89 85.2568C1509.98 97.7871 1512.13 110.536 1517.03 122.264C1519.37 127.845 1522.01 133.312 1523.88 139.064C1528.03 151.808 1528.21 165.444 1528.26 178.837C1528.29 188.003 1528.24 197.365 1525.26 206.038C1524.46 207.955 1523.89 209.962 1523.57 212.014C1523.39 214.081 1524 216.36 1525.68 217.584C1526.66 218.219 1527.78 218.609 1528.94 218.721C1534.11 219.475 1539.32 218.107 1544.41 216.935C1558.05 213.795 1571.96 211.997 1585.85 210.205L1609.12 207.201C1610.79 207.089 1612.42 206.669 1613.94 205.964C1618.19 203.647 1618.6 197.279 1615.95 193.232C1614.69 191.292 1612.9 189.7 1611.83 187.643C1610.77 185.586 1610.68 182.71 1612.51 181.285C1616.24 178.387 1615.13 173.01 1615.31 168.307C1615.8 155.157 1615.74 142.002 1615.1 128.842C1614.92 126.118 1614.87 123.386 1614.97 120.657C1615.39 116.033 1616.24 111.459 1617.54 106.999L1624.61 79.3228C1622.76 76.4211 1619.23 74.5802 1615.87 73.7443C1612.52 72.9085 1609.04 72.6625 1605.74 71.6461C1598.98 69.564 1592.96 64.2799 1585.9 64.7723C1585.37 64.7819 1584.84 64.9093 1584.36 65.1455C1582.77 66.0421 1583.02 68.323 1582.92 70.1392C1582.69 74.1574 1579.72 77.5158 1576.44 79.8704C1573.16 82.225 1569.41 83.9419 1566.39 86.6272C1565.86 87.0275 1565.42 87.5415 1565.11 88.1316C1564.8 88.7217 1564.63 89.3729 1564.61 90.0376Z"
          fill="url(#paint2_linear_contactForm)"
        />
        <path
          d="M1471.21 116.29C1469.65 117.298 1467.99 118.147 1466.25 118.826C1464.55 119.328 1462.95 120.102 1461.5 121.117C1460.84 121.69 1460.28 122.377 1459.85 123.145C1459.05 124.552 1458.65 126.146 1458.68 127.76C1458.72 129.375 1459.18 130.951 1460.04 132.324C1460.89 133.697 1462.1 134.817 1463.54 135.567C1464.98 136.316 1466.6 136.668 1468.22 136.586C1470.37 136.346 1472.47 135.725 1474.41 134.752L1491.03 127.59C1493.85 126.378 1496.89 124.957 1498.22 122.21C1498.37 121.954 1498.43 121.651 1498.38 121.356C1498.24 121.045 1498 120.791 1497.69 120.643C1493.94 118.222 1490.66 115.148 1488.01 111.568C1487.33 110.648 1486.5 108.474 1485.53 107.922C1482.01 105.907 1474.03 114.472 1471.21 116.29Z"
          fill="#C08B93"
        />
        <path
          d="M1648.94 110.413L1655.07 117.373C1656.39 118.867 1657.7 120.363 1658.9 121.952C1661.85 125.987 1664.21 130.412 1665.92 135.099C1667.26 138.574 1668.36 142.276 1667.9 145.966C1667.59 147.858 1667.07 149.711 1666.36 151.494C1665.24 154.921 1663.85 158.255 1662.2 161.465C1660.81 163.905 1659.24 166.238 1657.51 168.448C1652.76 174.7 1647.57 180.605 1641.97 186.115C1639.88 188.177 1639 191.512 1638.09 194.299C1636.4 199.44 1632.4 203.445 1628.53 207.242C1627.28 208.548 1625.91 209.724 1624.43 210.753C1622.2 212.177 1619.6 212.912 1616.96 212.867C1615.8 212.875 1614.65 212.639 1613.59 212.175C1610.94 210.924 1609.6 207.879 1609.32 204.976C1609.07 202.342 1609.52 199.689 1610.61 197.277C1614.13 189.531 1623.16 186.175 1629.54 180.523C1633.29 177.209 1636.15 173.047 1638.97 168.929C1640.89 166.359 1642.49 163.569 1643.74 160.619C1645.17 156.837 1644.61 152.36 1645.86 148.516C1646.39 146.891 1647.32 145.424 1647.89 143.812C1648.45 142.2 1648.6 140.287 1647.61 138.892C1646.86 138.006 1645.91 137.313 1644.83 136.872C1640.75 134.815 1636.18 132.166 1635.4 127.686C1635.09 125.202 1635.75 122.693 1637.23 120.67C1638.71 118.675 1640.44 116.874 1642.37 115.312C1644.16 113.744 1647.16 111.981 1648.94 110.413Z"
          fill="#C08B93"
        />
        <path
          d="M1467.28 126.005C1466.19 123.831 1464.84 121.614 1462.66 120.533C1461.6 120.076 1460.46 119.838 1459.3 119.833C1458.15 119.829 1457 120.057 1455.94 120.505C1453.82 121.413 1451.84 122.605 1450.05 124.048L1444.77 127.919C1443.57 128.7 1442.51 129.673 1441.63 130.797C1440.8 132.161 1440.24 133.675 1440 135.253C1439.08 138.85 1438.79 142.58 1439.17 146.274C1440 152.197 1443.6 157.367 1447.55 161.876C1450.02 164.693 1453.07 167.536 1456.83 167.656C1458.45 167.708 1460.25 167.072 1460.93 165.605C1461.16 165.023 1461.29 164.404 1461.29 163.777C1461.7 157.171 1459.32 150.719 1456.55 144.701C1455.67 143.126 1455.15 141.375 1455.02 139.575C1455 139.272 1455.08 138.971 1455.25 138.717C1455.49 138.469 1455.81 138.314 1456.15 138.279C1459.94 137.533 1466.48 140.463 1469.14 136.274C1470.87 133.559 1468.5 128.467 1467.28 126.005Z"
          fill="#C08B93"
        />
        <path
          d="M1545.36 29.2821C1545.31 28.741 1545.39 28.1957 1545.59 27.6916C1546.2 26.6233 1547.79 26.914 1548.99 27.2141C1553.8 28.4208 1558.87 27.3285 1563.83 27.6104C1568.79 27.8923 1574.37 30.3559 1575.37 35.1914C1578.97 32.6556 1582.07 29.4875 1584.52 25.8432C1585.9 23.7965 1587.1 21.4521 1586.91 18.9976C1586.64 15.4573 1583.65 12.8095 1580.88 10.5642L1572.64 3.87114C1571.55 2.8822 1570.3 2.09199 1568.94 1.53727C1567.04 0.890282 1564.96 1.19853 1562.95 1.19981C1556.95 1.20364 1550.55 -1.51245 1545.21 1.21601C1542.98 2.35575 1541.3 4.30888 1539.68 6.21332L1532.32 14.8753C1531.76 15.4274 1531.36 16.1097 1531.14 16.8588C1530.93 17.9162 1531.48 18.9596 1531.67 20.0204C1532.02 21.9555 1531.18 24.0208 1531.82 25.8791C1532.89 28.9642 1536.66 29.4009 1538.96 31.1695C1539.82 31.8287 1541.17 34.0626 1542.19 34.0354C1544.04 33.9861 1545.31 30.7076 1545.36 29.2821Z"
          fill="#3F3D56"
        />
        <path
          d="M1495.3 93.4974C1494.35 94.1076 1493.51 94.8806 1492.83 95.7807C1491.97 97.0796 1491.79 98.7194 1491.05 100.091C1489.46 103.025 1485.74 104.069 1483.43 106.477C1483.06 106.808 1482.8 107.244 1482.69 107.726C1482.68 108.379 1482.9 109.016 1483.32 109.524L1494.49 126.992C1498.22 127.318 1501.79 125 1505.51 125.308C1506.9 125.423 1508.32 125.9 1509.65 125.492C1510.61 125.071 1511.5 124.527 1512.31 123.874C1514.54 122.478 1517.49 122.315 1519.36 120.473C1521.15 118.705 1521.37 115.94 1521.35 113.432C1521.38 111.255 1521.16 109.082 1520.69 106.955C1519.04 100.25 1513.88 95.1049 1510.65 89.1655C1509.08 86.274 1508.22 84.5812 1504.89 86.5667C1501.54 88.5623 1498.45 91.2058 1495.3 93.4974Z"
          fill="url(#paint3_linear_contactForm)"
        />
        <path
          d="M1631.05 84.6628C1633.15 86.0188 1635.04 87.6678 1636.67 89.5592C1638.37 91.7418 1639.34 94.413 1640.97 96.6458C1642.56 98.81 1644.72 100.486 1646.51 102.479C1649.24 105.501 1651.09 109.189 1652.9 112.826C1648.47 118.571 1643.64 123.999 1638.44 129.071C1637.87 129.748 1637.1 130.246 1636.25 130.505C1635.67 130.567 1635.08 130.484 1634.54 130.265C1634 130.045 1633.52 129.696 1633.14 129.248C1632.34 128.408 1631.77 127.373 1631 126.501C1629.18 124.639 1626.76 123.475 1624.17 123.216C1621.59 122.968 1619 122.933 1616.42 123.11C1615.86 123.172 1615.28 123.077 1614.77 122.833C1614.39 122.571 1614.08 122.217 1613.87 121.805C1610.84 116.685 1611.81 110.258 1612.88 104.416L1615.33 91.0106C1615.69 88.4503 1616.37 85.9462 1617.37 83.56C1618.3 81.5886 1620.37 78.2455 1622.93 78.8357C1625.5 79.4306 1628.88 83.1156 1631.05 84.6628Z"
          fill="url(#paint4_linear_contactForm)"
        />
        <path
          d="M1234.46 377.815C1255.04 377.815 1271.73 361.198 1271.73 340.7C1271.73 320.201 1255.04 303.584 1234.46 303.584C1213.88 303.584 1197.19 320.201 1197.19 340.7C1197.19 361.198 1213.88 377.815 1234.46 377.815Z"
          fill="white"
        />
        <path
          d="M1234.46 300.872C1225.65 300.872 1217.04 303.469 1209.72 308.334C1202.39 313.2 1196.68 320.115 1193.31 328.205C1189.94 336.296 1189.06 345.199 1190.78 353.788C1192.5 362.377 1196.74 370.266 1202.97 376.458C1209.2 382.651 1217.13 386.868 1225.77 388.576C1234.41 390.284 1243.37 389.408 1251.51 386.056C1259.65 382.705 1266.6 377.03 1271.5 369.749C1276.39 362.467 1279 353.907 1279 345.15C1278.97 333.417 1274.26 322.175 1265.92 313.879C1257.57 305.583 1246.26 300.907 1234.46 300.872ZM1225.32 368.757L1202.49 346.065L1208.89 339.7L1225.34 356.049L1260.05 321.542L1266.45 327.907L1225.32 368.757L1225.32 368.757Z"
          fill="#6596da"
        />
        <defs>
          <linearGradient
            id="paint0_linear_contactForm"
            x1="1388.92"
            y1="221.261"
            x2="595.868"
            y2="1336.85"
            gradientUnits="userSpaceOnUse">
            <stop stop-color={bIsDark ? palette.darkPurple : '#802CB5'} />
            <stop offset="1" stop-color={bIsDark ? palette.darkPurple : '#3A205B'} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_contactForm"
            x1="1330.32"
            y1="130.533"
            x2="1330.32"
            y2="450.414"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#534C80" />
            <stop offset="1" stop-color="#6C63FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_contactForm"
            x1="1566.25"
            y1="64.7401"
            x2="1566.25"
            y2="218.937"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#2F27AF" stop-opacity="0.97" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_contactForm"
            x1="1502.02"
            y1="85.7059"
            x2="1502.41"
            y2="170.389"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#2F27AF" stop-opacity="0.97" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_contactForm"
            x1="1632.37"
            y1="78.7668"
            x2="1618.49"
            y2="163.134"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#2F27AF" stop-opacity="0.97" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default withEmail(withContent(withTheme(withStyles(styles)(ContactForm))));
