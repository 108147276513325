export default (theme) => ({
  textBubble__container: {
    alignItems: 'center',
    background: theme.textBubble.gradient,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    height: '85%',
    width: '85%',
  },
  textBubble__background: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: theme.textBubble.borderGradient,
    borderRadius: '50%',
  },
  textBubble__text: {
    fontFamily: theme.fonts.quickSand,
    color: theme.textColor,
    padding: '0 24px',
    fontSize: 14,
    textAlign: 'center',
  },
});
