export default (theme) => ({
  projects__mainSvg: {
    width: 1000,
    height: 641,
    marginTop: 70,
    position: 'relative',
    zIndex: 1,
  },
  header__ctaProjects: {
    position: 'absolute',
    zIndex: 2,
    right: 20,
    top: 180,
  },
  header__titleProjects: {
    position: 'absolute',
    zIndex: 2,
    right: 4,
    top: 120,
    color: theme.palette.white,
    fontFamily: theme.fonts.passionOne,
    fontWeight: 400,
    textAlign: 'right',
    fontSize: 30,
    lineHeight: 1,
    letterSpacing: 1,
    width: 190,
  },
  shape__containerProjects: {
    position: 'relative',
    width: 565,
  },
  //topLeftShape
  topLeft__shape: {
    height: 240,
    position: 'absolute',
    top: 280,
    width: 210,
    left: 50,
  },
  header__shapeBg: {
    position: 'absolute',
    bottom: -40,
    left: '60%',
    width: 560,
    height: 650,
  },

  //bubble
  header__text: {
    position: 'absolute',
    top: '30%',
    right: '-80%',
    zIndex: 2,
  },

  //tablet
  '@media (max-width: 1120px)': {
    shape__containerProjects: {
      marginTop: 65,
      marginBottom: 100,
      width: '60%',
    },
    header__text: {
      top: '70%',
      right: '0',
    },
    projects__mainSvg: {
      width: '100vw',
    },
  },

  //mobile
  '@media (max-width: 800px)': {
    topLeft__shape: {
      display: 'none',
    },
    shape__containerProjects: {
      width: '60%',
      height: 850,
    },
    header__text: {
      position: 'absolute',
      right: '-40%',
    },
    header__shapeBg: {
      bottom: -200,
      left: '30%',
      transform: 'rotate(-30deg)',
      '& svg': {
        width: 420,
      },
    },
  },
  '@media (max-width: 720px)': {
    projects__mainSvg: {
      width: '110vw',
      marginLeft: -80,
    },
    header__titleProjects: {
      top: 150,
      right: 25,
    },
    shape__containerProjects: {
      width: '70%',
    },
    header__ctaProjects: {
      position: 'absolute',
      bottom: 330,
      right: 40,
      top: 'unset',
    },
  },
  '@media (max-width: 600px)': {
    header__titleProjects: {
      top: 190,
      right: 25,
    },
    header__ctaProjects: {
      right: '9%',
    },
  },
  '@media (max-width: 470px)': {
    projects__mainSvg: {
      marginTop: 30,
      width: '120vw',
    },

    header__titleProjects: {
      top: 180,
      fontSize: 25,
    },
  },
  '@media (max-width: 400px)': {
    projects__mainSvg: {
      width: '138vw',
      marginLeft: -125,
    },
  },
});
