import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { BackgroundShape, TopRightShape } from './components';
import withTheme from '../../hoc/withTheme';
import withContent from '../../hoc/withContent';
import ROUTES from '../../constants/routes';
import posed from 'react-pose';
import palette from '../../theme/Palette';

const MobileNav = posed.div({
  open: {
    scale: 7,
    background: 'rgb(64, 59, 137)',
    transition: {
      scale: { ease: 'easeInOut', duration: 300 },
    },
  },
  closed: { scale: 1, background: palette.brightPurple },
});

const NavLinks = posed.div({
  open: { opacity: 1, x: 0, duration: 10 },
  closed: { opacity: 0, x: 200, duration: 10 },
});

const LineOne = posed.div({
  open: { rotate: 45, height: 5, background: palette.brightPurple },
  closed: { rotate: 0, height: 3, background: palette.white },
});

const LineTwo = posed.div({
  open: { rotate: -45, height: 5 },
  closed: { rotate: 0, height: 3 },
});

const Navigation = (props) => {
  const { classes, bIsDark, bIsFooter = false, bIsTabletScreen, objContent, intContentIndex } = props;
  const [bIsOpen, setOpen] = useState(false);

  const { nav_links } = objContent[intContentIndex].data;

  const renderNavigationType = () => {
    if (bIsFooter) return renderFooterNavigation();
    if (bIsTabletScreen) return renderMobileNavigation();
    return renderHeaderNavigation();
  };

  const handleMobileClick = () => {
    setOpen(!bIsOpen);
  };

  const renderMobileNavigation = () => {
    return (
      <div className={classes.nav__wrapper}>
        <MobileNav pose={bIsOpen ? 'open' : 'closed'} className={classes.nav__containerMobile}></MobileNav>
        <div onClick={handleMobileClick} className={classes.nav__linesContainer}>
          <LineOne pose={bIsOpen ? 'open' : 'closed'} className={classes.nav__line} />
          <div className={classes.nav__line} style={{ display: bIsOpen ? 'none' : 'block' }}></div>
          <LineTwo pose={bIsOpen ? 'open' : 'closed'} className={classes.nav__line} />
        </div>
        {renderNavLinks()}
      </div>
    );
  };

  const renderNavLinks = () => {
    return bIsTabletScreen ? (
      <NavLinks
        pose={bIsOpen ? 'open' : 'closed'}
        className={classes.nav__mobile}
        style={{ display: bIsOpen ? 'flex' : 'none' }}>
        <NavLink exact activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.HOME}>
          {nav_links[0].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.PROJECTS}>
          {nav_links[1].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.CONTACT}>
          {nav_links[2].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.BLOG}>
          {nav_links[3].text}
        </NavLink>
      </NavLinks>
    ) : (
      <>
        <NavLink exact activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.HOME}>
          {nav_links[0].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.PROJECTS}>
          {nav_links[1].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.CONTACT}>
          {nav_links[2].text}
        </NavLink>
        <NavLink activeClassName={classes.nav__linkActive} className={classes.nav__link} to={ROUTES.BLOG}>
          {nav_links[3].text}
        </NavLink>
      </>
    );
  };

  const renderHeaderNavigation = () => {
    return (
      <div className={classes.navigation}>
        <nav className={classes.nav__container}>{renderNavLinks()}</nav>
        <div className={classes.nav__shapes}>
          <BackgroundShape bIsDark={bIsDark} />
          <div className={classes.nav__rightShape}>
            <TopRightShape bIsDark={bIsDark} />
          </div>
        </div>
      </div>
    );
  };

  const renderFooterNavigation = () => {
    return <nav className={classes.nav__container__footer}>{renderNavLinks()}</nav>;
  };

  return renderNavigationType();
};

export default withContent(withTheme(withStyles(styles)(Navigation)));
