export default (theme) => ({
  '@keyframes grow': {
    from: { width: 0, height: 0 },
    to: { width: 80, height: 80 },
  },
  title__text: {
    fontFamily: theme.fonts.montez,
    fontSize: 45,
    fontWeight: 500,
    position: 'relative',
    zIndex: 1,
    color: theme.textColor,
  },
  title__container: {
    position: 'relative',
    width: 150,
  },
  title__circle: {
    borderRadius: '50%',
    border: `2.5px solid ${theme.palette.purple}`,
    top: -10,
    position: 'absolute',
    left: -20,
  },
  title__animate: {
    animation: '$grow 0.8s forwards',
  },
});
