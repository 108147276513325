import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendEmail, resetContactForm } from '../../redux/actions/ActionSendEmail';

const withEmail = (WrappedComponent) => {
  class HOC extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      funcSendEmail: (objEmailData) => dispatch(sendEmail(objEmailData)),
      funcResetContactForm: () => dispatch(resetContactForm()),
    };
  };

  const mapStateToProps = (state, ownProps) => {
    const { bLoading, nstrError, bEmailSent } = state.EmailReducer;
    return {
      bEmailSent,
      bLoading,
      nstrError,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withEmail;
