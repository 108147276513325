import Prismic from 'prismic-javascript';

export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const RECEIVED_CONTENT_DATA = 'RECEIVED_CONTENT_DATA';
export const FETCH_CONTENT_ERROR = 'FETCH_CONTENT_ERROR';

const CONTENT_URI = process.env.REACT_APP_PRISMIC_URL;

export const fetchContentBegin = () => {
  return {
    type: FETCH_CONTENT_START,
  };
};

export const fetchContentError = (error) => {
  return {
    type: FETCH_CONTENT_ERROR,
    payload: error,
  };
};

export const receivedContentData = (payload) => {
  return {
    type: RECEIVED_CONTENT_DATA,
    payload: payload,
  };
};

export const fetchContent = () => {
  return (dispatch) => {
    dispatch(fetchContentBegin());
    return Prismic.api(CONTENT_URI)
      .then((api) => {
        api
          .query(Prismic.Predicates.at('document.type', 'page'), { lang: '*' })
          .then((response) => dispatch(receivedContentData(response.results)))
          .catch(function (error) {
            console.log(error);
            dispatch(fetchContentError(error));
          });
      })
      .catch(function (error) {
        console.log(error);
        dispatch(fetchContentError(error));
      });
  };
};
