import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../hoc/withTheme';
import palette from '../../../theme/Palette';
import { Parallax } from 'react-skrollr';
import generateRandomString from '../../../util/generateRandomString';

const FloatingAbstractShape = ({ classes, bIsDark, bRoundType, bLight, objParallaxOptions = {} }) => {
  const determineColor = (strDefaultColor) => {
    if (bIsDark) return palette.darkPurple;
    if (bLight) return palette.greyPurple;
    return strDefaultColor;
  };

  const strId = generateRandomString();

  const renderShape = () => {
    switch (bRoundType) {
      case true:
        return (
          <svg className={classes.shape__svg} viewBox="0 0 356 431" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M143.484 404.612C71.2765 367.919 11.4508 290.439 29.8388 207.805C48.2269 125.172 158.383 8.14484 224.198 27.6291C349.608 36.953 341.773 375.7 264.312 344.897C224.639 348.477 209.299 424.096 143.484 404.612Z"
              fill={`url(#paint0_linear_abstract_round${strId})`}
            />
            <defs>
              <linearGradient
                id={`paint0_linear_abstract_round${strId}`}
                x1="210.404"
                y1="24.9075"
                x2="145.056"
                y2="405.481"
                gradientUnits="userSpaceOnUse">
                <stop stopColor={determineColor('#8025BC')} />
                <stop offset="1" stopColor={determineColor('#7923B9')} />
              </linearGradient>
            </defs>
          </svg>
        );
      default:
        return (
          <svg className={classes.shape__svg} viewBox="0 0 379 408" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M84.6963 270.049C55.3213 183.295 56.4627 86.0284 113.721 58.5178C170.978 31.0071 267.738 80.668 302.068 152.212C336.397 223.757 235.541 438.348 199.397 349.899C178.009 267.615 119.026 341.593 84.6963 270.049Z"
              fill={`url(#paint0_linear_abstract_regular${strId})`}
            />
            <defs>
              <linearGradient
                id={`paint0_linear_abstract_regular${strId}`}
                x1="296.618"
                y1="141.918"
                x2="81.9363"
                y2="265.864"
                gradientUnits="userSpaceOnUse">
                <stop stopColor={determineColor('#631CB2')} />
                <stop offset="0.912044" stopColor={determineColor('#4C15AA')} />
              </linearGradient>
            </defs>
          </svg>
        );
    }
  };

  const { strStartScroll = '0', strEndScroll = '500', strTranslateY = '-80px' } = objParallaxOptions;
  return (
    <Parallax
      data={{
        [`data-${strStartScroll}`]: 'transform:translateY(0px);',
        [`data-${strEndScroll}`]: `transform:translateY(${strTranslateY});`,
      }}>
      {renderShape()}
    </Parallax>
  );
};

export default withTheme(withStyles(styles)(FloatingAbstractShape));
