import {
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_START,
  RESET_CONTACT_FORM,
} from '../../actions/ActionSendEmail';

const objInitialState = {
  bLoading: false,
  nstrError: null,
  bEmailSent: false,
};

function todosReducer(state = objInitialState, action) {
  switch (action.type) {
    case SEND_EMAIL_START:
      return {
        ...state,
        bLoading: true,
        nstrError: null,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        bEmailSent: true,
        bLoading: false,
        nstrError: null,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        bEmailSent: false,
        bLoading: false,
        nstrError: true,
      };
    case RESET_CONTACT_FORM:
      return {
        bLoading: false,
        nstrError: null,
        bEmailSent: false,
      };
    default:
      return state;
  }
}

export default todosReducer;
