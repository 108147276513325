export default (theme) => ({
  fab__container: {
    position: 'fixed',
    bottom: 80,
    right: 20,
    height: 70,
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 33,
    background: theme.fabLinearGradient,
    '& .react-toggle-track': {
      height: 20,
      width: 60,
    },
    '& .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track ': {
      backgroundColor: 'rgb(86, 18, 141)',
    },
    '& .react-toggle--checked .react-toggle-track': {
      backgroundColor: '#1e2351',
    },
    '& .react-toggle': {
      position: 'relative',
    },
    '& .react-toggle-thumb': {
      height: 18,
      width: 18,
      marginLeft: 3,
    },
    '& .react-toggle--checked .react-toggle-thumb': {
      left: 36,
      borderColor: 'none',
    },
    zIndex: 10,
  },

  fab__flag: {
    height: 16,
    margin: '5px 5px 3px',
    cursor: 'pointer',
  },
  fab__sun: {
    height: 17,
    position: 'absolute',
    top: -4,
    marginLeft: -3,
  },
  fab__moon: {
    height: 15,
    position: 'absolute',
    top: -3,
    right: -4,
  },
  '@media (max-width: 500px)': {
    fab__container: {
      right: 8,
      width: 72,
      bottom: 20,
      height: 60,
      borderRadius: 26,
      '& .react-toggle-track': {
        width: 54,
      },
      '& .react-toggle--checked .react-toggle-thumb': {
        left: 30,
      },
    },
  },
});
