export default (theme) => ({
  honeyComb__mainSvg: {
    width: 400,
    position: 'relative',
  },
  honeyComb__container: {
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 30,
  },
  hexagon: {
    '&:hover': {
      fill: theme.skills.hexagon,
      stroke: theme.skills.hexagon,
    },
  },
  honeyComb__leftShape: {
    position: 'absolute',
    top: 0,
    left: '15%',
  },
  honeyComb__rightShape: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 0,
  },
  '@media (max-width: 440px)': {
    // honeyComb__leftShape: {
    //   display: 'none',
    // },
    // honeyComb__rightShape: {
    //   display: 'none',
    // },
    honeyComb__mainSvg: {
      width: '90%',
    },
  },
});
