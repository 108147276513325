export default (theme) => ({
  projectCard__container: {
    height: 220,
    width: 298,
    borderRadius: 30,
    backgroundSize: '101%',
    position: 'relative',
    margin: '20px 10px',
  },
  projectCard__info: {
    clipPath: 'polygon(25% 28%, 100% 2%, 100% 100%, 0 100%, 0 28%)',
    height: 90,
    width: '100%',
    position: 'absolute',
    bottom: -1,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  projectCard__personal: {
    background: theme.projects.personalBackground,
  },
  projectCard__commercial: {
    background: theme.projects.workBackground,
  },
  projectCard__title: {
    color: theme.palette.white,
    fontSize: 20,
    fontFamily: theme.fonts.passionOne,
    fontWeight: 400,
    letterSpacing: 0.5,
    position: 'absolute',
    bottom: -10,
    left: 30,
    zIndex: 10,
  },
  projectCard__links: {
    position: 'absolute',
    bottom: 5,
    right: 30,
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    justifyContent: 'space-around',
  },
});
