import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Placeholder from '../../components/Placeholder';
import { useLocation } from 'react-router-dom';

const ViewBlog = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Placeholder strTitleText="Coming Soon" />
    </Layout>
  );
};

export default ViewBlog;
