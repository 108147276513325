import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from '../styles';
import palette from '../../../theme/Palette';
import withTheme from '../../../hoc/withTheme';

const HexagonShape = ({ classes, bIsDark }) => {
  return (
    <svg className={classes.hexagon_svg} viewBox="0 0 351 414" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.8886 99.912C87.2259 86.5094 98.8605 76.6247 112.949 73.523L192.685 55.9688C208.487 52.4899 224.914 58.0692 235.328 70.4522L286.173 130.906C296.05 142.65 299.009 158.731 293.958 173.222L265.198 255.736C260.452 269.352 249.268 279.731 235.337 283.448L163.607 302.59C147.306 306.94 129.96 301.472 119.1 288.56L59.6124 217.829C49.298 205.566 46.5614 188.624 52.49 173.736L81.8886 99.912Z"
        stroke="#6C63FF"
        strokeWidth="5"
      />
      <g filter="url(#filter0_d_hexagon)">
        <path
          d="M76.6543 235.666C85.3727 222.976 99.8617 215.489 115.256 215.719L150.892 216.252C167.929 216.506 183.43 226.158 191.176 241.335L206.814 271.972C214.157 286.36 213.416 303.544 204.861 317.246L183.66 351.204C175.51 364.257 161.374 372.37 145.993 372.822L115.744 373.712C97.9773 374.235 81.4995 364.475 73.4193 348.644L54.1622 310.914C46.4914 295.885 47.6648 277.862 57.2196 263.954L76.6543 235.666Z"
          fill="url(#paint0_linear_hexagon)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_hexagon"
          x="21.8125"
          y="185.511"
          width="219.282"
          height="227.647"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_hexagon"
          x1="132.457"
          y1="245.343"
          x2="124.765"
          y2="435.255"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={bIsDark ? palette.darkPurple : '#5D2885'} />
          <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#791AC4'} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default withTheme(withStyles(styles)(HexagonShape));
