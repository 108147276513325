import React from 'react';
import palette from '../../../theme/Palette';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from '../styles';

const BackgroundShape = ({ bIsDark, classes }) => {
  return (
    <svg className={classes.nav__bgShape} viewBox="0 0 1000 275" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M760.78 257.332C687.885 205.446 249.196 217.051 121.398 147.461C-63.34 28.704 335.41 -62.9223 267.077 -171.565C204.79 -327.119 1116.78 -50.0841 998.562 18.1035C909.715 69.3525 844.707 304.675 760.78 257.332Z"
        fill="url(#background-gradient)"
      />
      <path
        d="M166 129.5C166 130.881 164.881 132 163.5 132C162.12 132 161 130.881 161 129.5C161 128.119 162.12 127 163.5 127C164.881 127 166 128.119 166 129.5Z"
        fill="white"
      />
      <path
        d="M282 120.5C282 118.567 280.433 117 278.5 117C276.567 117 275 118.567 275 120.5C275 122.433 276.567 124 278.5 124C280.433 124 282 122.433 282 120.5Z"
        fill="white"
      />
      <path
        d="M179.807 142C179.807 144.209 178.016 146 175.807 146C173.598 146 171.807 144.209 171.807 142C171.807 139.791 173.598 138 175.807 138C178.016 138 179.807 139.791 179.807 142Z"
        fill="white"
      />
      <path
        d="M119 69.5C119 70.8807 117.881 72 116.5 72C115.12 72 114 70.8807 114 69.5C114 68.1193 115.12 67 116.5 67C117.881 67 119 68.1193 119 69.5Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="background-gradient"
          x1="352.926"
          y1="126.253"
          x2="474.03"
          y2="497.993"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={bIsDark ? palette.darkPurple : '#3D115F'} />
          <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#8C15C4'} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default withStyles(styles)(BackgroundShape);
