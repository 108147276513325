const palette = {
  black: '#000000',
  coral: '#DD3471',
  green: '#8CEFCB',
  grey: '#292B34',
  greyDark: '#1F1D23',
  purple: '#8660F3',
  purpleFaded: 'rgba(116, 70, 241, 0.74)',
  placeholderGrey: '#949598',
  white: '#ffffff',
  yellow: '#F7C463',
  brightPurple: 'rgb(84, 81, 236)',
  darkPurple: '#2D2447',
  bluePurple: '#3751e5',
  mainPurple: '#b466e2',
  darkModePurpleBg: '#0D1225',
  darkModeSecondaryBg: '#462051',
  purpleRed: '#633379',
  greyPurple: '#ECEDFE',
};

export default palette;
