import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';
import { Parallax } from 'react-skrollr';

const TopLeftShape = ({ classes, bIsDark }) => {
  return (
    <Parallax
      data={{
        'data-0': 'transform:translateY(0px);',
        'data-365': 'transform:translateY(-40px);',
      }}>
      <svg className={classes.topLeft__shape} viewBox="0 0 384 464" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M38.1091 256.916C47.7113 137.599 99.3781 21.4424 182.329 18.0302C265.28 14.6179 355.738 124.259 359.947 227.884C364.157 331.508 132.136 536.923 134.49 412.071C151.368 302.248 42.3183 360.541 38.1091 256.916Z"
          fill="url(#paint0_linear_projects)"
        />
        <path
          d="M73.0879 277.172C72.2297 275.44 70.1299 274.731 68.3979 275.59C66.6658 276.448 65.9574 278.548 66.8155 280.28C67.6737 282.012 69.7735 282.72 71.5056 281.862C73.2376 281.004 73.946 278.904 73.0879 277.172Z"
          fill="white"
        />
        <path
          d="M253.364 140.979C252.506 139.247 250.406 138.539 248.674 139.397C246.942 140.255 246.234 142.355 247.092 144.087C247.95 145.819 250.05 146.528 251.782 145.67C253.514 144.811 254.222 142.712 253.364 140.979Z"
          fill="white"
        />
        <path
          d="M222.073 75.5721C221.583 74.5823 220.383 74.1775 219.393 74.6679C218.404 75.1583 217.999 76.3581 218.489 77.3479C218.98 78.3376 220.18 78.7425 221.169 78.2521C222.159 77.7617 222.564 76.5618 222.073 75.5721Z"
          fill="white"
        />
        <path
          d="M263.457 71.2504C262.599 69.5184 260.499 68.8099 258.767 69.6681C257.035 70.5263 256.326 72.6261 257.184 74.3581C258.043 76.0902 260.142 76.7986 261.874 75.9404C263.607 75.0823 264.315 72.9825 263.457 71.2504Z"
          fill="white"
        />
        <path
          d="M134.187 139.204C133.452 137.72 131.652 137.112 130.167 137.848C128.683 138.584 128.076 140.383 128.811 141.868C129.547 143.353 131.347 143.96 132.831 143.224C134.316 142.489 134.923 140.689 134.187 139.204Z"
          fill="white"
        />
        <path
          d="M109.626 190.992C108.768 189.26 106.668 188.552 104.936 189.41C103.204 190.268 102.495 192.368 103.354 194.1C104.212 195.832 106.312 196.54 108.044 195.682C109.776 194.824 110.484 192.724 109.626 190.992Z"
          fill="white"
        />
        <path
          d="M239.361 56.4046C238.503 54.6726 236.403 53.9641 234.671 54.8223C232.939 55.6805 232.231 57.7803 233.089 59.5123C233.947 61.2444 236.047 61.9528 237.779 61.0946C239.511 60.2365 240.219 58.1367 239.361 56.4046Z"
          fill="white"
        />
        <path
          d="M171.93 90.3721C168.62 83.6921 160.121 81.1568 152.945 84.7121C160.121 81.1568 163.254 72.8617 159.943 66.1788C163.254 72.8617 171.755 75.3927 178.931 71.8374C171.755 75.3927 168.62 83.6921 171.93 90.3721Z"
          fill="white"
        />
        <path
          d="M87.9464 187.783C85.4948 182.835 79.0951 181.009 73.6515 183.706C79.0951 181.009 81.52 174.813 79.0673 169.862C81.52 174.813 87.9209 176.636 93.3645 173.939C87.9209 176.636 85.4948 182.835 87.9464 187.783Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_projects"
            x1="294.482"
            y1="105.456"
            x2="89.2723"
            y2="449.655"
            gradientUnits="userSpaceOnUse">
            <stop stop-color={bIsDark ? palette.darkPurple : '#4B217E'} />
            <stop offset="0.979753" stop-color={bIsDark ? palette.darkPurple : '#6175D4'} />
          </linearGradient>
        </defs>
      </svg>
    </Parallax>
  );
};

export default withTheme(withStyles(styles)(TopLeftShape));
