import { TOGGLE_THEME, TOGGLE_LANGUAGE } from '../../actions/ActionChangePreferences';
import LANGUAGE from '../../../constants/language';
import THEME from '../../../constants/theme';

const isThemeInLSDark = () => {
  let strThemePreference = localStorage.getItem('theme');
  if (strThemePreference === THEME.DARK) return true;
  return false;
};

const objInitialState = {
  bIsDark: isThemeInLSDark(),
  intContentIndex: 1,
};

const preferencesReducer = (state = objInitialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        bIsDark: isThemeInLSDark(),
      };
    case TOGGLE_LANGUAGE:
      return {
        ...state,
        intContentIndex: action.payload === LANGUAGE.ENGLISH ? 1 : 0,
      };
    default:
      return state;
  }
};

export default preferencesReducer;
