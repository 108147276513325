import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchContent } from '../../redux/actions/ActionGetContent';

const withContent = (WrappedComponent) => {
  class HOC extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      funcFetchContent: () => dispatch(fetchContent()),
    };
  };

  const mapStateToProps = (state, ownProps) => {
    const { objContent, bLoading } = state.ContentReducer;
    const { intContentIndex } = state.PreferencesReducer;
    return {
      objContent,
      bLoading,
      intContentIndex,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withContent;
