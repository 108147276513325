export default (theme) => ({
  layout__container: {
    width: '100%',
    marginTop: -20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  layout__navigation: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  layout__bg: {
    backgroundColor: theme.bodyBackground,
    transition: 'background-color 0.4s',
  },
});
