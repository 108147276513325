export default (theme) => ({
  //logo
  header__logo: {
    position: 'absolute',
    top: 31,
    zIndex: 2,
  },
  header__container: {
    top: 0,
    position: 'relative',
    width: '100%',
  },
  //mobile
  logo__mobileShape: {
    position: 'absolute',
    top: 0,
    width: '60%',
    zIndex: 0,
  },
  '@media (max-width: 924px)': {
    logo__mobileShape: {
      width: '70%',
    },
  },
  '@media (max-width: 424px)': {
    logo__mobileShape: {
      width: '90%',
    },
  },
});
