import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withContent from '../../hoc/withContent';
import VisibilitySensor from 'react-visibility-sensor';
import posed from 'react-pose';

const Circle = posed.div({
  open: {
    height: 70,
    width: 70,
    transition: {
      scale: { ease: 'easeInOut', duration: 300 },
    },
  },
  closed: { scale: 1, height: 0, width: 0 },
});

const Navigation = React.memo(({ strTitleText, classes }) => {
  const [bIsVisible, setVisibility] = useState(false);
  const onChange = (bIsVisible) => {
    if (bIsVisible) {
      setVisibility(bIsVisible);
    }
  };
  return (
    <VisibilitySensor onChange={onChange}>
      <div className={classes.title__container}>
        <Circle pose={bIsVisible ? 'open' : 'closed'} className={classes.title__circle} />
        <h2 className={classes.title__text}>{strTitleText}</h2>
      </div>
    </VisibilitySensor>
  );
});

export default withContent(withStyles(styles)(Navigation));
