export default (theme) => ({
  githubActivity__mainSvg: {
    height: 540,
    width: 530,
    position: 'relative',
    zIndex: 0,
  },
  githubActivity__container: {
    position: 'relative',
  },
  '@media (max-width: 720px)': {
    githubActivity__container: {
      width: '100%',
    },
    githubActivity__mainSvg: {
      width: '80%',
      height: 'auto',
      margin: '20px 0',
    },
  },
});
