import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../hoc/withTheme';
import palette from '../../../theme/Palette';
import { Parallax } from 'react-skrollr';

const BackgroundAbstractShape = ({ classes, bIsDark, intRotation = 0, objParallaxOptions = {}, bDarkModePurple }) => {
  const { strStartScroll = '0', strEndScroll = '500', strTranslateY = '-80px' } = objParallaxOptions;

  const determineBgColor = () => {
    if (bDarkModePurple && bIsDark) return palette.darkPurple;
    if (bIsDark) return palette.purpleRed;
    return '#F3EEFE';
  };

  return (
    <Parallax
      data={{
        [`data-${strStartScroll}`]: 'transform:translateY(0px);',
        [`data-${strEndScroll}`]: `transform:translateY(${strTranslateY});`,
      }}>
      <svg
        style={{ transform: `rotate(${intRotation}deg)` }}
        className={classes.abstract__svg}
        viewBox="0 0 1479 1701"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M886.75 1588.54C533.697 1538.98 170.521 1295.3 122.376 912.079C74.2316 528.859 352.964 115.911 653.808 101.004C954.652 86.0963 1661.63 1166.56 1295.74 1150.23C1137.4 1225.17 1187.59 1573.63 886.75 1588.54Z"
          fill={`url(#paint0_linear_bg_abstract${determineBgColor()})`}
        />
        <defs>
          <linearGradient
            id={`paint0_linear_bg_abstract${determineBgColor()}`}
            x1="735.452"
            y1="132.989"
            x2="1057.75"
            y2="1697.91"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={determineBgColor()} />
            <stop offset="1" stopColor={determineBgColor()} />
          </linearGradient>
        </defs>
      </svg>
    </Parallax>
  );
};

export default withTheme(withStyles(styles)(BackgroundAbstractShape));
