import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import { useLocation } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';

const ViewContact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <ContactForm />
    </Layout>
  );
};

export default ViewContact;
