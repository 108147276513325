import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from '../styles';
import withTheme from '../../../hoc/withTheme';
import palette from '../../../theme/Palette';

const AbstractShapes = ({ classes, bIsDark }) => {
  return (
    <svg className={classes.abstract__shape} viewBox="0 0 285 233" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.5151 98.8871C62.8296 47.3891 105.524 0.860343 156.5 6.43036C207.475 12.0004 285.236 76.3725 277.861 122.166C287 258 76.0519 229.669 89.4719 175.126C84.6899 148.779 38.1398 144.681 45.5151 98.8871Z"
        fill={bIsDark ? palette.purpleRed : '#7428D1'}
      />
      <g filter="url(#filter0_d_abstract)">
        <path
          d="M27.7695 137.008C29.7454 129.915 35.1391 124.28 42.1395 121.996L64.2051 114.798C71.9525 112.27 80.4623 114.232 86.3208 119.897L102.587 135.625C108.141 140.995 110.471 148.884 108.726 156.41L102.966 181.252C101.304 188.422 96.1667 194.285 89.2783 196.875L70.2221 204.042C62.2654 207.034 53.2969 205.174 47.1858 199.265L27.642 180.369C21.8405 174.759 19.5758 166.423 21.7413 158.649L27.7695 137.008Z"
          fill={bIsDark ? palette.brightPurple : '#D4D4FF'}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_abstract"
          x="0.126953"
          y="97.0562"
          width="131.713"
          height="135.562"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default withTheme(withStyles(styles)(AbstractShapes));
