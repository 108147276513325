import { createMuiTheme } from '@material-ui/core/styles';
import palette from './Palette';
import './amends.css';

const theme = createMuiTheme({
  mediumFontSize: 17,
  palette: {
    brightPurple: palette.brightPurple,
    yellow: palette.yellow,
    white: palette.white,
    purple: palette.mainPurple,
    darkPurple: palette.darkPurple,
    redPurple: palette.purpleRed,
    darkModePurpleBg: palette.darkModePurpleBg,
    greyPurple: palette.greyPurple,
  },
  fonts: {
    montez: 'Montez, cursive',
    passionOne: 'Passion One, cursive',
    quickSand: 'Quicksand, sans-serif',
  },
  textBubble: {
    borderGradient: 'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.88) 0%, rgba(102, 134, 232, 0.03) 40%)',
    gradient: 'linear-gradient(180deg, #F6F6F6 0%, rgba(255, 255, 255, 0.88) 100%)',
  },
  skills: {
    hexagon: palette.darkPurple,
  },
  projects: {
    personalBackground: 'linear-gradient(309.05deg, #493FC6 4.29%, #7DA1FD 100.82%)',
    workBackground: 'linear-gradient(309.05deg, #7876F1 4.29%, #b162f9 100.82%)',
  },
  loaderText: palette.brightPurple,
  textColor: palette.black,
  textColorPlaceholderTitle: palette.purpleRed,
  bodyBackground: palette.white,
  fabLinearGradient: `linear-gradient(100deg, ${palette.mainPurple}, ${palette.bluePurple})`,
  footerLinearGradient: `linear-gradient(18deg, ${palette.darkModePurpleBg},  ${palette.purpleRed})`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1065,
      lg: 1540,
      xl: 1920,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: palette.purpleFaded,
      },
    },
    MuiDialog: {
      paper: {
        height: 700,
      },
    },
  },
});

export default theme;
