import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../hoc/withTheme';

const TextBubble = ({ classes, strText, intSize = 300, arrParagraphs }) => {
  return (
    <div className={classes.textBubble__background} style={{ width: intSize, height: intSize }}>
      <div className={classes.textBubble__container}>
        {strText ? (
          <p className={classes.textBubble__text}>{strText}</p>
        ) : (
          <div class>
            {arrParagraphs.map((objCopy) => (
              <p className={classes.textBubble__text}>{objCopy.text}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withTheme(withStyles(styles)(TextBubble));
