import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import { useLocation } from 'react-router-dom';
import { AboutMe, Skills } from './components';

const ViewAbout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <>
      <Layout>
        <AboutMe />
        <Skills />
      </Layout>
    </>
  );
};

export default ViewAbout;
