import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { withRouter } from 'react-router';
import { AboutPageShape, ContactPageShape, ProjectsPageShape } from './components';
import Logo from '../Logo';
import palette from '../../theme/Palette';
import withTheme from '../../hoc/withTheme';

const Header = ({ classes, location = '/', bIsTabletScreen, bIsDark }) => {
  const renderShape = () => {
    switch (location.pathname) {
      case '/':
        return <AboutPageShape />;
      case '/projects':
        return <ProjectsPageShape />;
      case '/contact':
        return <ContactPageShape />;
      default:
        break;
    }
  };
  return (
    <div className={classes.header__container}>
      <div className={classes.header__logo}>
        <Logo bIsTabletScreen={bIsTabletScreen} />
      </div>
      {bIsTabletScreen && (
        <svg
          className={classes.logo__mobileShape}
          viewBox="0 0 1249 988"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M570.734 265.91C394.373 168.936 87.9601 511.648 -134.628 491.102C-455.302 451.589 106.754 1.7076 -10.6791 -131.7C-116.471 -350.173 1416.92 -167.055 1208.09 41.1174C1051.14 197.577 725.902 360.781 570.734 265.91Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="512.512"
              y1="119.273"
              x2="740.053"
              y2="191.861"
              gradientUnits="userSpaceOnUse">
              <stop stopColor={bIsDark ? palette.darkPurple : '#3C135D'} />
              <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#53287D'} />
            </linearGradient>
          </defs>
        </svg>
      )}
      {renderShape()}
    </div>
  );
};

export default withTheme(withRouter(withStyles(styles)(Header)));
