export default (theme) => ({
  aboutMe__mainSvg: {
    height: 650,
    width: 800,
    position: 'relative',
    zIndex: 0,
  },
  aboutMe__container: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 900,
  },
  aboutMe__leftShape: {
    position: 'absolute',
    top: '77%',
    left: 0,
  },
  aboutMe__rightShape: {
    position: 'absolute',
    top: '10%',
    right: 0,
  },
  aboutMe__title: {
    position: 'absolute',
    left: 100,
    zIndex: 1,
  },
  aboutMe__textOne: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
  aboutMe__textTwo: {
    position: 'absolute',
    bottom: 20,
    right: 180,
    zIndex: 1,
  },
  aboutMe__backgroundShape: {
    position: 'absolute',
    left: 0,
  },

  //tablet
  '@media (max-width: 960px)': {
    aboutMe__backgroundShape: {
      display: 'none',
    },
    aboutMe__title: {
      left: 180,
    },
    aboutMe__leftShape: {
      display: 'none',
    },
    aboutMe__rightShape: {
      display: 'none',
    },
    aboutMe__container: {
      width: '100%',
    },
    aboutMe__mainSvg: {
      width: '120%',
    },
  },

  //mobile
  '@media (max-width: 800px)': {
    aboutMe__textTwo: {
      right: 'unset',
    },
    aboutMe__title: {
      left: 140,
      top: -120,
    },
    aboutMe__container: {
      marginTop: 100,
    },
  },
});
