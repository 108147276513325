export default (theme) => ({
  commercialProjects__container: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 270,
  },
  commercialProjects__title: {
    position: 'absolute',
    top: -30,
    left: 300,
    zIndex: 1,
    '& div': {
      width: 305,
    },
  },
  commercialProjects__grid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 650,
    position: 'relative',
    zIndex: 1,
    marginTop: -50,
    justifyContent: 'space-between',
  },
  commercialProjects__svg: {
    position: 'absolute',
    zIndex: -1,
    left: -112,
    height: 790,
    width: 750,
  },
  commercialProjects__leftShape: {
    position: 'absolute',
    zIndex: -2,
    left: '-50%',
    bottom: 0,
  },
  commercialProjects__rightShape: {
    position: 'absolute',
    zIndex: -2,
    right: '-65%',
    transform: 'scale(0.7)',
  },
  connexLogo: {
    position: 'absolute',
    bottom: 16,
    zIndex: 11,
    width: 100,
    right: 30,
  },
  edLogo: {
    position: 'absolute',
    bottom: 10,
    zIndex: 11,
    width: 140,
    right: 30,
  },
  gceLogo: {
    position: 'absolute',
    bottom: 18,
    zIndex: 11,
    width: 85,
    right: 30,
  },
  maybernLogo: {
    position: 'absolute',
    bottom: 19,
    zIndex: 11,
    width: 110,
    right: 30,
  },
  newtonLogo: {
    position: 'absolute',
    bottom: 25,
    zIndex: 11,
    width: 110,
    right: 30,
  },
  lucionLogo: {
    position: 'absolute',
    bottom: 20,
    zIndex: 11,
    width: 140,
    right: 30,
  },
  davantiLogo: {
    position: 'absolute',
    bottom: 20,
    zIndex: 11,
    width: 130,
    right: 30,
  },
  renfrewLogo: {
    position: 'absolute',
    bottom: 14,
    zIndex: 11,
    width: 50,
    right: 30,
  },
  //tablet
  '@media (max-width: 1100px)': {
    ercialProjects__rightShape: {
      display: 'none',
    },
    commercialProjects__leftShape: {
      display: 'none',
    },
    commercialProjects__title: {
      top: -50,
    },
  },

  //mobile
  '@media (max-width: 720px)': {
    commercialProjects__title: {
      top: -150,
      left: 'unset',
    },
    commercialProjects__codeEditor: {
      right: 'unset',
      width: '100%',
    },
    commercialProjects__svg: {
      width: '100%',
      left: 'unset',
    },
    commercialProjects__grid: {
      width: '100%',
      padding: 20,
      boxSizing: 'border-box',
    },
  },
  '@media (max-width: 690px)': {
    commercialProjects__grid: {
      justifyContent: 'center',
    },
    commercialProjects__container: {
      marginTop: 140,
    },
    commercialProjects__title: {
      top: -120,
    },
  },
});
