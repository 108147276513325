import React from 'react';
import Toggle from 'react-toggle';
import SunImg from '../../assets/icons/sun.svg';
import MoonImg from '../../assets/icons/night.svg';
import UkFlag from '../../assets/icons/uk_flag.svg';
import RoFlag from '../../assets/icons/romanian_flag.svg';
import withTheme from '../../hoc/withTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import 'react-toggle/style.css';
import LANGUAGE from '../../constants/language';
import withContent from '../../hoc/withContent';
const FaButton = ({ funcToggleTheme, funcToggleLanguage, classes, bIsDark, intContentIndex }) => {
  //TODO: This toggle for language might trigger an extra render, since users might click EN even though its the default one, or click the language they already have, make sure to use React memo on this component

  const determineActiveLanguage = (intLanguageIndex) => {
    return intContentIndex === intLanguageIndex;
  };
  return (
    <div className={classes.fab__container}>
      <div>
        <img
          onClick={() => funcToggleLanguage(LANGUAGE.ENGLISH)}
          className={classes.fab__flag}
          src={UkFlag}
          alt="United Kingdom flag"
          style={{ opacity: determineActiveLanguage(1) ? 1 : 0.7 }}
        />
        <img
          onClick={() => funcToggleLanguage(LANGUAGE.ROMANIAN)}
          style={{ opacity: determineActiveLanguage(0) ? 1 : 0.7 }}
          className={classes.fab__flag}
          src={RoFlag}
          alt="Romanian flag"
        />
      </div>
      <label>
        <Toggle
          checked={bIsDark}
          icons={{
            checked: <img className={classes.fab__sun} src={SunImg} alt="sun" />,
            unchecked: <img className={classes.fab__moon} src={MoonImg} alt="moon" />,
          }}
          onChange={() => funcToggleTheme()}
        />
      </label>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(FaButton)));
