import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../../hoc/withTheme';
import withContent from '../../../../../hoc/withContent';
import palette from '../../../../../theme/Palette';
import Typed from 'react-typed';

const CodeEditor = ({ classes, bIsDark }) => {
  return (
    <div className={classes.codeEditor__container}>
      <Typed
        loop
        className={classes.codeEditor__typed}
        strings={[
          `function <span class="fn-name-purple">createAwesomeProject</span>(){</br> 
              &nbsp;&nbsp; <span class="fn-name-yellow">createLayout</span>(); 💪 </br>
              &nbsp;&nbsp; <span class="fn-name-yellow">addCSSGoodness</span>(); 🖌 </br> 
              &nbsp;&nbsp; <span class="fn-name-yellow">addJavascriptMagic</span>(); 🧙‍♂️ </br>
              &nbsp;&nbsp; <span class="fn-name-blue"">createDatabaseSchema</span>(); 📝</br>
              &nbsp;&nbsp; <span class="fn-name-blue"">createBackendApi</span>(); 🔧 </br>
              &nbsp;&nbsp; <span class="fn-name-yellow">connectToBackend</span>(); 🔌 </br>
              &nbsp;&nbsp; <span class="fn-name-yellow">qaTesting</span>(); 👍</br> 
              &nbsp;&nbsp; <span class="fn-name-yellow">deployProject</span>() 🏁 </br>
              }  </br>  
              <span class="fn-name-purple">createAwesomeProject</span>(); </br>         
              `,
        ]}
        typeSpeed={25}></Typed>
      <svg
        className={classes.codeEditor__mainSvg}
        viewBox="0 0 1314 1237"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M627.866 941.116C332.736 827.608 77.0555 609.887 130.409 397.17C183.762 184.453 529.162 37.2119 794.854 103.998C1060.55 170.783 1407.58 912.168 1093.01 812.888C936.483 812.888 893.558 1007.9 627.866 941.116Z"
          fill="url(#paint0_linear_code_editor)"
        />
        <path
          className={classes.codeEditor__window}
          d="M833.526 248.674H172.467V768.288H833.526V248.674Z"
          fill="url(#paint1_linear_code_editor)"
        />
        <path
          d="M190.049 269.811C194.418 269.811 197.96 266.262 197.96 261.884C197.96 257.507 194.418 253.958 190.049 253.958C185.679 253.958 182.137 257.507 182.137 261.884C182.137 266.262 185.679 269.811 190.049 269.811Z"
          fill="#E44400"
        />
        <path
          d="M211.146 269.811C215.516 269.811 219.058 266.262 219.058 261.884C219.058 257.507 215.516 253.958 211.146 253.958C206.777 253.958 203.235 257.507 203.235 261.884C203.235 266.262 206.777 269.811 211.146 269.811Z"
          fill="#E4CD00"
        />
        <path
          d="M232.244 269.811C236.613 269.811 240.156 266.262 240.156 261.884C240.156 257.507 236.613 253.958 232.244 253.958C227.874 253.958 224.332 257.507 224.332 261.884C224.332 266.262 227.874 269.811 232.244 269.811Z"
          fill="#279316"
        />
        <path opacity="0.1" d="M833.526 275.095H172.467V277.737H833.526V275.095Z" fill="black" />
        <path
          d="M1054.88 1077.57L1079.62 1019.66C1085.52 1005.86 1098.1 996.081 1112.92 993.77L1163.46 985.887C1179.86 983.329 1196.28 990.323 1205.8 1003.92L1247.02 1062.79C1256.04 1075.68 1257.4 1092.43 1250.58 1106.6L1226.31 1157.05C1219.81 1170.58 1206.78 1179.8 1191.86 1181.44L1134.52 1187.74C1118.73 1189.47 1103.25 1182.46 1094.14 1169.45L1059.24 1119.61C1050.62 1107.3 1048.97 1091.4 1054.88 1077.57Z"
          stroke="#797BEC"
          stroke-width="5"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M1172.72 897.413C1184.52 888.257 1200.02 885.394 1214.3 889.734L1254.66 901.994C1271.19 907.015 1283.51 920.874 1286.57 937.877L1293.12 974.384C1296.02 990.528 1290.11 1007 1277.6 1017.6L1242.06 1047.73C1230.67 1057.38 1215.32 1060.91 1200.86 1057.2L1165.37 1048.08C1147.94 1043.6 1134.72 1029.37 1131.54 1011.65L1123.49 966.849C1120.47 950.006 1127.04 932.876 1140.56 922.382L1172.72 897.413Z"
            fill="url(#paint2_linear_code_editor)"
          />
        </g>
        <path
          d="M1069.19 570.018C1070.01 568.599 1068.95 566.453 1066.82 565.225C1064.69 563.996 1062.3 564.15 1061.48 565.569C1060.66 566.987 1061.73 569.133 1063.85 570.362C1065.98 571.591 1068.37 571.437 1069.19 570.018Z"
          fill="#3F3D56"
        />
        <path
          d="M1044.89 599.237C1044.89 599.237 1044.15 628.899 1038.22 632.606C1032.29 636.314 1048.6 647.437 1048.6 647.437L1073.07 642.988L1088.64 623.708C1088.64 623.708 1077.52 610.36 1081.23 599.237H1044.89Z"
          fill="#FFB6B6"
        />
        <path
          opacity="0.25"
          d="M1044.89 599.237C1044.89 599.237 1044.15 628.899 1038.22 632.606C1032.29 636.314 1048.6 647.437 1048.6 647.437L1073.07 642.988L1088.64 623.708C1088.64 623.708 1077.52 610.36 1081.23 599.237H1044.89Z"
          fill="black"
        />
        <path
          d="M1039 623C1039 623 1003.05 628.488 1000.94 647.304C998.825 666.12 1000.94 721 1000.94 721L1027.31 715L1039 623Z"
          fill="url(#paint3_linear_code_editor)"
        />
        <path
          d="M990.759 753.477C990.759 753.477 905.482 759.41 919.572 787.588C933.661 815.767 1001.88 863.225 1001.88 863.225C1001.88 863.225 1014.49 866.191 1016.71 866.933C1018.94 867.674 1053.05 844.687 1053.05 844.687C1053.05 844.687 1032.29 832.081 1022.65 829.856C1013.01 827.631 985.569 803.902 985.569 803.902L1062.69 784.622L1017.45 746.062L990.759 753.477Z"
          fill="#2F2E41"
        />
        <path
          d="M1058.98 741.5C1058.98 741.5 1010.04 841.248 1019.68 868.55C1029.32 895.853 1022.65 986.32 1022.65 986.32C1022.65 986.32 1058.98 998.185 1058.98 986.32C1058.98 974.456 1069.36 890.662 1058.98 862.483C1058.98 862.483 1061.21 844.686 1067.14 841.72C1073.07 838.754 1093.83 811.317 1093.83 811.317C1093.83 811.317 1109.97 764.14 1107 754.5C1104.03 744.86 1058.98 741.5 1058.98 741.5Z"
          fill="#2F2E41"
        />
        <path
          d="M1093.09 583.665C1093.09 589.531 1091.35 595.266 1088.09 600.144C1084.83 605.022 1080.2 608.824 1074.78 611.069C1069.36 613.314 1063.4 613.901 1057.64 612.756C1051.89 611.612 1046.6 608.787 1042.46 604.639C1038.31 600.49 1035.48 595.205 1034.34 589.452C1033.19 583.698 1033.78 577.734 1036.03 572.314C1038.27 566.894 1042.07 562.261 1046.95 559.002C1051.83 555.743 1057.56 554.003 1063.43 554.003C1067.33 554.002 1071.18 554.768 1074.78 556.258C1078.38 557.748 1081.65 559.932 1084.41 562.687C1087.16 565.442 1089.35 568.712 1090.84 572.312C1092.33 575.911 1093.09 579.769 1093.09 583.665V583.665Z"
          fill="#C08B93"
        />
        <path
          d="M1082.71 613.327C1082.71 613.327 1127.2 631.865 1120.53 655.594C1113.85 679.323 1099.77 781.656 1078.26 781.656C1056.76 781.656 1011.52 769.791 1008.56 765.342C1005.59 760.893 1019.68 670.425 1019.68 670.425C1019.68 670.425 1036.72 615.266 1043.03 619.858C1043.03 619.858 1038.96 643.73 1060.46 637.797C1081.97 631.865 1082.71 613.327 1082.71 613.327Z"
          fill="url(#paint4_linear_code_editor)"
        />
        <path
          d="M1002.62 766.825V775.724C1002.62 775.724 972.962 803.16 984.827 810.576C996.691 817.991 1019.68 783.88 1019.68 783.88L1022.47 764.06L1002.62 766.825Z"
          fill="#FFB6B6"
        />
        <path
          d="M1002.62 766.825V775.724C1002.62 775.724 972.962 803.16 984.827 810.576C996.691 817.991 1019.68 783.88 1019.68 783.88L1022.47 764.06L1002.62 766.825Z"
          fill="#BF8888"
        />
        <path
          d="M1031 866.933L1022.65 872.865C1022.65 872.865 994.467 915.874 1007.07 920.323C1019.68 924.773 1074.55 871.382 1073.81 866.933C1073.07 862.483 1053 850 1053 850L1031 866.933Z"
          fill="#2F2E41"
        />
        <path
          d="M1024.87 980.388L1018.2 1010.05C1018.2 1010.05 992.242 1044.16 1004.11 1047.13C1015.97 1050.09 1042.67 1046.39 1046.37 1038.23C1050.08 1030.07 1054.53 1018.95 1060.46 1010.05C1066.4 1001.15 1055.27 980.388 1055.27 980.388H1024.87Z"
          fill="#2F2E41"
        />
        <path
          d="M1003.37 707.502L998.174 720.85L1000.4 772.016C1000.4 772.016 1018.94 764.6 1024.87 772.016L1019.68 720.85L1011.52 707.502H1003.37Z"
          fill="url(#paint5_linear_code_editor)"
        />
        <path
          d="M1028.78 769L1014.69 773.449C1014.69 773.449 959.815 791.246 984.286 803.852C1008.76 816.458 1022.85 795.695 1022.85 795.695L1032.49 788.28L1028.78 769Z"
          fill="#BF8888"
        />
        <path
          d="M1113.85 643.73C1113.85 643.73 1093.83 653.37 1090.87 679.323C1087.9 705.277 1084.19 739.388 1084.19 739.388L1047.12 763.859C1047.12 763.859 1021.76 762.853 1025.54 772.254C1029.32 781.656 1030.06 801.677 1036.73 798.711C1043.41 795.745 1053.05 777.948 1062.69 777.207C1072.33 776.465 1112.37 760.893 1116.82 743.096C1121.27 725.299 1138.33 665.234 1130.91 656.336C1123.49 647.437 1119.79 640.022 1113.85 643.73Z"
          fill="url(#paint6_linear_code_editor)"
        />
        <path
          d="M1115.71 581.771C1117.27 584.974 1116.24 588.838 1113.3 590.842L1113.17 590.927C1113.17 590.927 1105.56 595.885 1096.86 588.436C1088.16 580.988 1075.4 571.481 1069.49 571.811L1084.59 570.854L1098.67 573.175L1113.72 579.853C1114.59 580.239 1115.29 580.918 1115.71 581.771V581.771Z"
          fill="#3F3D56"
        />
        <path
          d="M1097 588.5C1093.2 584.973 1089.7 583.245 1085.47 580.31C1077.31 574.589 1065.98 568.215 1054.63 566.912C1054.63 566.912 1044.42 564.442 1037 569L1048.7 549.703C1048.7 549.703 1056.3 543.739 1060.75 544.449C1060.75 544.449 1075.62 545.915 1080.19 549.237C1080.32 549.329 1080.44 549.424 1080.55 549.519C1083.09 549.584 1085.54 550.604 1087.46 552.394C1091.74 556.416 1098.7 564.481 1098.66 574.41C1098.6 588.751 1097 588.5 1097 588.5Z"
          fill="#3F3D56"
        />
        <path
          d="M1088.81 587.704C1090.02 587.527 1090.77 585.74 1090.47 583.714C1090.18 581.688 1088.95 580.189 1087.74 580.367C1086.52 580.544 1085.77 582.331 1086.07 584.357C1086.37 586.383 1087.59 587.882 1088.81 587.704Z"
          fill="#C08B93"
        />
        <defs>
          <filter
            id="filter0_d"
            x="1108.02"
            y="873.702"
            width="201.363"
            height="210.238"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_code_editor"
            x1="426"
            y1="558"
            x2="717.464"
            y2="1032.06"
            gradientUnits="userSpaceOnUse">
            <stop stop-color={bIsDark ? palette.darkPurple : '#6789EA'} />
            <stop offset="1" stop-color={bIsDark ? palette.darkPurple : '#A25BF1'} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_code_editor"
            x1="502.997"
            y1="248.674"
            x2="502.997"
            y2="768.288"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#0F0B39" />
            <stop offset="1" stop-color={bIsDark ? palette.darkModePurpleBg : '#3F3D58'} stop-opacity="0.97" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_code_editor"
            x1="1210.2"
            y1="920.649"
            x2="1214.14"
            y2="1054.85"
            gradientUnits="userSpaceOnUse">
            <stop stop-color={bIsDark ? palette.darkPurple : '#9E5EF0'} />
            <stop offset="1" stop-color={bIsDark ? palette.darkPurple : '#D874F4'} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_code_editor"
            x1="1019.5"
            y1="623"
            x2="1019.5"
            y2="721"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#6762BD" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_code_editor"
            x1="1064.68"
            y1="613.327"
            x2="1064.68"
            y2="781.656"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#5D58AA" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_code_editor"
            x1="1011.52"
            y1="707.502"
            x2="1011.52"
            y2="772.016"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6762BD" />
            <stop offset="0.0001" stop-color="#6762BD" />
            <stop offset="1" stop-color="#4941CB" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_code_editor"
            x1="1078.95"
            y1="642.752"
            x2="1078.95"
            y2="799.008"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#6C63FF" />
            <stop offset="1" stop-color="#443DAA" stop-opacity="0.94" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(CodeEditor)));
