import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';
import withContent from '../../../../hoc/withContent';
import CtaButton from '../../../CtaButton';
import posed from 'react-pose';
import TopLeftShape from './TopLeftShape';
import createFloatingParallaxOptions from '../../../../util/createFloatingParallaxOptions';
import { BackgroundAbstractShape } from '../../../../components/common';
import TextBubble from '../../../../components/TextBubble';

const Container = posed.div({
  enter: { staggerChildren: 50 },
});

const Title = posed.h1({
  open: { y: 0, opacity: 1 },
  closed: { y: 20, opacity: 0 },
});

const Widget = posed.path({
  open: { x: 0, opacity: 1, delay: 500 },
  closed: { x: 50, opacity: 0, delay: 500 },
});

const Circle = posed.path({
  open: { y: 0, opacity: 1, delay: 500 },
  closed: { y: 50, opacity: 0, delay: 500 },
});

const BrowserWindow = posed.rect({
  open: {
    height: 311,
    opacity: 1,
    transition: {
      height: { ease: 'easeOut', duration: 150 },
    },
  },
  closed: { height: 0, opacity: 0 },
});

const ProjectsPageShape = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const [bIsOpen, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  const {
    title_projectspage: arrProjectsTitle = [],
    speech_bubble_projectspage_one: arrTextBubble = [],
    cta_text_projects: strCta,
  } = objContent[intContentIndex].data;

  const strFormattedAboutTitle = arrProjectsTitle.map((objContent) => objContent.text).slice(0, 1);

  return (
    <Container className={classes.shape__containerProjects}>
      <Title pose={bIsOpen ? 'open' : 'closed'} className={classes.header__titleProjects}>
        {strFormattedAboutTitle}
        <br />
      </Title>
      <TopLeftShape />

      <div className={classes.header__ctaProjects}>
        <CtaButton strButtonText={strCta[0].text} strLink={'/contact'} />
      </div>

      <div className={classes.header__text}>
        <TextBubble arrParagraphs={arrTextBubble} intSize={350} />
      </div>

      <div className={classes.header__shapeBg}>
        <BackgroundAbstractShape
          intRotation="0"
          objParallaxOptions={createFloatingParallaxOptions('70', '540', '-80px')}
        />
      </div>

      <svg
        className={classes.projects__mainSvg}
        width="1978"
        height="1745"
        viewBox="0 0 1978 1745"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M963 73.5C963 74.8807 961.881 76 960.5 76C959.119 76 958 74.8807 958 73.5C958 72.1193 959.119 71 960.5 71C961.881 71 963 72.1193 963 73.5Z"
          fill="white"
        />
        <path
          d="M704 372.5C704 370.567 702.433 369 700.5 369C698.567 369 697 370.567 697 372.5C697 374.433 698.567 376 700.5 376C702.433 376 704 374.433 704 372.5Z"
          fill="white"
        />
        <path
          d="M734 363C734 361.895 733.105 361 732 361C730.895 361 730 361.895 730 363C730 364.105 730.895 365 732 365C733.105 365 734 364.105 734 363Z"
          fill="white"
        />
        <path
          d="M814 82.5C814 80.567 812.433 79 810.5 79C808.567 79 807 80.567 807 82.5C807 84.433 808.567 86 810.5 86C812.433 86 814 84.433 814 82.5Z"
          fill="white"
        />
        <path
          d="M1079 64.5C1079 62.567 1077.43 61 1075.5 61C1073.57 61 1072 62.567 1072 64.5C1072 66.433 1073.57 68 1075.5 68C1077.43 68 1079 66.433 1079 64.5Z"
          fill="white"
        />
        <path
          d="M976.807 86C976.807 88.2091 975.016 90 972.807 90C970.598 90 968.807 88.2091 968.807 86C968.807 83.7909 970.598 82 972.807 82C975.016 82 976.807 83.7909 976.807 86Z"
          fill="white"
        />
        <path
          d="M667.499 274C667.499 270.687 664.59 268 661 268C664.59 268 667.499 265.315 667.499 262C667.499 265.315 670.41 268 674 268C670.41 268 667.499 270.687 667.499 274Z"
          fill="white"
        />
        <path
          d="M764.499 137C764.499 132.858 761.142 129.5 757 129.5C761.142 129.5 764.499 126.143 764.499 122C764.499 126.143 767.858 129.5 772 129.5C767.858 129.5 764.499 132.858 764.499 137Z"
          fill="white"
        />
        <path
          d="M875 258C875 256.895 874.105 256 873 256C871.895 256 871 256.895 871 258C871 259.105 871.895 260 873 260C874.105 260 875 259.105 875 258Z"
          fill="white"
        />
        <path
          d="M611 304C611 306.209 609.209 308 607 308C604.791 308 603 306.209 603 304C603 301.791 604.791 300 607 300C609.209 300 611 301.791 611 304Z"
          fill="white"
        />
        <path
          d="M1355 12.5C1355 13.8807 1353.88 15 1352.5 15C1351.12 15 1350 13.8807 1350 12.5C1350 11.1193 1351.12 10 1352.5 10C1353.88 10 1355 11.1193 1355 12.5Z"
          fill="white"
        />
        <path
          d="M1471 3.5C1471 1.567 1469.43 0 1467.5 0C1465.57 0 1464 1.567 1464 3.5C1464 5.433 1465.57 7 1467.5 7C1469.43 7 1471 5.433 1471 3.5Z"
          fill="white"
        />
        <path
          d="M1368.81 25C1368.81 27.2091 1367.02 29 1364.81 29C1362.6 29 1360.81 27.2091 1360.81 25C1360.81 22.7909 1362.6 21 1364.81 21C1367.02 21 1368.81 22.7909 1368.81 25Z"
          fill="white"
        />
        <path
          d="M616 264C616 267.314 613.314 270 610 270C606.686 270 604 267.314 604 264C604 260.686 606.686 258 610 258C613.314 258 616 260.686 616 264Z"
          fill="white"
        />
        <path
          d="M1857.01 611.751C1857.01 814.696 1533.48 956.795 1168.91 1071.63C630.904 1230.3 1083.96 454.129 733.551 431.163C291.113 343.472 1001.56 -149.09 1121.9 53.4329C1157.3 205.847 1833 118.55 1857.01 611.751Z"
          fill="url(#paint2_linear)"
        />
        <rect x="825.55" y="461.332" width="499.272" height="19.1799" fill="#2B2626" />
        <BrowserWindow
          pose={bIsOpen ? 'open' : 'closed'}
          x="982.632"
          y="482.814"
          width="341.452"
          height="313.016"
          fill="#222527"
          fillOpacity="0.99"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M636.752 923.665H663.97L731.044 896.418C731.044 896.418 778.677 876.235 774.789 914.583C770.9 952.93 764.096 1004.4 764.096 1004.4C764.096 1004.4 742.71 994.305 731.044 997.333C719.379 1000.36 727.156 935.775 727.156 935.775C727.156 935.775 631.891 983.205 623.142 977.15C614.393 971.095 612.449 928.711 612.449 928.711L636.752 923.665Z"
            fill="#2F2E41"
          />
          <path
            opacity="0.1"
            d="M636.752 923.665H663.97L731.044 896.418C731.044 896.418 778.677 876.235 774.789 914.583C770.9 952.93 764.096 1004.4 764.096 1004.4C764.096 1004.4 742.71 994.305 731.044 997.333C719.379 1000.36 727.156 935.775 727.156 935.775C727.156 935.775 631.891 983.205 623.142 977.15C614.393 971.095 612.449 928.711 612.449 928.711L636.752 923.665Z"
            fill="black"
          />
          <path
            d="M679.635 835.177L712.575 884.308L789.37 950.912C789.37 950.912 860.333 968.067 850.612 980.177C840.891 992.287 781.593 968.067 781.593 968.067C781.593 968.067 693.133 901.464 690.217 896.418C687.3 891.372 655.221 843.943 655.221 843.943L679.635 835.177Z"
            fill="#A0616A"
          />
          <path
            opacity="0.25"
            d="M679.635 835.177L712.575 884.308L789.37 950.912C789.37 950.912 860.333 968.067 850.612 980.177C840.891 992.287 781.593 968.067 781.593 968.067C781.593 968.067 693.133 901.464 690.217 896.418C687.3 891.372 655.221 843.943 655.221 843.943L679.635 835.177Z"
            fill="#F8F8F8"
            fillOpacity="0.94"
          />
          <path
            d="M660.082 795.504L681.468 838.897C681.468 838.897 676.607 860.089 668.831 862.107C661.054 864.126 628.003 835.87 628.003 835.87L660.082 795.504Z"
            fill="#D0CDE1"
          />
          <path
            opacity="0.1"
            d="M660.082 795.504L681.468 838.897C681.468 838.897 676.607 860.089 668.831 862.107C661.054 864.126 628.003 835.87 628.003 835.87L660.082 795.504Z"
            fill="#411D5D"
          />
          <path
            d="M920.982 901.198L927.299 928.25C927.37 928.551 927.359 928.867 927.268 929.163L898.611 1022.13C898.498 1022.5 898.266 1022.81 897.955 1023.02C897.644 1023.24 897.272 1023.33 896.901 1023.29C896.531 1023.26 896.184 1023.09 895.919 1022.82C895.654 1022.54 895.486 1022.19 895.444 1021.8L891.48 985.588C891.453 985.347 891.477 985.104 891.549 984.874L917.853 901.072C917.963 900.723 918.179 900.42 918.47 900.21C918.76 899.999 919.109 899.893 919.463 899.907C919.817 899.922 920.157 900.055 920.431 900.288C920.705 900.521 920.899 900.84 920.982 901.198Z"
            fill="#2F2E41"
          />
          <path d="M918.659 903.482L924.491 926.693L899.216 1011.46L893.384 985.223L918.659 903.482Z" fill="#F2F2F2" />
          <path
            d="M791.314 1029.63C792.286 1032.65 892.412 1028.62 893.384 1027.61C894.231 1026.34 894.948 1024.99 895.522 1023.57C896.436 1021.55 897.272 1019.53 897.272 1019.53L893.384 986.858L794.23 983.205C794.23 983.205 791.751 1010.9 791.246 1023.57C791.12 1026.77 791.12 1029.02 791.314 1029.63Z"
            fill="#2F2E41"
          />
          <path opacity="0.1" d="M880.747 991.278L882.69 1019.53H838.947V991.278H880.747Z" fill="black" />
          <path
            opacity="0.1"
            d="M824.366 1000.36L824.626 1000.31L823.393 1012.47H799.091V1000.36H824.366Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M791.314 1029.63C792.286 1032.65 892.412 1028.62 893.384 1027.61C894.231 1026.34 894.948 1024.99 895.522 1023.57H791.246C791.12 1026.77 791.12 1029.02 791.314 1029.63Z"
            fill="black"
          />
          <path
            d="M674.663 781.376C698.823 781.376 718.407 761.044 718.407 735.964C718.407 710.884 698.823 690.553 674.663 690.553C650.504 690.553 630.919 710.884 630.919 735.964C630.919 761.044 650.504 781.376 674.663 781.376Z"
            fill="#C08B93"
          />
          <path
            d="M671.747 776.33C671.747 776.33 657.166 802.568 654.249 816.696C651.333 830.824 610.505 788.44 610.505 788.44L605.159 773.807C605.159 773.807 643.556 755.138 640.64 740.001C637.724 724.864 671.747 776.33 671.747 776.33Z"
            fill="#C08B93"
          />
          <path
            d="M679.524 855.043L719.379 909.537L814.644 983.205C814.644 983.205 883.663 998.342 868.109 1009.44C852.556 1020.54 807.84 999.351 807.84 999.351C807.84 999.351 711.603 945.866 691.189 923.665C670.775 901.464 632.863 864.126 632.863 864.126L679.524 855.043Z"
            fill="#C08B93"
          />
          <path
            d="M626.059 939.811L637.724 958.985L707.342 936.248C722.618 931.259 739.368 933.095 752.917 941.956C765.068 949.903 772.844 962.517 755.347 981.186C720.351 1018.52 697.021 998.342 697.021 998.342C697.021 998.342 579.398 1055.86 559.956 1027.61C540.515 999.351 539.542 985.223 539.542 985.223C539.542 985.223 614.394 936.784 626.059 939.811Z"
            fill="#2F2E41"
          />
          <path
            d="M771.872 1006.41C771.872 1006.41 802.979 1027.61 766.04 1037.7C729.1 1047.79 701.882 1033.66 701.882 1033.66C701.882 1033.66 669.803 1033.66 669.803 1016.51C669.803 999.351 680.496 997.333 680.496 997.333L713.547 1002.38C713.547 1002.38 753.403 991.278 771.872 1006.41Z"
            fill="#D0CDE1"
          />
          <path
            d="M710.549 724.541C713.526 725.919 716.478 727.55 719.715 727.949C722.952 728.349 726.638 727.176 728.17 724.189C728.997 722.576 729.092 720.678 729.13 718.85C729.247 713.309 728.861 707.318 725.479 703.03C723.326 700.301 720.183 698.58 717.955 695.917C716.358 694.009 715.296 691.697 714.152 689.466C709.818 681.016 703.401 672.895 694.483 670.459C690.759 669.442 686.853 669.491 683.003 669.548L660.545 669.877C656.911 669.93 653.221 669.992 649.742 671.084C642.513 673.353 637.25 679.663 632.43 685.698C628.824 690.213 625.208 694.774 622.501 699.923C618.621 707.436 616.659 715.855 616.802 724.379C616.772 726.565 617.044 728.744 617.609 730.85C618.271 732.781 619.091 734.65 620.059 736.436C624.271 745.024 626.772 755.75 622.029 764.034C630.268 760.54 638.42 755.959 643.855 748.643C646.286 745.37 648.193 741.538 651.433 739.136C654.673 736.734 659.953 736.526 662.032 740.051C662.746 741.477 663.114 743.063 663.104 744.67C663.255 747.316 663.251 750.064 664.332 752.465C665.413 754.867 667.987 756.804 670.444 756.086C674.677 754.848 674.271 748.105 677.531 745.042C680.007 742.715 683.826 743.16 687.007 742.104C690.713 740.873 693.493 737.541 695.078 733.852C696.282 731.048 696.264 723.71 698.543 722.192C701.401 720.288 707.9 723.315 710.549 724.541Z"
            fill="#2F2E41"
          />
          <path
            d="M601.686 769.02C603.654 768.475 605.726 768.481 607.691 769.036C613.931 770.775 627.92 775.379 630.919 782.385C634.807 791.467 648.417 805.595 648.417 805.595C648.417 805.595 666.886 824.769 662.998 837.888C659.11 851.007 644.528 866.144 644.528 866.144C644.528 866.144 648.417 928.711 628.975 944.857C609.533 961.003 601.756 949.903 601.756 965.04C601.756 980.177 541.487 1027.61 529.822 998.342C529.822 998.342 535.654 943.848 533.71 924.674C531.794 905.777 534.599 787.875 601.686 769.02Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M632.863 794.495C632.863 794.495 692.161 839.906 681.468 859.08C681.468 859.08 645.501 877.245 637.724 876.235C629.947 875.226 599.812 839.906 592.035 836.879C584.259 833.851 580.37 779.358 632.863 794.495Z"
            fill="#6C63FF"
          />
          <Circle
            pose={bIsOpen ? 'open' : 'closed'}
            d="M897.793 694.074C924.315 694.074 945.815 671.754 945.815 644.221C945.815 616.689 924.315 594.369 897.793 594.369C871.271 594.369 849.771 616.689 849.771 644.221C849.771 671.754 871.271 694.074 897.793 694.074Z"
            fill="#DD68E7"
          />
          <path
            d="M1294.43 579.476H1025.32V521.019H1294.43V579.476ZM1026.51 578.246H1293.25V522.249H1026.51V578.246Z"
            fill="#9A98AE"
          />
          <Widget
            pose={bIsOpen ? 'open' : 'closed'}
            d="M1274.87 532.71H1006.95V589.937H1274.87V532.71Z"
            fill="url(#paint4_linear)"
          />
          <path
            d="M838.358 476.85C840.65 476.85 842.507 474.922 842.507 472.543C842.507 470.164 840.65 468.235 838.358 468.235C836.066 468.235 834.209 470.164 834.209 472.543C834.209 474.922 836.066 476.85 838.358 476.85Z"
            fill="#E44400"
          />
          <path
            d="M853.134 476.083C855.426 476.083 857.283 474.155 857.283 471.776C857.283 469.397 855.426 467.468 853.134 467.468C850.843 467.468 848.985 469.397 848.985 471.776C848.985 474.155 850.843 476.083 853.134 476.083Z"
            fill="#F4BD5F"
          />
          <path
            d="M867.171 476.083C869.463 476.083 871.321 474.155 871.321 471.776C871.321 469.397 869.463 467.468 867.171 467.468C864.88 467.468 863.022 469.397 863.022 471.776C863.022 474.155 864.88 476.083 867.171 476.083Z"
            fill="#279316"
          />
          <path
            d="M1324.82 799.308H825.726V462.1H1324.82V799.308ZM826.912 798.077H1323.64V463.33H826.912V798.077Z"
            fill="#3F3D56"
          />
          <path d="M1324.23 480.766H826.319V481.997H1324.23V480.766Z" fill="#3F3D56" />
          <path
            d="M838.767 476.868C837.829 476.868 836.912 476.579 836.132 476.038C835.353 475.497 834.745 474.729 834.386 473.829C834.027 472.93 833.933 471.94 834.116 470.985C834.299 470.03 834.751 469.153 835.414 468.464C836.077 467.776 836.922 467.307 837.842 467.117C838.762 466.927 839.715 467.025 840.582 467.397C841.448 467.77 842.189 468.401 842.71 469.21C843.231 470.02 843.509 470.972 843.509 471.945C843.507 473.25 843.007 474.502 842.118 475.424C841.229 476.347 840.024 476.866 838.767 476.868ZM838.767 468.253C838.064 468.253 837.376 468.47 836.791 468.875C836.206 469.281 835.75 469.858 835.481 470.532C835.212 471.207 835.142 471.949 835.279 472.665C835.416 473.382 835.755 474.04 836.252 474.556C836.749 475.072 837.383 475.424 838.073 475.566C838.763 475.709 839.478 475.636 840.128 475.356C840.778 475.077 841.333 474.604 841.724 473.996C842.115 473.389 842.323 472.675 842.323 471.945C842.322 470.966 841.947 470.028 841.281 469.336C840.614 468.644 839.71 468.254 838.767 468.253Z"
            fill="#513A3A"
          />
          <path
            d="M852.993 476.868C852.055 476.868 851.138 476.579 850.358 476.038C849.579 475.497 848.971 474.729 848.612 473.829C848.253 472.93 848.159 471.94 848.342 470.985C848.525 470.03 848.977 469.153 849.64 468.464C850.303 467.776 851.148 467.307 852.068 467.117C852.988 466.927 853.941 467.025 854.808 467.397C855.674 467.77 856.415 468.401 856.936 469.21C857.457 470.02 857.735 470.972 857.735 471.945C857.733 473.25 857.233 474.502 856.344 475.424C855.455 476.347 854.25 476.866 852.993 476.868ZM852.993 468.253C852.29 468.253 851.602 468.47 851.017 468.875C850.432 469.281 849.976 469.858 849.707 470.532C849.438 471.207 849.368 471.949 849.505 472.665C849.642 473.382 849.981 474.04 850.478 474.556C850.976 475.072 851.609 475.424 852.299 475.566C852.989 475.709 853.704 475.636 854.354 475.356C855.004 475.077 855.559 474.604 855.95 473.996C856.341 473.389 856.549 472.675 856.549 471.945C856.548 470.966 856.173 470.028 855.507 469.336C854.84 468.644 853.936 468.254 852.993 468.253Z"
            fill="#4E4D43"
          />
          <path
            d="M867.219 476.868C866.281 476.868 865.364 476.579 864.584 476.038C863.804 475.497 863.197 474.729 862.838 473.829C862.479 472.93 862.385 471.94 862.568 470.985C862.751 470.03 863.203 469.153 863.866 468.464C864.529 467.776 865.374 467.307 866.294 467.117C867.214 466.927 868.167 467.025 869.033 467.397C869.9 467.77 870.641 468.401 871.162 469.21C871.683 470.02 871.961 470.972 871.961 471.945C871.959 473.25 871.459 474.502 870.57 475.424C869.681 476.347 868.476 476.866 867.219 476.868ZM867.219 468.253C866.515 468.253 865.828 468.47 865.243 468.875C864.658 469.281 864.202 469.858 863.933 470.532C863.664 471.207 863.593 471.949 863.731 472.665C863.868 473.382 864.207 474.04 864.704 474.556C865.201 475.072 865.835 475.424 866.525 475.566C867.215 475.709 867.93 475.636 868.58 475.356C869.23 475.077 869.785 474.604 870.176 473.996C870.567 473.389 870.775 472.675 870.775 471.945C870.774 470.966 870.399 470.028 869.732 469.336C869.066 468.644 868.162 468.254 867.219 468.253Z"
            fill="#3F3D56"
          />
          <path d="M983.236 481.381H982.05V799.17H983.236V481.381Z" fill="#3F3D56" />
          <Widget
            pose={bIsOpen ? 'open' : 'closed'}
            d="M1125.79 637.626H1038.66V649.933H1125.79V637.626Z"
            fill="#EDDEC5"
          />
          <Widget
            pose={bIsOpen ? 'open' : 'closed'}
            d="M1262.72 637.626H1175.59V649.933H1262.72V637.626Z"
            fill="#F6BD44"
          />
          <path
            d="M1131.13 644.395H1042.81V630.857H1131.13V644.395ZM1043.99 643.164H1129.94V632.088H1043.99V643.164Z"
            fill="#9A98AE"
          />
          <Widget
            pose={bIsOpen ? 'open' : 'closed'}
            d="M1125.79 733.004H1038.66V745.311H1125.79V733.004Z"
            fill="#F6C341"
          />
          <path
            d="M1131.13 739.773H1042.81V726.236H1131.13V739.773ZM1043.99 738.543H1129.94V727.466H1043.99V738.543Z"
            fill="#9A98AE"
          />
          <Widget
            pose={bIsOpen ? 'open' : 'closed'}
            d="M1263.02 684.085H1038.96V696.392H1263.02V684.085Z"
            fill="#F6BD44"
          />
          <path
            d="M1268.35 692.084H1043.11V678.547H1268.35V692.084ZM1044.29 690.853H1267.17V679.777H1044.29V690.853Z"
            fill="#9A98AE"
          />
          <path
            d="M1268.05 644.395H1179.73V630.857H1268.05V644.395ZM1180.92 643.164H1266.87V632.088H1180.92V643.164Z"
            fill="#9A98AE"
          />
          <path
            d="M905.92 681.036C896.276 681.036 886.848 678.067 878.83 672.505C870.811 666.943 864.561 659.037 860.87 649.787C857.18 640.538 856.214 630.36 858.096 620.541C859.977 610.721 864.621 601.702 871.44 594.623C878.26 587.543 886.948 582.722 896.407 580.769C905.866 578.816 915.67 579.818 924.58 583.65C933.49 587.481 941.105 593.969 946.463 602.293C951.821 610.618 954.681 620.404 954.681 630.416C954.666 643.837 949.524 656.703 940.383 666.193C931.242 675.683 918.848 681.021 905.92 681.036ZM905.92 581.33C896.568 581.33 887.426 584.209 879.65 589.603C871.875 594.996 865.814 602.663 862.236 611.632C858.657 620.601 857.72 630.471 859.545 639.992C861.369 649.514 865.873 658.26 872.485 665.125C879.098 671.99 887.523 676.665 896.695 678.559C905.867 680.453 915.374 679.481 924.014 675.765C932.654 672.05 940.039 665.759 945.235 657.687C950.43 649.615 953.203 640.124 953.203 630.416C953.189 617.402 948.203 604.926 939.339 595.724C930.474 586.521 918.456 581.345 905.92 581.33Z"
            fill="#3F3D56"
          />
        </g>
        <path
          d="M866 137.5C866 138.881 864.881 140 863.5 140C862.119 140 861 138.881 861 137.5C861 136.119 862.119 135 863.5 135C864.881 135 866 136.119 866 137.5Z"
          fill="white"
        />
        <path
          d="M709 233.5C709 231.567 707.433 230 705.5 230C703.567 230 702 231.567 702 233.5C702 235.433 703.567 237 705.5 237C707.433 237 709 235.433 709 233.5Z"
          fill="white"
        />
        <path
          d="M968 64.5C968 62.567 966.433 61 964.5 61C962.567 61 961 62.567 961 64.5C961 66.433 962.567 68 964.5 68C966.433 68 968 66.433 968 64.5Z"
          fill="white"
        />
        <path
          d="M833 125C833 127.209 831.209 129 829 129C826.791 129 825 127.209 825 125C825 122.791 826.791 121 829 121C831.209 121 833 122.791 833 125Z"
          fill="white"
        />
        <path
          d="M708.499 336C708.499 332.687 705.59 330 702 330C705.59 330 708.499 327.315 708.499 324C708.499 327.315 711.41 330 715 330C711.41 330 708.499 332.687 708.499 336Z"
          fill="white"
        />
        <path
          d="M726.499 162C726.499 157.858 723.142 154.5 719 154.5C723.142 154.5 726.499 151.143 726.499 147C726.499 151.143 729.858 154.5 734 154.5C729.858 154.5 726.499 157.858 726.499 162Z"
          fill="white"
        />
        <path
          d="M916 320C916 318.895 915.105 318 914 318C912.895 318 912 318.895 912 320C912 321.105 912.895 322 914 322C915.105 322 916 321.105 916 320Z"
          fill="white"
        />
        <path
          d="M915.499 64C915.499 58.7539 911.247 54.5 906 54.5C911.247 54.5 915.499 50.2483 915.499 45C915.499 50.2483 919.753 54.5 925 54.5C919.753 54.5 915.499 58.7539 915.499 64Z"
          fill="white"
        />
        <path
          d="M657 326C657 329.314 654.314 332 651 332C647.686 332 645 329.314 645 326C645 322.686 647.686 320 651 320C654.314 320 657 322.686 657 326Z"
          fill="white"
        />
        <path
          d="M1109 108C1109 147.212 1077.66 179 1039 179C1000.34 179 969 147.212 969 108C969 68.7878 1000.34 37 1039 37C1077.66 37 1109 68.7878 1109 108Z"
          fill="url(#paint5_radial)"
        />
        <path
          d="M1098 107.5C1098 140.913 1071.36 168 1038.5 168C1005.64 168 979 140.913 979 107.5C979 74.0868 1005.64 47 1038.5 47C1071.36 47 1098 74.0868 1098 107.5Z"
          fill="url(#paint6_linear)"
        />
        <ellipse cx="989.5" cy="96" rx="4.5" ry="5" fill="url(#paint7_linear)" />
        <ellipse cx="1051" cy="62" rx="9" ry="6" fill="url(#paint8_linear)" />
        <ellipse cx="1083" cy="101" rx="11" ry="13" fill="url(#paint9_linear)" />
        <ellipse cx="995.5" cy="118" rx="7.5" ry="9" fill="url(#paint10_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="282.482"
            y1="642.456"
            x2="77.2723"
            y2="986.655"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={bIsDark ? palette.darkPurple : '#4B217E'} />
            <stop offset="0.979753" stopColor={bIsDark ? palette.darkPurple : '#6175D4'} />
          </linearGradient>

          <linearGradient
            id="paint2_linear"
            x1="1363"
            y1="110"
            x2="1321.33"
            y2="973.265"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.164583" stopColor={bIsDark ? palette.darkPurple : '#481471'} />
            <stop offset="0.966667" stopColor={bIsDark ? palette.darkPurple : '#6D9EFF'} />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="596.679"
            y1="768.616"
            x2="596.679"
            y2="1007.69"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#6C63FF" />
            <stop offset="1" stopColor="#221E62" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="1140.91"
            y1="532.71"
            x2="1140.91"
            y2="589.937"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#644CF5" />
            <stop offset="1" stopColor="#7D44F6" />
          </linearGradient>
          <radialGradient
            id="paint5_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1037.91 106.17) rotate(-154.864) scale(63.9636 63.5716)">
            <stop stopColor="#FDF399" />
            <stop offset="1" stopColor="#E8DEC5" stopOpacity="0.11" />
          </radialGradient>
          <linearGradient
            id="paint6_linear"
            x1="1101.1"
            y1="139.309"
            x2="973.608"
            y2="73.4119"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FDF399" />
            <stop offset="1" stopColor="#C5C8E8" stopOpacity="0.71" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="989.856"
            y1="91.3945"
            x2="989.856"
            y2="101.552"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#544660" stopOpacity="0.19" />
            <stop offset="1" stopColor="#491EA5" stopOpacity="0.12" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="1051.71"
            y1="56.4734"
            x2="1051.71"
            y2="68.663"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#544660" stopOpacity="0.19" />
            <stop offset="1" stopColor="#491EA5" stopOpacity="0.12" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="1083.87"
            y1="89.0258"
            x2="1083.87"
            y2="115.436"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#544660" stopOpacity="0.19" />
            <stop offset="1" stopColor="#491EA5" stopOpacity="0.12" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="996.093"
            y1="109.71"
            x2="996.093"
            y2="127.994"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#544660" stopOpacity="0.19" />
            <stop offset="1" stopColor="#491EA5" stopOpacity="0.12" />
          </linearGradient>
          <clipPath id="clip0">
            <rect x="529.822" y="462.1" width="795" height="579.233" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};

export default withContent(withTheme(withStyles(styles)(ProjectsPageShape)));
