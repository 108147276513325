export default (theme) => ({
  containerAbout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  about__mainSvg: {
    width: 977,
    height: 508,
    marginTop: 25,
  },
  header__titleAbout: {
    position: 'absolute',
    top: 80,
    color: theme.palette.white,
    fontFamily: theme.fonts.passionOne,
    fontWeight: 400,
    textAlign: 'right',
    fontSize: 30,
    lineHeight: 1,
    letterSpacing: 1,
    left: '12%',
    width: 190,
  },
  shape__containerAbout: {
    position: 'relative',
    width: 515,
  },
  header__ctaAbout: {
    position: 'absolute',
    top: 180,
    left: '25%',
  },
  laptop__mainSvg: {
    position: 'absolute',
    height: 160,
    bottom: 200,
    left: '16%',
    top: 220,
    width: 190,
    zIndex: 10,
  },

  //topLeftShape
  topLeft__shape: {
    height: 260,
    position: 'absolute',
    top: 200,
    left: -220,
    width: 230,
  },

  //tablet
  '@media (max-width: 1170px)': {
    containerAbout: {
      marginTop: 100,
    },
  },

  '@media (max-width: 924px)': {
    topLeft__shape: {
      display: 'none',
    },
    about__mainSvg: {
      marginLeft: -50,
      width: '150%',
    },
    shape__containerAbout: {
      width: 490,
    },
    containerAbout: {
      marginTop: 150,
    },
  },

  //mobile
  '@media (max-width: 654px)': {
    about__mainSvg: {
      width: '170%',
    },
  },

  '@media (max-width: 560px)': {
    about__mainSvg: {
      width: '190%',
      marginRight: -50,
    },
    header__titleAbout: {
      textAlign: 'center',
      fontSize: 27,
      top: 80,
      left: '18%',
    },
    shape__containerAbout: {
      width: '100%',
    },
  },
  '@media (max-width: 460px)': {
    containerAbout: {
      marginTop: 30,
    },
    laptop__mainSvg: {
      top: 336,
      left: '12%',
    },
    header__ctaAbout: {
      top: 275,
    },
    header__titleAbout: {
      top: 120,
      fontSize: 28,
    },
    planet__redPurple: {
      display: 'none',
    },
  },
  '@media (max-width: 410px)': {
    header__titleAbout: {
      top: 165,
      fontSize: 24,
    },
  },
});
