export default (theme) => ({
  contactForm__container: {
    width: 700,
    position: 'relative',
  },
  contact__form: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '32%',
    width: '60%',
    left: '50%',
    marginLeft: -225,
    '& button': {
      width: 140,
      position: 'absolute',
      bottom: -48,
      right: 0,
    },
  },
  contactForm__title: {
    position: 'absolute',
    top: -50,
    '& h2': {
      width: 172,
    },
  },
  contact__svg: {
    width: '100%',
  },
  contact__input: {
    background: theme.palette.greyPurple,
    border: 'none',
    margin: '5px 0',
    height: 30,
    borderRadius: 10,
    outline: 'none',
    fontFamily: theme.fonts.quickSand,
    padding: '3px 10px',
    color: theme.palette.redPurple,
    '&::placeholder': {
      fontFamily: theme.fonts.quickSand,
      fontWeight: 600,
      color: theme.palette.redPurple,
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },
  contact__message: {
    background: theme.palette.greyPurple,
    border: 'none',
    margin: '5px 0',
    borderRadius: 10,
    outline: 'none',
    fontFamily: theme.fonts.quickSand,
    padding: '10px 10px',
    height: '100px',
    resize: 'none',
    color: theme.palette.redPurple,
    '&::placeholder': {
      fontFamily: theme.fonts.quickSand,
      fontWeight: 600,
      color: theme.palette.redPurple,
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },

  contact__error: {
    fontFamily: theme.fonts.quickSand,
    fontWeight: 600,
    color: theme.palette.yellow,
  },

  '@media (max-width: 800px)': {
    contactForm__container: {
      marginTop: 100,
    },
    contactForm__title: {
      left: 100,
    },
  },
  '@media (max-width: 730px)': {
    contactForm__container: {
      width: '100%',
    },
  },
  '@media (max-width: 630px)': {
    contact__form: {
      marginLeft: '-35%',
      width: '70%',
    },
  },
  '@media (max-width: 490px)': {
    contactForm__title: {
      top: -80,
    },
    contactForm__container: {
      width: '120%',
    },
    contact__form: {
      marginLeft: '-35%',
      width: '70%',
    },
  },
  '@media (max-width: 400px)': {
    contactForm__container: {
      marginBottom: 80,
    },
  },
});
