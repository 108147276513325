export default (theme) => ({
  personalProjects__container: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -80,
    width: '100%',
  },
  personalProjects__title: {
    position: 'absolute',
    top: 250,
    left: 300,
    zIndex: 1,
    '& div': {
      width: 265,
    },
  },
  personalProjects__grid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 650,
    position: 'relative',
    zIndex: 1,
    marginTop: -50,
    justifyContent: 'space-between',
  },
  personalProjects__svg: {
    position: 'absolute',
    zIndex: -1,
    left: -112,
    height: 790,
    width: 750,
  },
  personalProjects__rightShape: {
    position: 'absolute',
    zIndex: -2,
    right: '-65%',
    transform: 'scale(0.7)',
  },
  personalProjects__leftShape: {
    position: 'absolute',
    zIndex: -2,
    left: '-50%',
    bottom: 0,
  },
  personalProjects__codeEditor: {
    position: 'relative',
    right: '-20%',
  },
  //tablet
  '@media (max-width: 1100px)': {
    personalProjects__title: {
      top: -120,
    },
    personalProjects__codeEditor: {
      right: '-10%',
    },
    personalProjects__container: {
      marginTop: 150,
    },
    personalProjects__rightShape: {
      display: 'none',
    },
    personalProjects__leftShape: {
      display: 'none',
    },
  },

  //mobile
  '@media (max-width: 720px)': {
    personalProjects__title: {
      top: -115,
      left: 'unset',
    },
    personalProjects__codeEditor: {
      right: 'unset',
      width: '100%',
      marginBottom: 50,
    },
    personalProjects__svg: {
      width: '100%',
      left: 'unset',
    },
    personalProjects__grid: {
      width: '100%',
      padding: 20,
      boxSizing: 'border-box',
    },
  },
  '@media (max-width: 690px)': {
    personalProjects__grid: {
      justifyContent: 'center',
    },
  },
});
