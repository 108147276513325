export default (theme) => ({
  cta__btn: {
    backgroundColor: theme.palette.yellow,
    border: 'none',
    borderRadius: 18,
    padding: '7px 20px',
    fontSize: 17,
    fontFamily: theme.fonts.passionOne,
    color: theme.palette.darkPurple,
    cursor: 'pointer',
    transition: 'all 0.3s',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette.purple,
      color: theme.palette.white,
    },
  },
});
