import React from 'react';
import palette from '../../../theme/Palette';
import { Parallax } from 'react-skrollr';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from '../styles';

const TopRightShape = ({ bIsDark, classes }) => {
  return (
    <Parallax
      data={{
        'data-0': 'transform:translateY(0px);',
        'data-230': 'transform:translateY(-40px);',
      }}>
      <svg className={classes.nav__topRightShape} viewBox="0 0 275 301" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.2613 114.183C56.1139 56.8357 111.89 9.13789 168.68 23.3813C225.47 37.6247 267.116 109.395 251.425 161.611C235.734 213.826 60.2015 315.239 51.2304 251.035C46.968 183.186 12.5706 166.398 28.2613 114.183Z"
          fill="url(#topRight-gradient)"
        />
        <defs>
          <linearGradient
            id="topRight-gradient"
            x1="61.6093"
            y1="265.402"
            x2="145.684"
            y2="78.2534"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={bIsDark ? palette.darkPurple : '#881BC1'} />
            <stop offset="0.948503" stopColor={bIsDark ? palette.darkPurple : '#511280'} />
          </linearGradient>
        </defs>
      </svg>
    </Parallax>
  );
};

export default withStyles(styles)(TopRightShape);
