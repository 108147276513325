import React, { useEffect, useState } from 'react';
import withTheme from '../../hoc/withTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import posed from 'react-pose';
import Typist from 'react-typist';
import palette from '../../theme/Palette';
import { BackgroundAbstractShape } from '../common/';

const TerminalCursor = posed.path({
  open: { x: 400, opacity: 0, delay: 500 },
  closed: { x: 0, opacity: 1, delay: 500 },
});

const LetterR = posed.path({
  open: { x: -100, opacity: 0, delay: 500 },
  closed: { x: 0, opacity: 1, delay: 500 },
});

const Code = posed.path({
  open: { x: -60, opacity: 1, delay: 500 },
  closed: { x: 0, opacity: 1, delay: 500 },
});

const FaButton = ({ bIsDark, classes }) => {
  const [bIsAnimating, setAnimating] = useState(false);
  useEffect(() => {
    if (bIsAnimating === false) {
      setAnimating(true);
    }
  }, [bIsAnimating]);

  return (
    <div
      className={classes.loader__container}
      style={{ background: bIsDark ? palette.darkModePurpleBg : palette.white }}>
      <div className={classes.logo__container}>
        <div className={classes.loader__bg}>
          <BackgroundAbstractShape intRotation={320} bDarkModePurple />
        </div>
        <Typist
          className={classes.logo__typist}
          avgTypingDelay={70}
          startDelay={800}
          cursor={{
            show: false,
            blink: true,
            hideWhenDone: true,
          }}>
          <span pose={bIsAnimating ? 'open' : 'closed'} className={classes.logo__animatedText}>
            {'Loading..'}
          </span>
        </Typist>
        <p className={classes.loader__title}>razvan-anisia.com</p>
        <svg className={classes.logo__svg} viewBox="0 0 155 173" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M68.6358 5.62389C74.4363 2.77152 81.2272 2.74934 87.0462 5.56376L141.22 31.7657C148.471 35.2724 153.077 42.6168 153.077 50.6706L153.077 120.43C153.077 128.384 148.583 135.655 141.468 139.213L87.1775 166.358C81.288 169.303 74.3584 169.315 68.4589 166.39L13.6003 139.195C6.45046 135.651 1.92741 128.36 1.92741 120.38L1.92741 51.5026C1.92741 43.4982 6.47769 36.1899 13.6606 32.6578L68.6358 5.62389Z"
            fill="#403B89"
          />
          <path
            d="M77.4054 84.4014L123.653 55.7612C136.311 47.9227 152.657 57.0265 152.657 71.9146L152.657 121.966C152.657 129.162 148.591 135.741 142.154 138.96L85.8667 167.103C80.5381 169.767 74.2684 169.778 68.9308 167.132L31.3648 148.51C18.1783 141.973 17.1107 123.572 29.4529 115.553L77.4054 84.4014Z"
            fill="#2C2870"
          />
          <TerminalCursor
            pose={bIsAnimating ? 'open' : 'closed'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.904 97.955L114.558 98.0579C114.374 98.059 114.007 98.0613 113.824 98.2675L101.587 109.21C101.405 109.416 101.406 109.621 101.591 109.824C101.592 110.029 101.776 110.028 101.96 110.027L131.162 109.843C134.101 109.825 136.654 106.939 136.448 103.455C136.244 100.176 133.658 97.9376 130.904 97.955Z"
            fill="#5451EC"
          />
          <LetterR
            pose={bIsAnimating ? 'open' : 'closed'}
            d="M63.7785 74.808C58.9145 74.808 55.5545 75.736 53.6985 77.592V99.576C58.8825 100.536 62.4345 101.88 64.3545 103.608L63.3945 111H35.9385L34.9785 103.608C35.8745 102.392 37.6345 101.304 40.2585 100.344V73.656C38.0185 72.888 36.2585 71.8 34.9785 70.392L35.9385 63C39.4585 62.232 42.6265 61.848 45.4425 61.848C48.2585 61.848 50.5945 61.912 52.4505 62.04V68.088C53.9225 66.36 55.8105 64.952 58.1145 63.864C60.4185 62.712 62.4985 62.136 64.3545 62.136C66.2745 62.136 67.5865 62.296 68.2905 62.616L68.0025 75.096C66.7225 74.904 65.3145 74.808 63.7785 74.808Z"
            fill="#F9F7FF"
          />
          <Code
            pose={bIsAnimating ? 'open' : 'closed'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.955 84.9296C111.747 83.1927 110.944 81.4595 109.751 80.3806L89.2623 62.6926C86.6769 60.536 83.1188 60.993 81.1583 63.83C79.1978 66.667 79.6181 70.5754 82.2035 72.7319L97.1225 85.6748L82.3676 98.8045C79.8095 100.993 79.4385 104.907 81.4345 107.719C83.4306 110.531 86.9942 110.943 89.5522 108.754L109.813 90.3751C111.19 89.0628 111.97 87.3196 111.958 85.3642L111.955 84.9296Z"
            fill="white"
          />
          <Code
            pose={bIsAnimating ? 'open' : 'closed'}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.156 72.0426L93.2659 82.3565C93.402 82.4724 96.6275 85.3081 97.1274 85.8081L106.392 77.8026C106.392 78.0777 106.392 77.3026 106.392 77.8026C106.527 77.6435 106.656 77.8026 106.656 77.8026L89.3918 62.5426C86.3918 60.5426 83.3243 60.9811 81.156 63.8026C79.1233 66.465 79.115 70.3043 81.156 72.0426Z"
            fill="#5451EC"
          />
        </svg>
      </div>
    </div>
  );
};

export default withTheme(withStyles(styles)(FaButton));
