import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';
import { Parallax } from 'react-skrollr';

const TopLeftShape = ({ classes, bIsDark }) => {
  return (
    <Parallax
      data={{
        'data-0': 'transform:translateY(0px);',
        'data-365': 'transform:translateY(-40px);',
      }}>
      <svg className={classes.topLeft__shape} viewBox="0 0 627 679" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M188.187 586.547C102.911 508.49 50.2523 379.074 107.121 272.465C163.99 165.856 360.202 47.0226 443.264 98.9712C612.164 159.724 471.922 622.572 377.171 550.607C321.245 540.367 271.249 638.495 188.187 586.547Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M180 268.5C180 266.567 178.433 265 176.5 265C174.567 265 173 266.567 173 268.5C173 270.433 174.567 272 176.5 272C178.433 272 180 270.433 180 268.5Z"
          fill="white"
        />
        <path
          d="M402 226.5C402 224.567 400.433 223 398.5 223C396.567 223 395 224.567 395 226.5C395 228.433 396.567 230 398.5 230C400.433 230 402 228.433 402 226.5Z"
          fill="white"
        />
        <path
          d="M403 154C403 152.895 402.105 152 401 152C399.895 152 399 152.895 399 154C399 155.105 399.895 156 401 156C402.105 156 403 155.105 403 154Z"
          fill="white"
        />
        <path
          d="M442 168.5C442 166.567 440.433 165 438.5 165C436.567 165 435 166.567 435 168.5C435 170.433 436.567 172 438.5 172C440.433 172 442 170.433 442 168.5Z"
          fill="white"
        />
        <path
          d="M296 172C296 170.343 294.657 169 293 169C291.343 169 290 170.343 290 172C290 173.657 291.343 175 293 175C294.657 175 296 173.657 296 172Z"
          fill="white"
        />
        <path
          d="M251 207.5C251 205.567 249.433 204 247.5 204C245.567 204 244 205.567 244 207.5C244 209.433 245.567 211 247.5 211C249.433 211 251 209.433 251 207.5Z"
          fill="white"
        />
        <path
          d="M427 144.5C427 142.567 425.433 141 423.5 141C421.567 141 420 142.567 420 144.5C420 146.433 421.567 148 423.5 148C425.433 148 427 146.433 427 144.5Z"
          fill="white"
        />
        <path
          d="M351.498 145C351.498 137.545 345.008 131.5 337 131.5C345.008 131.5 351.498 125.458 351.498 118C351.498 125.458 357.992 131.5 366 131.5C357.992 131.5 351.498 137.545 351.498 145Z"
          fill="white"
        />
        <path
          d="M232.999 195C232.999 189.478 228.075 185 222 185C228.075 185 232.999 180.525 232.999 175C232.999 180.525 237.925 185 244 185C237.925 185 232.999 189.478 232.999 195Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear" x1="615" y1="375" x2="550" y2="388" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B864FA" />
            <stop offset="1" stopColor="#4D15C4" />
            <stop offset="1" stopColor="#8864D6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="425.335"
            y1="89.9567"
            x2="190.017"
            y2="588.342"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={bIsDark ? palette.darkPurple : '#4D1677'} />
            <stop offset="1" stopColor={bIsDark ? palette.darkPurple : '#8025BC'} />
          </linearGradient>
        </defs>
      </svg>
    </Parallax>
  );
};

export default withTheme(withStyles(styles)(TopLeftShape));
