export default (theme) => ({
  loader__container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo__container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .Typist': {
      position: 'absolute',
      bottom: 60,
      left: 90,
    },
  },
  logo__animatedText: {
    fontFamily: theme.fonts.passionOne,
    fontSize: 18,
    color: theme.palette.white,
    fontWeight: 500,
    letterSpacing: 1,
    position: 'relative',
    zIndex: 10,
  },
  logo__svg: {
    position: 'relative',
    width: 150,
    height: 150,
  },
  loader__title: {
    position: 'relative',
    fontSize: 30,
    fontFamily: theme.fonts.passionOne,
    color: theme.loaderText,
  },
  loader__bg: {
    position: 'absolute',
    zIndex: 0,
  },
  '@media (max-width: 550px)': {
    logo__svg: {
      width: 100,
      height: 100,
    },
    logo__animatedText: {
      fontSize: 13,
    },
    loader__title: {
      fontSize: 25,
    },
    logo__container: {
      '& .Typist': {
        position: 'absolute',
        bottom: 34,
        left: 75,
      },
    },
  },
});
