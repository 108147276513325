import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { useHistory } from 'react-router-dom';

const CtaButton = ({ strButtonText, classes, strLink, ...objOtherProps }) => {
  let history = useHistory();

  const handleClick = () => {
    history.push(strLink);
  };
  return (
    <button {...objOtherProps} className={classes.cta__btn} onClick={handleClick}>
      {strButtonText}
    </button>
  );
};

export default withStyles(styles)(CtaButton);
