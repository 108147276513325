import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import withContent from '../../../../hoc/withContent';
import { FloatingAbstractShape, BackgroundAbstractShape } from '../../../../components/common';
import palette from '../../../../theme/Palette';
import createFloatingParallaxOptions from '../../../../util/createFloatingParallaxOptions';
import SectionTitle from '../../../../components/SectionTitle';
import ProjectCard from '../../../../components/ProjectCard';
import GithubActivity from './components/GithubActivity';
import ConnexBg from '../../../../assets/commercialProjects/connexBg.png';
import ConnexLogo from '../../../../assets/commercialProjects/connexLogo.png';
import EDBg from '../../../../assets/commercialProjects/EDBg.png';
import EDLogo from '../../../../assets/commercialProjects/EDLogo.png';
import gceBg from '../../../../assets/commercialProjects/gceBg.png';
import gceLogo from '../../../../assets/commercialProjects/gceLogo.png';
import maybernBg from '../../../../assets/commercialProjects/maybernBg.png';
import maybernLogo from '../../../../assets/commercialProjects/maybernLogo.png';
import newtonBg from '../../../../assets/commercialProjects/newtonBg.png';
import newtonLogo from '../../../../assets/commercialProjects/newtonLogo.png';
import lucionBg from '../../../../assets/commercialProjects/lucionBg.png';
import lucionLogo from '../../../../assets/commercialProjects/lucionLogo.png';
import davantiBg from '../../../../assets/commercialProjects/davantiBg.png';
import davantiLogo from '../../../../assets/commercialProjects/davantiLogo.png';
import renfrewBg from '../../../../assets/commercialProjects/renfrewBg.png';
import renfrewLogo from '../../../../assets/commercialProjects/renfrewLogo.png';

const CommercialProjects = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const { subtitles_text_projectspage: arrSectionTitles } = objContent[intContentIndex].data;

  return (
    <div className={classes.commercialProjects__container}>
      <div className={classes.commercialProjects__title}>
        <SectionTitle strTitleText={arrSectionTitles[1].text} />
      </div>
      <GithubActivity />
      <div className={classes.commercialProjects__grid}>
        <a href="https://www.connexone.co.uk/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={ConnexBg}>
            <img className={classes.connexLogo} src={ConnexLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://procontrol.gcegroup.com/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={gceBg}>
            <img className={classes.gceLogo} src={gceLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://maybern.co.uk/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={maybernBg}>
            <img className={classes.maybernLogo} src={maybernLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://www.elegantdigital.co.uk/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={EDBg}>
            <img className={classes.edLogo} src={EDLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://www.newtoneurope.com/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={newtonBg}>
            <img className={classes.newtonLogo} src={newtonLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://www.lucionservices.com/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={lucionBg}>
            <img className={classes.lucionLogo} src={lucionLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://www.davanti-tyres.com/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={davantiBg}>
            <img className={classes.davantiLogo} src={davantiLogo} alt="logo" />
          </ProjectCard>
        </a>
        <a href="https://renfrewcreative.co.uk/" target="_blank" rel="noopener noreferrer">
          <ProjectCard bIsCommercial strBgImageUrl={renfrewBg}>
            <img className={classes.renfrewLogo} src={renfrewLogo} alt="logo" />
          </ProjectCard>
        </a>

        <div className={classes.commercialProjects__leftShape}>
          <FloatingAbstractShape bLight objParallaxOptions={createFloatingParallaxOptions('200', '1200', '-80px')} />
        </div>
        <div className={classes.commercialProjects__rightShape}>
          <BackgroundAbstractShape
            bDarkModePurple
            intRotation={400}
            objParallaxOptions={createFloatingParallaxOptions('200', '1300', '-80px')}
          />
        </div>
        <svg
          className={classes.commercialProjects__svg}
          viewBox="0 0 1817 1885"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M146.106 647.31C185.608 374.539 624.878 243.156 1111.06 155.985C1826.43 41.8373 1098.94 1001.59 1540.28 1097.02C2086.11 1296.4 738.56 1987.42 624.871 1693.04C609.506 1481.67 80.6458 1314.63 146.106 647.31Z"
            fill="url(#paint0_linear_commercial_projects_svg)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_commercial_projects_svg"
              x1="611.715"
              y1="1695.28"
              x2="1995.58"
              y2="428.19"
              gradientUnits="userSpaceOnUse">
              <stop stop-color={bIsDark ? palette.darkPurple : palette.brightPurple} />
              <stop offset="1" stop-color={bIsDark ? palette.darkPurple : palette.purpleRed} />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(CommercialProjects)));
