export default (theme) => ({
  logo__svg: {
    height: 75,
    width: 65,
  },
  logo__container: {
    position: 'relative',
    cursor: 'pointer',
    marginLeft: 30,
    zIndex: 10,
    '& .Typist': {
      position: 'absolute',
      top: 31,
      left: 25,
    },
  },
  logo__animatedText: {
    fontFamily: theme.fonts.passionOne,
    fontSize: 12,
    color: theme.palette.white,
    fontWeight: 500,
    letterSpacing: 1,
  },
  logo__animatedCursor: {
    fontFamily: theme.fonts.passionOne,
    fontSize: 12,
    color: 'rgb(84, 81, 236)',
    fontWeight: 500,
    letterSpacing: 1,
  },
});
