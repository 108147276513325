export default (theme) => ({
  placeholder__svg: {
    width: '45%',
    marginTop: 20,
  },
  placeholder__title: {
    marginTop: 180,
    fontFamily: theme.fonts.passionOne,
    letterSpacing: 0.7,
    fontSize: 50,
    fontWeight: 500,
    color: theme.textColorPlaceholderTitle,
  },
  '@media (max-width: 1300px)': {
    placeholder__svg: {
      width: '60%',
    },
  },
  //mobile
  '@media (max-width: 740px)': {
    placeholder__svg: {
      width: '70%',
    },
    placeholder__title: {
      fontSize: 40,
    },
  },
  '@media (max-width: 540px)': {
    placeholder__svg: {
      width: '95%',
    },
    placeholder__title: {
      fontSize: 40,
    },
  },
});
