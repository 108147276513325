import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from '../styles';
import GithubIcon from '../../../assets/icons/github.svg';
import LinkedInIcon from '../../../assets/icons/linkedin.svg';
import GmailIcon from '../../../assets/icons/gmail.svg';
import withContent from '../../../hoc/withContent';

const SocialLinks = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const { social_media_links: arrSocialLinks } = objContent[intContentIndex].data;
  const strGithubLink = arrSocialLinks[0].text;
  const strLinkednInLink = arrSocialLinks[1].text;
  const strEmailLink = arrSocialLinks[2].text;

  return (
    <div className={classes.social__container}>
      <a href={strGithubLink}>
        <img className={classes.social__github} src={GithubIcon} alt="github" />
      </a>
      <a href={`mailto:${strEmailLink}`}>
        <img className={classes.social__gmail} src={GmailIcon} alt="gmail" />
      </a>
      <a href={strLinkednInLink}>
        <img className={classes.social__linkedin} src={LinkedInIcon} alt="linkedin" />
      </a>
    </div>
  );
};

export default withContent(withStyles(styles)(SocialLinks));
