import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';
import posed from 'react-pose';

const Screen = posed.path({
  open: { x: 0, opacity: 1 },
  closed: { x: 10, opacity: 0 },
});

const LaptopShape = ({ classes, bIsDark }) => {
  const [bIsOpen, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    // <Container pose={bIsOpen ? 'open' : 'closed'}>
    <svg
      className={classes.laptop__mainSvg}
      viewBox="300 100 314 380"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink">
      <g filter="url(#filter0_f)">
        <path
          d="M465.951 476.16C464.653 476.16 463.284 475.858 462.214 475.271L283.618 376.942C281.663 375.865 281.45 374.237 283.143 373.305L389.718 314.628C390.485 314.206 391.512 314 392.587 314C393.885 314 395.254 314.3 396.324 314.89L574.922 413.219C576.875 414.295 577.089 415.923 575.395 416.855L468.82 475.532C468.053 475.954 467.026 476.16 465.951 476.16Z"
          fill="url(#paint0_radial_laptop)"
        />
      </g>
      <path
        d="M611.71 261.345C611.71 261.344 611.71 261.341 611.71 261.338C611.71 261.341 611.71 261.344 611.71 261.345ZM611.711 261.334C611.799 260.707 611.637 259.975 611.317 259.279L608.35 260.533C607.918 259.724 607.266 258.994 606.525 258.586L426.092 159.108C425.634 158.855 425.196 158.733 424.808 158.733C424.56 158.733 424.332 158.783 424.129 158.88L426.826 157.404L426.821 157.42C427.059 157.267 427.342 157.189 427.655 157.189C428.039 157.189 428.469 157.308 428.923 157.558L609.356 257.035C610.812 257.837 611.92 259.873 611.711 261.334Z"
        fill="url(#paint1_linear_laptop)"
      />
      <path
        d="M608.35 260.533L611.317 259.279C611.649 260.005 611.813 260.768 611.699 261.412L588.942 390.335C588.824 391.006 588.475 391.453 587.991 391.647L587.994 391.649L585.325 393.121L584.867 390.742L585.019 390.786L575.944 385.783L608.35 260.533Z"
        fill="#529BDA"
      />
      <Screen
        pose={bIsOpen ? 'open' : 'closed'}
        d="M408.069 292.621L588.502 392.099C589.894 392.866 591.087 392.423 591.332 391.045L614.087 262.122C614.347 260.658 613.226 258.562 611.745 257.746L431.312 158.268C429.92 157.499 428.727 157.944 428.482 159.322L405.727 288.245C405.465 289.709 406.587 291.803 408.069 292.621Z"
        fill="url(#paint2_linear_laptop)"
      />
      <path
        d="M461.742 273.082L452.785 268.14L453.13 266.187L462.633 271.429L462.405 272.717L461.742 273.082"
        fill="url(#paint3_linear_laptop)"
      />
      <path
        d="M584.566 393.312C584.177 393.312 583.74 393.192 583.282 392.939L402.849 293.462C401.511 292.723 400.469 290.946 400.469 289.529C400.469 289.377 400.481 289.227 400.507 289.085L423.262 160.162C423.427 159.238 424.017 158.733 424.808 158.733C425.196 158.733 425.634 158.855 426.092 159.108L606.525 258.586C607.861 259.323 608.905 261.101 608.905 262.516C608.905 262.67 608.893 262.82 608.867 262.963L586.112 391.886C586.013 392.444 585.759 392.848 585.398 393.08C585.163 393.234 584.88 393.312 584.566 393.312ZM407 288.82L583.055 385.889L585.289 373.318L604.358 265.965L604.623 264.469L426.406 166.207L404.953 287.69L407 288.819"
        fill="url(#paint4_linear_laptop)"
      />
      <path
        d="M289.429 354.499L299.276 359.409L398.013 304.973C399.664 304.062 402.551 304.175 404.457 305.227L574.751 399.115C576.381 398.244 583.81 394.833 583.81 394.833L580.206 403.801L580.191 403.789C580.042 404.189 579.722 404.561 579.184 404.857L475.194 462.189C473.543 463.1 470.656 462.987 468.75 461.935L294.486 365.858C293.702 365.426 293.223 364.903 293.023 364.374L293.01 364.383L289.429 354.499"
        fill="url(#paint5_linear_laptop)"
      />
      <path
        d="M290.952 356.043L469.548 454.51C471.504 455.588 474.46 455.704 476.154 454.771L582.729 396.012C584.423 395.079 584.21 393.449 582.256 392.372L403.658 293.906C401.703 292.828 398.746 292.71 397.053 293.645L290.477 352.402C288.784 353.336 288.997 354.967 290.952 356.043"
        fill="url(#paint6_radial_laptop)"
      />
      <path
        d="M290.952 354.377L469.548 452.843C471.504 453.92 474.46 454.037 476.154 453.103L582.729 394.346C583.132 394.123 583.423 393.862 583.613 393.577C584.138 394.452 583.863 395.389 582.729 396.012L476.154 454.771C474.46 455.704 471.504 455.588 469.548 454.51L290.952 356.043C289.461 355.222 288.988 354.08 289.593 353.171C289.854 353.602 290.306 354.021 290.952 354.377"
        fill="#8FDAEC"
      />
      <path d="M399.344 316.082L528.262 387.157L533.995 383.998L405.077 312.921L399.344 316.082Z" fill="#8AD9EC" />
      <path d="M385.29 323.83L506.212 390.496L511.944 387.336L391.022 320.669L385.29 323.83Z" fill="#8AD9EC" />
      <path d="M376.137 328.875L518.508 407.368L524.241 404.209L381.869 325.715L376.137 328.875" fill="#8AD9EC" />
      <path d="M366.984 333.922L509.355 412.415L515.088 409.254L372.716 330.761L366.984 333.922Z" fill="#8AD9EC" />
      <path d="M357.832 338.968L500.203 417.462L505.935 414.301L363.564 335.808L357.832 338.968Z" fill="#8AD9EC" />
      <path d="M536.052 391.453L541.784 394.613L547.516 391.453L541.784 388.292L536.052 391.453Z" fill="#8AD9EC" />
      <path d="M515.833 395.802L527.731 402.361L533.463 399.2L521.565 392.641L515.833 395.802Z" fill="#8AD9EC" />
      <path
        d="M452.913 208.987L441.133 202.488L441.478 200.533L453.259 207.034L452.913 208.987Z"
        fill="url(#paint7_linear_laptop)"
      />
      <path
        d="M461.228 213.567L454.808 210.025L455.153 208.072L461.573 211.613L461.228 213.567"
        fill="url(#paint8_linear_laptop)"
      />
      <path
        d="M475.86 221.632L471.216 219.07L471.562 217.116L476.206 219.679L475.86 221.632Z"
        fill="url(#paint9_linear_laptop)"
      />
      <path
        d="M469.416 218.076L467.829 217.2L468.174 215.247L469.761 216.123L469.416 218.076Z"
        fill="url(#paint10_linear_laptop)"
      />
      <path
        d="M466.029 216.208L463.147 214.618L463.491 212.664L466.374 214.255L466.029 216.208Z"
        fill="url(#paint11_linear_laptop)"
      />
      <path
        d="M455.1 219.989L450.456 217.425L450.801 215.472L455.446 218.036L455.1 219.989Z"
        fill="url(#paint12_linear_laptop)"
      />
      <path
        d="M448.656 216.433L439.556 211.413L439.9 209.458L449.001 214.48L448.656 216.433Z"
        fill="url(#paint13_linear_laptop)"
      />
      <path
        d="M461.707 248.09L452.606 243.068L452.952 241.115L462.053 246.136L461.707 248.09Z"
        fill="url(#paint14_linear_laptop)"
      />
      <path
        d="M450.806 242.076L447.924 240.486L448.269 238.532L451.151 240.123L450.806 242.076Z"
        fill="url(#paint15_linear_laptop)"
      />
      <path
        d="M463.224 239.154L454.123 234.132L454.469 232.179L463.568 237.199L463.224 239.154Z"
        fill="url(#paint16_linear_laptop)"
      />
      <path
        d="M452.325 233.139L449.441 231.548L449.787 229.595L452.669 231.186L452.325 233.139Z"
        fill="url(#paint17_linear_laptop)"
      />
      <path
        d="M430.062 275.044L428.407 274.131L428.752 272.177L432.006 273.973L430.062 275.044Z"
        fill="url(#paint18_linear_laptop)"
      />
      <path
        d="M433.841 272.23L429.197 269.669L429.541 267.714L434.186 270.277L433.841 272.23"
        fill="url(#paint19_linear_laptop)"
      />
      <path d="M435.886 271.834L435.985 271.27L436.446 271.524L435.886 271.834" fill="url(#paint20_linear_laptop)" />
      <path
        d="M438.941 270.148L429.985 265.206L430.33 263.253L439.833 268.496L439.605 269.783L438.941 270.148"
        fill="url(#paint21_linear_laptop)"
      />
      <path
        d="M455.958 276.388L447.001 271.446L447.347 269.493L456.85 274.736L456.621 276.024L455.958 276.388"
        fill="url(#paint22_linear_laptop)"
      />
      <rect x="437.468" y="268.517" width="3.07385" height="2.93527" fill="url(#pattern0)" />
      <path
        d="M496.965 238.158L496.274 237.777L496.618 235.823L498.909 237.087L496.965 238.158Z"
        fill="url(#paint23_linear_laptop)"
      />
      <path
        d="M494.474 236.784L487.6 232.992L487.946 231.039L494.819 234.831L494.474 236.784Z"
        fill="url(#paint24_linear_laptop)"
      />
      <path
        d="M485.802 231.999L481.156 229.437L481.501 227.484L486.145 230.046L485.802 231.999"
        fill="url(#paint25_linear_laptop)"
      />
      <path
        d="M501.421 235.702L493.118 231.121L493.463 229.168L502.966 234.411L502.879 234.897L501.421 235.702"
        fill="url(#paint26_linear_laptop)"
      />
      <path
        d="M491.318 230.127L484.446 226.336L484.791 224.383L491.663 228.174L491.318 230.127Z"
        fill="url(#paint27_linear_laptop)"
      />
      <path
        d="M482.646 225.343L478.001 222.78L478.345 220.827L482.991 223.39L482.646 225.343"
        fill="url(#paint28_linear_laptop)"
      />
      <path
        d="M457.961 241.15L454.75 239.379L455.096 237.426L458.306 239.196L457.961 241.15"
        fill="url(#paint29_linear_laptop)"
      />
      <path
        d="M452.95 238.385L448.709 236.045L449.054 234.092L453.295 236.432L452.95 238.385Z"
        fill="url(#paint30_linear_laptop)"
      />
      <path
        d="M440.337 266.014L430.834 260.77L431.179 258.817L440.682 264.06L440.337 266.014"
        fill="url(#paint31_linear_laptop)"
      />
      <path
        d="M443.389 267.697L442.137 267.006L442.482 265.054L445.332 266.626L443.389 267.697Z"
        fill="url(#paint32_linear_laptop)"
      />
      <path
        d="M434.833 258.079L431.622 256.309L431.968 254.354L435.177 256.126L434.833 258.079"
        fill="url(#paint33_linear_laptop)"
      />
      <path
        d="M440.874 261.412L436.633 259.073L436.977 257.12L441.219 259.459L440.874 261.412"
        fill="url(#paint34_linear_laptop)"
      />
      <path
        d="M447.828 265.25L442.674 262.406L443.02 260.452L449.771 264.177L447.828 265.25Z"
        fill="url(#paint35_linear_laptop)"
      />
      <path
        d="M462.325 228.845L459.116 227.075L459.46 225.121L462.671 226.892L462.325 228.845"
        fill="url(#paint36_linear_laptop)"
      />
      <path
        d="M457.316 226.082L453.073 223.741L453.419 221.788L457.66 224.129L457.316 226.082"
        fill="url(#paint37_linear_laptop)"
      />
      <path
        d="M451.273 222.748L444.439 218.977L444.785 217.024L451.619 220.795L451.273 222.748Z"
        fill="url(#paint38_linear_laptop)"
      />
      <path
        d="M470.839 238.088L464.005 234.318L464.35 232.365L471.184 236.135L470.839 238.088Z"
        fill="url(#paint39_linear_laptop)"
      />
      <path
        d="M462.039 233.584L452.827 228.501L453.172 226.548L462.384 231.631L462.039 233.584Z"
        fill="url(#paint40_linear_laptop)"
      />
      <path
        d="M451.027 227.508L448.145 225.917L448.49 223.964L451.372 225.555L451.027 227.508Z"
        fill="url(#paint41_linear_laptop)"
      />
      <path
        d="M446.533 225.029L443.651 223.438L443.996 221.485L446.878 223.075L446.533 225.029Z"
        fill="url(#paint42_linear_laptop)"
      />
      <path
        d="M453.371 253.673L444.272 248.652L444.617 246.699L453.717 251.72L453.371 253.673Z"
        fill="url(#paint43_linear_laptop)"
      />
      <path
        d="M442.472 247.66L439.59 246.069L439.935 244.116L442.817 245.707L442.472 247.66Z"
        fill="url(#paint44_linear_laptop)"
      />
      <path
        d="M448.108 255.671L444.899 253.9L445.244 251.947L448.454 253.716L448.108 255.671"
        fill="url(#paint45_linear_laptop)"
      />
      <path
        d="M459.438 252.093L456.229 250.322L456.575 248.369L459.784 250.14L459.438 252.093Z"
        fill="url(#paint46_linear_laptop)"
      />
      <path
        d="M465.566 255.469L460.805 252.845L461.15 250.89L467.511 254.398L465.566 255.469Z"
        fill="url(#paint47_linear_laptop)"
      />
      <path
        d="M443.099 252.906L438.856 250.566L439.202 248.613L443.444 250.953L443.099 252.906Z"
        fill="url(#paint48_linear_laptop)"
      />
      <path
        d="M441.668 256.922L438.458 255.151L438.803 253.198L442.012 254.968L441.668 256.922"
        fill="url(#paint49_linear_laptop)"
      />
      <path
        d="M436.657 254.157L432.417 251.817L432.762 249.864L437.003 252.204L436.657 254.157Z"
        fill="url(#paint50_linear_laptop)"
      />
      <path
        d="M454.454 249.346L451.571 247.756L451.917 245.803L454.799 247.393L454.454 249.346Z"
        fill="url(#paint51_linear_laptop)"
      />
      <path
        d="M449.961 246.867L447.078 245.277L447.423 243.324L450.305 244.914L449.961 246.867Z"
        fill="url(#paint52_linear_laptop)"
      />
      <path
        d="M479.044 228.273L469.212 222.848L469.558 220.894L479.389 226.318L479.044 228.273Z"
        fill="url(#paint53_linear_laptop)"
      />
      <path
        d="M467.412 221.854L458.2 216.772L458.545 214.819L467.757 219.902L467.412 221.854Z"
        fill="url(#paint54_linear_laptop)"
      />
      <path
        d="M456.401 215.779L453.518 214.189L453.863 212.235L456.745 213.826L456.401 215.779Z"
        fill="url(#paint55_linear_laptop)"
      />
      <path
        d="M451.906 213.3L449.024 211.71L449.37 209.756L452.252 211.347L451.906 213.3Z"
        fill="url(#paint56_linear_laptop)"
      />
      <path
        d="M446.568 210.355L440.349 206.924L440.694 204.969L446.914 208.402L446.568 210.355Z"
        fill="url(#paint57_linear_laptop)"
      />
      <defs>
        <filter
          id="filter0_f"
          x="278"
          y="310"
          width="302.538"
          height="170.16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use href="#image0" transform="scale(0.25)" />
        </pattern>
        <radialGradient
          id="paint0_radial_laptop"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(445.678 316.79) scale(656.794 608.396)">
          <stop stopColor={bIsDark ? palette.darkModePurpleBg : '#571EB3'} stopOpacity="0.62" />
          <stop offset="1" stopColor="#151A32" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_laptop"
          x1="611.735"
          y1="275.154"
          x2="403.299"
          y2="275.154"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8FDBF8" />
          <stop offset="1" stopColor="#70C2EE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_laptop"
          x1="614.125"
          y1="275.183"
          x2="405.689"
          y2="275.183"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E1CA97" />
          <stop offset="1" stopColor="#20BCFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_laptop"
          x1="627.424"
          y1="278.981"
          x2="427.753"
          y2="278.981"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_laptop"
          x1="608.905"
          y1="276.023"
          x2="400.469"
          y2="276.023"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#496BBD" />
          <stop offset="0.521505" stopColor="#502789" />
          <stop offset="1" stopColor="#942496" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_laptop"
          x1="583.81"
          y1="383.581"
          x2="289.429"
          y2="383.581"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#496BBD" />
          <stop offset="0.521505" stopColor="#502789" />
          <stop offset="1" stopColor="#942496" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_laptop"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(592.829 396.973) rotate(180) scale(312.545 298.459)">
          <stop stopColor="#4A6FBF" />
          <stop offset="1" stopColor="#831B8F" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_laptop"
          x1="604.623"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_laptop"
          x1="604.623"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_laptop"
          x1="604.623"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_laptop"
          x1="604.624"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_laptop"
          x1="604.623"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_laptop"
          x1="604.623"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_laptop"
          x1="604.62"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_laptop"
          x1="621.64"
          y1="282.288"
          x2="421.97"
          y2="282.288"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_laptop"
          x1="604.621"
          y1="276.047"
          x2="404.955"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_laptop"
          x1="604.626"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_laptop"
          x1="604.625"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_laptop"
          x1="604.624"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_laptop"
          x1="604.622"
          y1="276.047"
          x2="404.954"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_laptop"
          x1="604.625"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_laptop"
          x1="604.625"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_laptop"
          x1="604.624"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_laptop"
          x1="604.622"
          y1="276.048"
          x2="404.954"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_laptop"
          x1="604.623"
          y1="276.048"
          x2="404.953"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_laptop"
          x1="604.626"
          y1="276.048"
          x2="404.952"
          y2="276.048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_laptop"
          x1="604.626"
          y1="276.047"
          x2="404.952"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_laptop"
          x1="604.622"
          y1="276.047"
          x2="404.953"
          y2="276.047"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C5ECFB" />
          <stop offset="1" stopColor="#B4E4F9" />
        </linearGradient>
        <image
          id="image0"
          width="4"
          height="4"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABHNCSVQICAgIfAhkiAAAABZJREFUCJlj3P3s538GJMDEgAYICwAA7IoDoZIfGRoAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
    // </Container>
  );
};

export default withTheme(withStyles(styles)(LaptopShape));
