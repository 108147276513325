import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import withContent from '../../../../hoc/withContent';
import SectionTitle from '../../../../components/SectionTitle';
import CodeEditor from './components/CodeEditor';
import ProjectCard from '../../../../components/ProjectCard';
import { BackgroundAbstractShape, FloatingAbstractShape } from '../../../../components/common';
import createFloatingParallaxOptions from '../../../../util/createFloatingParallaxOptions';
import palette from '../../../../theme/Palette';

const PersonalProjects = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const { subtitles_text_projectspage: arrSectionTitles, body } = objContent[intContentIndex].data;

  const arrPersonalProjects = body[0].items;

  return (
    <div className={classes.personalProjects__container}>
      <div className={classes.personalProjects__title}>
        <SectionTitle strTitleText={arrSectionTitles[0].text} />
      </div>
      <div className={classes.personalProjects__codeEditor}>
        <CodeEditor />
      </div>
      <div className={classes.personalProjects__grid}>
        {arrPersonalProjects.map((objProject) => {
          const { project } = objProject;
          return (
            <ProjectCard
              strTitle={project[0].text}
              strGithubLink={project[1].text}
              strUrl={project[2].text}
              strBgImageUrl={project[3].url}
            />
          );
        })}

        <div className={classes.personalProjects__rightShape}>
          <BackgroundAbstractShape
            bDarkModePurple
            intRotation={400}
            objParallaxOptions={createFloatingParallaxOptions('130', '940', '-80px')}
          />
        </div>
        <div className={classes.personalProjects__leftShape}>
          <FloatingAbstractShape bLight objParallaxOptions={createFloatingParallaxOptions('200', '1000', '-80px')} />
        </div>

        <svg
          className={classes.personalProjects__svg}
          viewBox="0 0 1817 1885"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M146.106 647.31C185.608 374.539 624.878 243.156 1111.06 155.985C1826.43 41.8373 1098.94 1001.59 1540.28 1097.02C2086.11 1296.4 738.56 1987.42 624.871 1693.04C609.506 1481.67 80.6458 1314.63 146.106 647.31Z"
            fill="url(#paint0_linear_personal_projects_svg)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_personal_projects_svg"
              x1="611.715"
              p
              y1="1695.28"
              x2="1995.58"
              y2="428.19"
              gradientUnits="userSpaceOnUse">
              <stop stop-color={bIsDark ? palette.darkPurple : '#7969DC'} />
              <stop offset="1" stop-color={bIsDark ? palette.darkPurple : '#C265D5'} />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(PersonalProjects)));
