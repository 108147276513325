import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../hoc/withTheme';
import withContent from '../../hoc/withContent';
import Github from './components/Github';
import Browser from './components/Browser';
import classNames from 'classnames';

const ProjectCard = ({ classes, strGithubLink, strUrl, strTitle, bIsCommercial, strBgImageUrl, children }) => {
  return (
    <div className={classes.projectCard__container} style={{ backgroundImage: `url(${strBgImageUrl})` }}>
      {strTitle && <h5 className={classes.projectCard__title}>{strTitle}</h5>}
      {children}
      <div
        className={classNames(
          classes.projectCard__info,
          bIsCommercial ? classes.projectCard__commercial : classes.projectCard__personal
        )}>
        <div className={classes.projectCard__links}>
          {!bIsCommercial && (
            <>
              <Github strLink={strGithubLink} /> <Browser strLink={strUrl} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(ProjectCard)));
