import React, { Children, isValidElement, cloneElement } from 'react';
import Navigation from '../Navigation';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import FaButton from '../FaButton';
import Header from '../Header';
import Footer from '../Footer';
import { ParallaxProvider } from 'react-skrollr';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Layout = (props) => {
  const { children, classes, strHeaderTitle } = props;

  const objTheme = useTheme();
  const bIsTabletScreen = useMediaQuery(objTheme.breakpoints.down('sm'));

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, bIsTabletScreen);
    }

    return child;
  });

  return (
    <div className={classes.layout__bg}>
      <ParallaxProvider
        init={{
          smoothScrollingDuration: 500,
          smoothScrolling: true,
        }}>
        <Grid container justify="center">
          <Grid container alignContent="center" item sm={12} md={12} lg={9}>
            <div className={classes.layout__container}>
              <div className={classes.layout__navigation}>
                <Navigation bIsTabletScreen={bIsTabletScreen} />
              </div>
              <Header strHeaderTitle={strHeaderTitle} bIsTabletScreen={bIsTabletScreen} />
              <FaButton />
              {childrenWithProps}
            </div>
          </Grid>
          <Footer bIsTabletScreen={bIsTabletScreen} />
        </Grid>
      </ParallaxProvider>
    </div>
  );
};

export default withStyles(styles)(Layout);
