import EmailReducer from './Email/EmailReducer';
import PreferencesReducer from './Preferences/PreferencesReducer';
import ContentReducer from './Content/ContentReducer';

import { combineReducers } from 'redux';

export default combineReducers({
  EmailReducer,
  PreferencesReducer,
  ContentReducer,
});
