import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ThemeDark from './theme/ThemeDark';
import ThemeLight from './theme/ThemeLight';
import { ThemeProvider } from '@material-ui/core/styles';
import withTheme from './hoc/withTheme';
import withContent from './hoc/withContent';
import ROUTES from './constants/routes';
import THEME from './constants/theme';
import { ViewAbout, ViewBlog, ViewContact, ViewProjects } from './views';
import Loader from './components/Loader';
import ErrorBoundary from './components/ErrorBoundary';

const App = ({ bIsDark, funcFetchContent, bLoading }) => {
  const [bIsAnimating, setIsAnimating] = useState(true);

  const objTheme = bIsDark ? ThemeDark : ThemeLight;

  useEffect(() => {
    async function fetchContentData() {
      await funcFetchContent();
    }
    fetchContentData();

    const nstrThemePreferences = localStorage.getItem('theme');
    if (!nstrThemePreferences) localStorage.setItem('theme', THEME.LIGHT);
  }, [funcFetchContent]);

  useEffect(() => {
    let timer = setTimeout(() => setIsAnimating(false), 2000);
    if (!bLoading) {
      return () => {
        clearTimeout(timer);
      };
    }
  }, [bIsAnimating, bLoading]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={objTheme}>
        {bIsAnimating ? (
          <Loader />
        ) : (
          <Router>
            <Switch>
              <Route exact path={ROUTES.PROJECTS} component={ViewProjects} />
              <Route exact path={ROUTES.CONTACT} component={ViewContact} />
              <Route exact path={ROUTES.BLOG} component={ViewBlog} />
              <Route exact path={ROUTES.HOME} component={ViewAbout} />
            </Switch>
          </Router>
        )}
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default withContent(withTheme(App));

//TODO Create staging env, using the netlfiy instance of my site and Prismic preview,
//make sure to make the OG site not deploy on pushing on  master, rather create a staging branch for the staging site
//TODO Create a 404 page
//TODO Add recaptcha from Google
//TODO Add serverless check to google
