import { createMuiTheme } from '@material-ui/core/styles';
import palette from './Palette';
import './amends.css';

const theme = createMuiTheme({
  mediumFontSize: 17,
  palette: {
    brightPurple: palette.brightPurple,
    yellow: palette.yellow,
    white: palette.white,
    purple: palette.mainPurple,
    darkPurple: palette.darkPurple,
    redPurple: palette.purpleRed,
    darkModePurpleBg: palette.darkModePurpleBg,
    greyPurple: palette.greyPurple,
  },
  fonts: {
    montez: 'Montez, cursive',
    passionOne: 'Passion One, cursive',
    quickSand: 'Quicksand, sans-serif',
  },
  textBubble: {
    borderGradient:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.88) 0%, rgba(199, 90, 226, 0.0701324) 96.41%, rgba(234, 193, 245, 0.04) 100%)',
    gradient: 'linear-gradient(180deg, rgba(108, 17, 163, 0.58) 0%, #623AD4 100%);',
  },
  skills: {
    hexagon: palette.darkPurple,
  },
  projects: {
    personalBackground: `linear-gradient(#341d75 5%, #493FC6)`,
    workBackground: 'linear-gradient(#9039af 10%, #2b2ba0)',
  },
  loaderText: palette.greyPurple,
  bodyBackground: palette.darkModePurpleBg,
  textColor: palette.white,
  textColorPlaceholderTitle: palette.white,
  footerLinearGradient: `linear-gradient(18deg, ${palette.darkModePurpleBg},  ${palette.darkPurple})`,
  fabLinearGradient: `linear-gradient(100deg, ${palette.brightPurple}, ${palette.darkPurple})`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1065,
      lg: 1540,
      xl: 1920,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: palette.purpleFaded,
      },
    },
    MuiDialog: {
      paper: {
        height: 700,
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: palette.white,
        borderRadius: '10px',
      },
    },
    MuiButton: {
      root: {
        background: palette.purple,
        borderRadius: 10,
        '&:hover': {
          background: palette.black,
        },
      },
      label: {
        color: palette.white,
        '&:active': {
          color: palette.black,
        },
        '&:hover': {
          color: palette.black,
        },
      },
    },
  },
});

export default theme;
