const checkForContent = (content) => {
  try {
    if (!content) return '';
    return content;
  } catch (err) {
    return '';
  }
};

export default checkForContent;
