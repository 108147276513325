import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import palette from '../../../../theme/Palette';

const Browser = ({ classes, strLink = '', bIsCommercial = false }) => {
  return (
    <a href={strLink} target="_blank" rel="noopener noreferrer">
      {bIsCommercial ? (
        <svg className={classes.browser__svg} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_browser_commercial)">
            <path
              d="M34.6188 69.2376C53.7382 69.2376 69.2376 53.7382 69.2376 34.6188C69.2376 15.4994 53.7382 0 34.6188 0C15.4994 0 0 15.4994 0 34.6188C0 53.7382 15.4994 69.2376 34.6188 69.2376Z"
              fill="#7C7AF7"
            />
            <path
              d="M69.2375 34.6188C69.2375 50.5967 57.7397 64.5773 42.6077 68.1724L39.1458 68.705L35.684 69.2376L35.1514 54.2318V0C54.1781 0 69.2375 15.8848 69.2375 34.6188Z"
              fill="#6968D3"
            />
            <path
              d="M10.9546 23.9932V51.6059C10.9546 51.8253 11.1328 51.9898 11.3385 51.9898H57.8853C58.1047 51.9898 58.2692 51.8116 58.2692 51.6059V23.9932H10.9546Z"
              fill="#C9C9F9"
            />
            <path
              d="M58.2829 24.3637V19.6747C58.2829 18.3448 57.1998 17.2617 55.8699 17.2617H13.3676C12.0377 17.2617 10.9546 18.3448 10.9546 19.6747V24.3637H58.2829Z"
              fill="#CB6AF4"
            />
            <path
              d="M39.0334 22.5262H15.8217C14.8757 22.5262 14.1079 21.7585 14.1079 20.8124C14.1079 19.8664 14.8757 19.0986 15.8217 19.0986H39.0334C39.9795 19.0986 40.7472 19.8664 40.7472 20.8124C40.7472 21.7585 39.9795 22.5262 39.0334 22.5262Z"
              fill="white"
            />
            <path
              d="M53.3746 22.5948C54.3438 22.5948 55.1295 21.8091 55.1295 20.8399C55.1295 19.8707 54.3438 19.085 53.3746 19.085C52.4053 19.085 51.6196 19.8707 51.6196 20.8399C51.6196 21.8091 52.4053 22.5948 53.3746 22.5948Z"
              fill="white"
            />
            <path
              d="M48.3292 22.5948C49.2984 22.5948 50.0841 21.8091 50.0841 20.8399C50.0841 19.8707 49.2984 19.085 48.3292 19.085C47.3599 19.085 46.5742 19.8707 46.5742 20.8399C46.5742 21.8091 47.3599 22.5948 48.3292 22.5948Z"
              fill="white"
            />
            <path
              d="M15.9455 21.4433H15.6027L15.1914 20.2505H15.5342L15.7672 20.9771L16.0003 20.2505H16.3294L16.5624 20.9771L16.7955 20.2505H17.1383L16.727 21.4433H16.3842L16.1648 20.7852L15.9455 21.4433Z"
              fill="#324A5E"
            />
            <path
              d="M17.9606 21.4433H17.6179L17.2065 20.2505H17.5493L17.7824 20.9771L18.0155 20.2505H18.3445L18.5776 20.9771L18.8107 20.2505H19.1534L18.7421 21.4433H18.3993L18.18 20.7852L17.9606 21.4433Z"
              fill="#324A5E"
            />
            <path
              d="M19.9626 21.4433H19.6198L19.2085 20.2505H19.5513L19.7843 20.9771L20.0174 20.2505H20.3465L20.5795 20.9771L20.8126 20.2505H21.1554L20.7441 21.4433H20.4013L20.1819 20.7852L19.9626 21.4433Z"
              fill="#324A5E"
            />
            <path
              d="M17.9335 33.3438H16.6721L15.2051 28.9976H16.4664L17.3165 31.63L18.1665 28.9976H19.3456L20.1957 31.63L21.0457 28.9976H22.3071L20.8263 33.3575H19.565L18.7698 30.9856L17.9335 33.3438Z"
              fill="#133454"
            />
            <path
              d="M25.2411 33.3438H23.9797L22.499 28.9976H23.7604L24.6104 31.63L25.4605 28.9976H26.6396L27.4896 31.63L28.3397 28.9976H29.601L28.1203 33.3575H26.8589L26.0637 30.9856L25.2411 33.3438Z"
              fill="#133454"
            />
            <path
              d="M32.5487 33.3438H31.2874L29.8066 28.9976H31.068L31.918 31.63L32.7681 28.9976H33.9472L34.7972 31.63L35.6473 28.9976H36.9086L35.4279 33.3575H34.1665L33.3713 30.9856L32.5487 33.3438Z"
              fill="#133454"
            />
            <path
              d="M50.8654 47.246L53.3744 44.737L49.2613 40.6102L53.0179 37.8681L41.4189 35.2905L43.9965 46.8895L46.7523 43.1192L50.8654 47.246Z"
              fill="#6968D3"
            />
          </g>
          <defs>
            <clipPath id="clip0_browser_commercial">
              <rect width="69.2376" height="69.2376" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg className={classes.browser__svg} viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_browser_commercial_browser_svg)">
            <path
              d="M34.8663 68.7282C53.6923 68.7282 68.9538 53.4639 68.9538 34.6345C68.9538 15.805 53.6923 0.540771 34.8663 0.540771C16.0403 0.540771 0.778809 15.805 0.778809 34.6345C0.778809 53.4639 16.0403 68.7282 34.8663 68.7282Z"
              fill="#4250C6"
            />
            <path
              d="M68.9539 34.6345C68.9539 50.37 57.6325 64.1387 42.7328 67.6792L39.324 68.2037L35.9153 68.7282L35.3909 53.95V0.540771C54.1256 0.540771 68.9539 16.1846 68.9539 34.6345Z"
              fill="#6070F9"
            />
            <path
              d="M11.5659 24.1702V51.3641C11.5659 51.5802 11.7414 51.7422 11.9439 51.7422H57.7764C57.9924 51.7422 58.1544 51.5667 58.1544 51.3641V24.1702H11.5659Z"
              fill="#C9C9F9"
            />
            <path
              d="M58.1679 24.5348V19.917C58.1679 18.6072 57.1014 17.5405 55.7919 17.5405H13.9419C12.6324 17.5405 11.5659 18.6072 11.5659 19.917V24.5348H58.1679Z"
              fill="#00B3CB"
            />
            <path
              d="M39.2132 22.7252H16.3577C15.4262 22.7252 14.6702 21.9691 14.6702 21.0374C14.6702 20.1057 15.4262 19.3496 16.3577 19.3496H39.2132C40.1447 19.3496 40.9007 20.1057 40.9007 21.0374C40.9007 21.9691 40.1447 22.7252 39.2132 22.7252Z"
              fill="white"
            />
            <path
              d="M53.3337 22.7928C54.2881 22.7928 55.0617 22.019 55.0617 21.0645C55.0617 20.11 54.2881 19.3362 53.3337 19.3362C52.3794 19.3362 51.6057 20.11 51.6057 21.0645C51.6057 22.019 52.3794 22.7928 53.3337 22.7928Z"
              fill="white"
            />
            <path
              d="M48.3657 22.7928C49.32 22.7928 50.0937 22.019 50.0937 21.0645C50.0937 20.11 49.32 19.3362 48.3657 19.3362C47.4113 19.3362 46.6377 20.11 46.6377 21.0645C46.6377 22.019 47.4113 22.7928 48.3657 22.7928Z"
              fill="white"
            />
            <path
              d="M16.4796 21.6586H16.1421L15.7371 20.4839H16.0746L16.3041 21.1995L16.5336 20.4839H16.8576L17.0871 21.1995L17.3166 20.4839H17.6541L17.2491 21.6586H16.9116L16.6956 21.0105L16.4796 21.6586Z"
              fill="#324A5E"
            />
            <path
              d="M18.4642 21.6586H18.1267L17.7217 20.4839H18.0592L18.2887 21.1995L18.5182 20.4839H18.8422L19.0717 21.1995L19.3012 20.4839H19.6387L19.2337 21.6586H18.8962L18.6802 21.0105L18.4642 21.6586Z"
              fill="#324A5E"
            />
            <path
              d="M20.4351 21.6586H20.0976L19.6926 20.4839H20.0301L20.2596 21.1995L20.4891 20.4839H20.8131L21.0426 21.1995L21.2721 20.4839H21.6096L21.2046 21.6586H20.8671L20.6511 21.0105L20.4351 21.6586Z"
              fill="#324A5E"
            />
            <path
              d="M18.4365 33.3787H17.1945L15.75 29.0984H16.992L17.829 31.6909L18.666 29.0984H19.827L20.664 31.6909L21.501 29.0984H22.743L21.285 33.3922H20.043L19.26 31.0562L18.4365 33.3787Z"
              fill="#133454"
            />
            <path
              d="M25.6321 33.3787H24.3901L22.9321 29.0984H24.1741L25.0111 31.6909L25.8481 29.0984H27.0091L27.8461 31.6909L28.6831 29.0984H29.9251L28.4671 33.3922H27.2251L26.4421 31.0562L25.6321 33.3787Z"
              fill="#133454"
            />
            <path
              d="M32.8272 33.3787H31.5852L30.1272 29.0984H31.3692L32.2062 31.6909L33.0432 29.0984H34.2042L35.0412 31.6909L35.8782 29.0984H37.1202L35.6622 33.3922H34.4202L33.6372 31.0562L32.8272 33.3787Z"
              fill="#133454"
            />
            <path
              d="M50.864 47.0703L53.3345 44.5993L49.2845 40.5351L52.9835 37.8346L41.5625 35.2961L44.1005 46.7192L46.814 43.006L50.864 47.0703Z"
              fill="#15218A"
            />
          </g>
          <defs>
            <clipPath id="clip0_browser_commercial_browser_svg">
              <rect x="0.778809" y="0.540771" width="68.175" height="68.1874" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </a>
  );
};

export default withTheme(withStyles(styles)(Browser));
