export default (theme) => ({
  codeEditor__mainSvg: {
    height: 540,
    width: 530,
    position: 'relative',
    zIndex: 0,
  },
  codeEditor__typed: {
    color: 'white',
    fontSize: '20px',
    position: 'absolute',
    zIndex: 11,
    top: 140,
    left: 85,
    textAlign: 'left',
    lineHeight: '1.45',
    display: 'flex',
    '& span': {
      fontSize: 11.5,
      fontFamily: 'Monospace',
    },
    '& .typed-cursor': {
      display: 'none',
    },
  },
  codeEditor__container: {
    position: 'relative',
  },
  '@media (max-width: 550px)': {
    codeEditor__mainSvg: {
      height: 340,
      width: 350,
    },
    codeEditor__container: {
      display: 'flex',
      justifyContent: 'center',
    },
    codeEditor__typed: {
      width: 180,
      top: 82,
      left: '50%',
      marginLeft: -130,
      '& span': {
        fontSize: 9,
      },
    },
    codeEditor__window: {
      transform: 'scale(1.2) translate(-70px, -53px)',
    },
  },
});
