import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import withTheme from '../../../../hoc/withTheme';
import withContent from '../../../../hoc/withContent';
import { FloatingAbstractShape, BackgroundAbstractShape } from '../../../../components/common';
import SectionTitle from '../../../../components/SectionTitle';
import palette from '../../../../theme/Palette';
import TextBubble from '../../../../components/TextBubble';
import createFloatingParallaxOptions from '../../../../util/createFloatingParallaxOptions';

const AboutMe = ({ classes, bIsDark, objContent, intContentIndex }) => {
  const {
    speech_bubble_aboutpage_one: arrTextBubbleOne = [],
    speech_bubble_aboutpage_two: arrTextBubbleTwo = [],
    subtitles_text_aboutpage: arrSectionTitles = [],
  } = objContent[intContentIndex].data;

  return (
    <div className={classes.aboutMe__container}>
      <div className={classes.aboutMe__title}>
        <SectionTitle strTitleText={arrSectionTitles[0].text} />
      </div>
      <div className={classes.aboutMe__backgroundShape}>
        <BackgroundAbstractShape
          intRotation="90"
          objParallaxOptions={createFloatingParallaxOptions('70', '940', '-80px')}
        />
      </div>
      <div className={classes.aboutMe__textOne}>
        <TextBubble intSize={380} arrParagraphs={arrTextBubbleOne} />
      </div>
      <div className={classes.aboutMe__textTwo}>
        <TextBubble arrParagraphs={arrTextBubbleTwo} intSize={250} />
      </div>
      <div className={classes.aboutMe__leftShape}>
        <FloatingAbstractShape objParallaxOptions={createFloatingParallaxOptions('100', '1000', '-80px')} />
      </div>
      <div className={classes.aboutMe__rightShape}>
        <FloatingAbstractShape objParallaxOptions={createFloatingParallaxOptions('0', '690', '-80px')} bRoundType />
      </div>

      <svg className={classes.aboutMe__mainSvg} viewBox="0 0 2205 1796" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path
            d="M2051.81 981.654C2051.81 1257.33 1189.79 1597.24 713.833 1753.22C11.4452 1968.76 809.155 1115.07 358.676 1038.01C-218.944 918.897 2132.05 -627.797 1730.1 289.064C1578.81 521 2051.81 705.982 2051.81 981.654Z"
            fill="url(#paint2_linear_about_AboutMe)"
          />
        </g>
        <defs>
          <filter id="filter0_d" x="262.807" y={0} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={2} />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_about_AboutMe"
            x1="296.618"
            y1="1228.92"
            x2="81.9363"
            y2="1352.86"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={bIsDark ? palette.darkPurple : '#631CB2'} />
            <stop offset="0.912044" stopColor={bIsDark ? palette.darkPurple : '#4C15AA'} />
          </linearGradient>

          <linearGradient
            id="paint2_linear_about_AboutMe"
            x1="1727.18"
            y1="630.327"
            x2="1727.18"
            y2="1506.56"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={bIsDark ? palette.darkPurple : '#8025BC'} />
            <stop offset={1} stopColor={bIsDark ? palette.darkPurple : '#4C15AA'} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default withContent(withTheme(withStyles(styles)(AboutMe)));
