export default (theme) => ({
  contact__mainSvg: {
    width: 1000,
    height: 641,
    marginTop: 30,
    position: 'relative',
    zIndex: 1,
  },
  header__ctaContact: {
    position: 'absolute',
    top: 215,
    right: 50,
    zIndex: 2,
  },
  header__titleContact: {
    zIndex: 2,
    position: 'absolute',
    right: 75,
    top: 105,
    color: theme.palette.white,
    fontFamily: theme.fonts.passionOne,
    fontWeight: 400,
    textAlign: 'right',
    fontSize: 30,
    lineHeight: 1,
    letterSpacing: 1,
    width: 190,
  },
  shape__containerContact: {
    position: 'relative',
    width: 565,
  },
  //topLeftShape
  contact__topLeftShape: {
    position: 'absolute',
    left: -60,
  },
  //BgShape
  contact__shapeBg: {
    position: 'absolute',
    zIndex: 0,
    left: '80%',
    bottom: -40,
  },
  //bubble
  contact__text: {
    position: 'absolute',
    top: '34%',
    right: '-85%',
    zIndex: 2,
  },

  //tablet
  '@media (max-width: 1120px)': {
    shape__containerContact: {
      marginTop: 100,
      marginBottom: 100,
      width: '60%',
    },
    contact__text: {
      top: '70%',
      right: '0',
    },
    contact__mainSvg: {
      width: '100vw',
    },
    contact__topLeftShape: {
      display: 'none',
    },
  },
  //mobile
  '@media (max-width: 800px)': {
    header__titleContact: {
      top: 160,
      right: 0,
    },
    header__ctaContact: {
      right: 0,
      top: 230,
    },
    projects__mainSvg: {
      width: '110vw',
      marginLeft: -80,
    },
    shape__containerContact: {
      width: '60%',
      height: 850,
    },
    contact__text: {
      position: 'absolute',
      right: '-40%',
    },
    contact__shapeBg: {
      bottom: -200,
      left: '30%',
      transform: 'rotate(-30deg)',
      '& svg': {
        width: 420,
      },
    },
  },
  '@media (max-width: 720px)': {
    shape__containerContact: {
      marginTop: 50,
    },
    contact__mainSvg: {
      width: '140vw',
      marginLeft: -120,
    },
  },
  '@media (max-width: 480px)': {
    header__titleContact: {
      fontSize: 25,
      top: 170,
    },
    shape__containerContact: {
      marginTop: 0,
    },
    contact__mainSvg: {
      width: '145vw',
      marginLeft: -100,
    },
    contact__text: {
      right: '-65%',
    },
  },
  '@media (max-width: 420px)': {
    header__titleContact: {
      top: 190,
    },
    header__ctaContact: {
      top: 250,
      right: -10,
    },
    contact__mainSvg: {
      width: '150vw',
    },
  },
  '@media (max-width: 380px)': {
    contact__mainSvg: {
      width: '160vw',
    },
  },
});
