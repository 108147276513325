import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { useLocation } from 'react-router-dom';
import PersonalProjects from './components/PersonalProjects';
import CommercialProjects from './components/CommercialProjects';
import Dialog from '@material-ui/core/Dialog';

const ViewProjects = () => {
  const { pathname } = useLocation();

  const [bIsModalOpen, funcSetModalOpen] = useState(false);
  const [objProject, funcSetProject] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <PersonalProjects />
      <CommercialProjects />
    </Layout>
  );
};

export default ViewProjects;
