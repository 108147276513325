import THEME from '../../constants/theme';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';

export const toggleTheme = () => {
  const strThemePreference = localStorage.getItem('theme');
  if (strThemePreference === THEME.DARK) localStorage.setItem('theme', THEME.LIGHT);
  if (strThemePreference === THEME.LIGHT) localStorage.setItem('theme', THEME.DARK);

  return {
    type: TOGGLE_THEME,
  };
};

export const toggleLanguage = (strToggleLanguage) => {
  return {
    type: TOGGLE_LANGUAGE,
    payload: strToggleLanguage,
  };
};
